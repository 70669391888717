import React from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import PageText from "../components/texts/Pagetexts";
import upload from "../assets/upload.png";
import Edit from "../assets/Edit.png";
import Delete from "../assets/Delete.png";
import outline from "../assets/outline.png";
import regular from "../assets/regular.png";
import { GiHamburgerMenu } from "react-icons/gi";
import Buttons from "../components/buttons/buttons";

const questions = () => {
  const option = [
    {
      opt: "A:",
      ans: "Choice 1",
    },
    {
      opt: "B:",
      ans: "Choice 2",
    },
    {
      opt: "C:",
      ans: "Choice 3",
    },
    {
      opt: "D:",
      ans: "Choice 4",
    },
  ];

  const choice = ["A", "B", "C", "D"];
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="EXAMS" />
      <Navbar />
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <div className=" tw-flex tw-items-center tw-gap-5">
            <PageText text="New Questions" />
            <div className=" tw-flex tw-items-center tw-gap-1">
              <img className=" tw-w-6 tw-h-6" src={upload} alt="" />
              <small className=" tw-text-sm tw-text-[#2242B4] tw-font-light Manrope">
                Upload PDF
              </small>
            </div>
          </div>

          {/*  */}
          <div className="tw-w-[692px] tw-h-auto">
            <div className=" tw-flex tw-gap-5 ">
              <div className=" tw-flex tw-gap-2 tw-w-5/12 ">
                <GiHamburgerMenu className=" tw-mt-3" />
                <p className=" tw-text-[#3E3E3E] tw-text-base tw-font-semibold">
                  Question (1):
                </p>
              </div>
              <div className=" tw-w-full">
                <div className=" tw-flex tw-items-center tw-justify-between">
                  <small className=" tw-text-xs tw-text-[#838080] Manrope">
                    Which elements can you not use for branding?
                  </small>
                  <div className=" tw-flex tw-gap-1">
                    <img
                      className=" tw-w-3 tw-h-[17.23px] tw-mt-3"
                      src={Edit}
                      alt=""
                    />
                    <img className=" tw-w-4 tw-h-4" src={Delete} alt="" />
                  </div>
                </div>
                <div className=" tw-mt-3">
                  <div className=" tw-grid tw-grid-cols-4 ">
                    {option.map((i) => (
                      <div className=" tw-flex tw-gap-1">
                        <small className=" tw-text-xs tw-font-semibold tw-mt-6">
                          {i.opt}
                        </small>
                        <small className=" tw-w-20 tw-text-xs Manrope tw-pt-1 tw-h-7 tw-grid tw-justify-center tw-border tw-border-[#C6B8B8] tw-text-[#4C4A4A] tw-rounded-lg">
                          {i.ans}
                        </small>
                      </div>
                    ))}
                  </div>
                </div>
                <div className=" tw-flex tw-items-center  tw-gap-5 tw-mt-5">
                  <small className=" tw-text-sm tw-text-[#3E3E3E] Manrope tw-font-normal">
                    Correct Choice:
                  </small>
                  <div className=" tw-grid tw-grid-cols-4 tw-gap-4">
                    {choice.map((i) => (
                      <div className=" tw-flex tw-gap-1">
                        <input className=" tw-pt-2" type="radio" />
                        <small className="tw-text-sm tw-text-[#3E3E3E] Manrope tw-font-normal">
                          {i}
                        </small>
                      </div>
                    ))}
                  </div>
                </div>
                <div className=" tw-flex tw-items-center tw-gap-32 tw-my-10">
                  <p className=" tw-font-medium tw-text-xs tw-text-[#4C4A4A]">
                    Score:
                  </p>
                  <p className="tw-font-medium tw-text-xs tw-text-[#4C4A4A]">
                    Points:{" "}
                  </p>
                </div>
                <div className=" tw-flex tw-items-center tw-justify-between">
                  <div className=" tw-flex tw-gap-3 tw-items-center">
                    <small className=" Manrope tw-text-sm tw-text-[#3E3E3E]">
                      Attach Image
                    </small>
                    <img
                      className=" tw-w-4 tw-h-4 tw-mt-1"
                      src={outline}
                      alt=""
                    />
                  </div>
                  <img className=" tw-w-4 tw-h-4" src={regular} alt="" />
                </div>
              </div>
            </div>
            <div className=" tw-pr-5 tw-mt-10">
              <div className=" tw-flex tw-items-center tw-pl-5 tw-font-semibold tw-w-full tw-h-12 tw-bg-[#FFFAFA] tw-text-[#C13D3D] tw-rounded-lg tw-border tw-border-[#F90909]">
                + Add Question
              </div>
            </div>
          </div>
        </div>
        <div className=" tw-flex tw-items-center tw-justify-center tw-gap-5 tw-mt-32">
          <Buttons
            color={"tw-text-[#FF5757]"}
            css={"tw-border-[#FF5757] tw-border"}
            text="Discard"
          />
          <Buttons
            color={"tw-text-[#fff]"}
            bg={"tw-bg-[#2242B4]"}
            text="Publish"
          />
        </div>
      </div>
    </div>
  );
};

export default questions;
