import React from "react";
import config from "../../assets/config.png";

const PaymentTable = () => {
  const payments = [
    {
      category: "School Fees",
      date: "04/03/2024",
      amount: "20,000",
      status: "Success",
    },
    {
      category: "School Fees",
      date: "04/03/2024",
      amount: "20,000",
      status: "Pending",
    },
    {
      category: "School Fees",
      date: "04/03/2024",
      amount: "20,000",
      status: "Failed",
    },
  ];

  const statusClasses = {
    Success: "tw-bg-green-100 tw-text-green-700",
    Pending: "tw-bg-yellow-100 tw-text-yellow-700",
    Failed: "tw-bg-red-100 tw-text-red-700",
  };

  return (
    <div className="tw-p-4 tw-bg-white tw-rounded-lg tw-shadow-md tw-h-72">
      <div className="tw-flex tw-justify-between tw-items-center tw-mb-4">
        <h2 className=" tw-text-base tw-font-Kum tw-font-medium tw-text-[#2C323F]">
          Payment History
        </h2>
        <button className=" tw-text-[#1A2B88] tw-text-xs tw-px-4 tw-py-2 tw-rounded-md tw-font-medium tw-flex tw-items-center">
          Filter & Sort
          <span className=" tw-pl-2">
            <img className=" tw-w-5 tw-h-5" src={config} alt="" />
          </span>
        </button>
      </div>
      <table className="tw-w-full tw-border-collapse">
        <thead>
          <tr className="tw-text-left tw-border-b tw-border-gray-200">
            <th className="tw-py-2 tw-px-4 Satoshi tw-text-sm tw-font-normal tw-text-[#949494]">
              Payment Category
            </th>
            <th className="tw-py-2 tw-px-4 Satoshi tw-text-sm tw-font-normal tw-text-[#949494]">
              Date
            </th>
            <th className="tw-py-2 tw-px-4 Satoshi tw-text-sm tw-font-normal tw-text-[#949494]">
              Amount
            </th>
            <th className="tw-py-2 tw-px-4 Satoshi tw-text-sm tw-font-normal tw-text-[#949494]">
              Status
            </th>
          </tr>
        </thead>
        <tbody>
          {payments.map((payment, index) => (
            <tr key={index} className="tw-border-b tw-border-gray-200">
              <td className="tw-py-2 tw-px-4 tw-font-medium tw-text-[#000] Satoshi tw-text-sm">
                {payment.category}
              </td>
              <td className="tw-py-2 tw-px-4 tw-font-medium tw-text-[#000] Satoshi tw-text-sm">
                {payment.date}
              </td>
              <td className="tw-py-2 tw-px-4 tw-font-medium tw-text-[#000] Satoshi tw-text-sm">
                {payment.amount}
              </td>
              <td className="tw-py-2 tw-px-4 ">
                <span
                  className={`tw-inline-block tw-px-3 tw-py-1 tw-rounded-full Satoshi tw-text-xs ${
                    statusClasses[payment.status]
                  }`}
                >
                  {payment.status}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PaymentTable;
