import React from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import PageText from "../components/texts/Pagetexts";
import { RiArrowDropDownLine } from "react-icons/ri";
import Buttons from "../components/buttons/buttons";
import Addd from "../assets/Addd.png";

const NewTimetable = () => {
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="TIMETABLE" />
      <Navbar />
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <PageText text="New Timetable" />
        </div>

        <div className=" ">
          <div className=" tw-flex tw-items-center tw-w-[643px] ">
            <div className=" tw-w-2/6">
              <p className="Manrope tw-text-sm tw-font-semibold">Class*</p>
            </div>
            <div className=" tw-w-full tw-flex tw-items-center tw-relative ">
              <input
                className=" tw-rounded-md tw-border tw-h-12 tw-w-full tw-outline-none tw-px-2"
                type="text"
              />
              <RiArrowDropDownLine
                className=" tw-absolute tw-right-2 tw-cursor-pointer"
                size={24}
              />
            </div>
          </div>

          {/* Day of the week* */}
          <div className=" tw-flex tw-items-center tw-w-[643px] tw-mt-5 ">
            <div className=" tw-w-2/6">
              <p className=" Manrope tw-text-sm tw-font-semibold">
                Day of the week*
              </p>
            </div>
            <div className=" tw-w-full tw-flex tw-items-center tw-relative ">
              <input
                className=" tw-rounded-md tw-border tw-h-12 tw-w-full tw-outline-none tw-px-2"
                type="text"
              />
              <RiArrowDropDownLine
                className=" tw-absolute tw-right-2 tw-cursor-pointer"
                size={24}
              />
            </div>
          </div>

          <div className=" tw-mt-7">
            <p className=" Satoshi tw-text-base tw-font-semibold tw-text-[#000]">
              FIRST PERIOD
            </p>
            {/* Class/Break* */}
            <div className=" tw-flex tw-items-center tw-w-[643px] tw-mt-5 ">
              <div className=" tw-w-2/6">
                <p className=" Manrope tw-text-sm tw-font-semibold">
                  Class/Break*
                </p>
              </div>
              <div className=" tw-w-full tw-flex tw-items-center tw-relative ">
                <input
                  className=" tw-rounded-md tw-border tw-h-12 tw-w-full tw-px-2 tw-outline-none"
                  type="text"
                  placeholder="Class"
                />
              </div>
            </div>
            {/* Add Subject**/}
            <div className=" tw-flex tw-items-center tw-w-[643px] tw-mt-5 ">
              <div className=" tw-w-2/6">
                <p className=" Manrope tw-text-sm tw-font-semibold">
                  Add Subject*
                </p>
              </div>
              <div className=" tw-w-full tw-flex tw-items-center tw-relative ">
                <input
                  className=" tw-rounded-md tw-border tw-h-12 tw-w-full tw-px-2 tw-outline-none"
                  type="text"
                />
              </div>
            </div>
            {/* Start time* */}
            <div className=" tw-flex tw-items-center tw-w-[643px] tw-mt-5 ">
              <div className=" tw-w-2/6">
                <p className=" Manrope tw-text-sm tw-font-semibold">
                  Start time*
                </p>
              </div>
              <div className=" tw-w-full tw-flex tw-items-center tw-relative ">
                <input
                  className=" tw-rounded-md tw-border tw-h-12 tw-w-full tw-px-2 tw-outline-none"
                  type="time"
                />
              </div>
            </div>
            {/* End time* */}
            <div className=" tw-flex tw-items-center tw-w-[643px] tw-mt-5 ">
              <div className=" tw-w-2/6">
                <p className=" Manrope tw-text-sm tw-font-semibold">
                  End time*
                </p>
              </div>
              <div className=" tw-w-full tw-flex tw-items-center tw-relative ">
                <input
                  className=" tw-rounded-md tw-border tw-h-12 tw-w-full tw-px-2 tw-outline-none"
                  type="time"
                />
              </div>
            </div>

            <div className="tw-w-[643px] tw-flex tw-justify-end">
              <img
                className=" tw-w-7 tw-h-7 tw-cursor-pointer"
                src={Addd}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className=" tw-flex tw-items-center tw-justify-center tw-mt-20">
          <Buttons
            text="Create"
            bg={"tw-bg-[#2242B4]"}
            color={"tw-text-[#fff]"}
          />
        </div>
      </div>
    </div>
  );
};

export default NewTimetable;
