import React from "react";
// import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import ReactPaginate from "react-paginate";


const Buttons = ({ bg, text, color, css, onClick, width }) => {
  return (
    <div>
      <button
        onClick={onClick}
        className={`tw-w-32 tw-h-10 tw-rounded-lg ${bg} ${width} ${color} ${css} `}
      >
        {text}
      </button>
    </div>
  );
};

export const PrimaryBtn = ({
	text,
	bg,
	color,
	onClick,
	disabled,
	css,
	...restProps
}) => {
	return (
		<div>
			<button
				disabled={disabled || restProps?.loading}
				onClick={onClick}
				style={{
					background: bg || "#2242B4",
					color: color || "#fff",
				}}
				type={restProps?.type || "submit"}
				className={`tw-h-10 tw-px-6 tw-rounded tw-text-md tw-font-medium satoshi hover:tw-transition-transform tw-ease-in-out tw-duration-300 hover:tw--translate-y-1 hover:tw-shadow-sm ${css}`} {...restProps}>
				{text}{" "}
				{restProps?.loading && (
					<ClipLoader
						color={restProps?.loadCss || color || "white"}
						size={12}
					/>
				)}
			</button>
		</div>
	);
};

export const LoadMore = ({ handleLoadMore, next, loading }) => {
	return (
		<>
			{!next ? (
				""
			) : (
				<PrimaryBtn
					onClick={handleLoadMore}
					text={loading ? "Loading..." : "Load More"}
					loading={loading}
					css="tw-mx-auto"
					type={"button"}
				/>
			)}
		</>
	);
};

export const MainPaginate = ({ handlePageClick, pageCount }) => (
	<div className="tw-flex tw-justify-center tw-items-center tw-py-10">
		<ReactPaginate
			breakLabel="..."
			onPageChange={handlePageClick}
			pageRangeDisplayed={5}
			pageCount={pageCount}
			renderOnZeroPageCount={null}
			pageClassName="tw-h-10 tw-w-10 tw-flex tw-items-center tw-justify-center tw-rounded-full tw-text-xl tw-font-medium manrope tw-border tw-border-main tw-mx-2 tw-cursor-pointer"
			className="tw-flex tw-items-center tw-justify-center"
			previousClassName="tw-hidden"
			nextClassName="tw-hidden"
			activeClassName="tw-bg-[#2242B4] tw-text-white"
		/>
	</div>
);

export default Buttons;
