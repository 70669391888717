import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import DashButton from "../assets/DashButton.png";
import notification from "../assets/notification.png";
import ChevronDown from "../assets/ChevronDown.png";
import message from "../assets/message.png";
import userEllipse from "../assets/userEllipse.png";
import SelectScores from "./cards/grades-card/SelectScores";
import Buttons from "./buttons/buttons";

const Navbar = () => {
  const [istoggle, setIsToggle] = useState(false);
  const [isLearning, setIsLearning] = useState(false);
  const [isGradings, setIsGradings] = useState(false);
  const [isAllGrades, setIsAllGrades] = useState(false);
  const [currentPage, setCurrentPage] = useState("Dashboard");

  const navigate = useNavigate();

  const handleLinkClick = (text) => {
    setCurrentPage(text);
    navigate(text); // Navigate to the clicked link
  };
  return (
    <div className=" tw-flex tw-items-center tw-bg-white tw-shadow-md tw-py-1 tw-justify-between lg:tw-px-8 md:tw-px-8 tw-px-5">
      <div className="tw-flex tw-items-center tw-gap-5">
        <img className="tw-w-12 tw-h-12" src={DashButton} alt="" />
        <p className="tw-text-[#FFC000] tw-text-lg tw-pt-3 tw-truncate tw-w-24">
          {currentPage}
        </p>
      </div>
      <div className=" tw-flex tw-items-center tw-gap-3 tw-font-Kumbh tw-text-sm">
        <ul className=" tw-flex tw-items-center tw-justify-between tw-gap-3 tw-text-[#6B7A99] tw-pt-3">
          <Link
            to="/home"
            className="tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
            onClick={() => handleLinkClick("Dashboard")}
          >
            Dashboard
          </Link>
          <Link
            to="/students"
            onClick={() => handleLinkClick("Students")}
            className="tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Students
          </Link>

          <Link
            onClick={() => setIsLearning(!isLearning)}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Learning
          </Link>

          {isLearning && (
            <div
              onClick={() => setIsLearning(false)}
              className=" tw-z-20 tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-black tw-bg-opacity-45 tw-grid "
            >
              <div className=" tw-w-44 tw-bg-white tw-h-52 tw-absolute tw-top-32 tw-left-[427px] tw-pr-10 tw-pt-5">
                <ul className=" tw-grid tw-space-y-3">
                  <Link
                    to="/exams"
                    className=" tw-cursor-pointer tw-text-sm tw-no-underline  tw-text-[#384042] tw-font-Kum"
                  >
                    Exams
                  </Link>
                  <Link className=" tw-cursor-pointer tw-text-sm tw-no-underline  tw-text-[#384042] tw-font-Kum">
                    Quizzes
                  </Link>
                  <Link className=" tw-cursor-pointer tw-text-sm tw-no-underline  tw-text-[#384042] tw-font-Kum">
                    Assignments
                  </Link>
                  <Link className=" tw-cursor-pointer tw-text-sm tw-no-underline  tw-text-[#384042] tw-font-Kum">
                    AI Virtual Class
                  </Link>
                  <Link className=" tw-cursor-pointer tw-text-sm tw-no-underline  tw-text-[#384042] tw-font-Kum">
                    E-Library
                  </Link>
                </ul>
              </div>
            </div>
          )}

          <Link
            to="/payment-history"
            onClick={() => handleLinkClick("Payment History")}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Payment History
          </Link>
          <Link
            to="/lesson-guides"
            onClick={() => handleLinkClick("Lesson Guides")}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Lesson Guides
          </Link>
          <Link
            onClick={() => setIsGradings(!isGradings)}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Grading
          </Link>
          {isGradings && (
            <div
              onClick={() => setIsGradings(false)}
              className=" tw-z-20 tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-black tw-bg-opacity-45 tw-grid "
            >
              <div className=" tw-w-56 tw-bg-white tw-h-32 tw-absolute tw-top-32 tw-left-[687px] tw-pr-10 tw-pt-5">
                <ul className=" tw-grid tw-space-y-3">
                  <Link
                    to="/promotions"
                    className=" tw-cursor-pointer tw-text-sm tw-no-underline  tw-text-[#384042] tw-font-Kum"
                  >
                    Promotions
                  </Link>
                  <Link
                    onClick={() => setIsAllGrades(!isAllGrades)}
                    className=" tw-cursor-pointer tw-text-sm tw-no-underline  tw-text-[#384042] tw-font-Kum"
                  >
                    Grades Inputting
                  </Link>

                  <Link
                    to="/all-grades"
                    className=" tw-cursor-pointer tw-text-sm tw-no-underline  tw-text-[#384042] tw-font-Kum"
                  >
                    Grade Calculations
                  </Link>
                </ul>
              </div>
            </div>
          )}
          {isAllGrades && (
            <div
              onClick={() => setIsAllGrades(false)}
              className=" tw-z-40 tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-black tw-bg-opacity-45 tw-grid "
            >
              <div className=" tw-w-72 tw-bg-white tw-space-y-5 tw-absolute tw-top-32 tw-left-[687px] tw-p-10">
                <div className=" tw-space-y-2">
                  <small className=" tw-font-semibold tw-text-sm tw-text-[#000] Manrope">
                    Select class
                  </small>
                  <div className=" tw-relative tw-flex tw-items-center ">
                    <input
                      placeholder="SS 1A"
                      className=" tw-w-full tw-h-12 placeholder-[#757576] tw-font-light placeholder:tw-text-xs tw-px-2 tw-border tw-border-[#2242B4] tw-rounded-lg"
                      type="text"
                    />
                    <img
                      className=" tw-absolute tw-right-2 tw-w-4 tw-h-4"
                      src={ChevronDown}
                      alt=""
                    />
                  </div>
                </div>
                <div className=" tw-space-y-2">
                  <small className=" tw-font-semibold tw-text-sm tw-text-[#000] Manrope">
                    Select Subject
                  </small>
                  <div className=" tw-relative tw-flex tw-items-center ">
                    <input
                      placeholder="Chemistry"
                      className=" tw-w-full tw-h-12 placeholder-[#757576] tw-font-light placeholder:tw-text-xs tw-px-2 tw-border tw-border-[#2242B4] tw-rounded-lg"
                      type="text"
                    />
                    <img
                      className=" tw-absolute tw-right-2 tw-w-4 tw-h-4"
                      src={ChevronDown}
                      alt=""
                    />
                  </div>
                </div>
                <div className=" tw-flex tw-justify-center tw-items-center">
                  <Buttons
                    onClick={() => navigate("/grade-inputing")}
                    text="Input Scores"
                    color={"tw-text-[#fff]"}
                    bg={"tw-bg-[#2242B4]"}
                  />
                </div>
              </div>
            </div>
          )}

          <Link
            to="/communication"
            onClick={() => handleLinkClick("Communication")}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Communication
          </Link>
          <Link
            to="/attendance"
            onClick={() => handleLinkClick("Attendance")}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Attendance
          </Link>
          <Link
            to="/timetable"
            onClick={() => handleLinkClick("Timetable")}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Timetable
          </Link>
          <Link
            onClick={() => handleLinkClick("Leave")}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Leave
          </Link>
          <Link
            to="/my-profile"
            onClick={() => handleLinkClick("My Profile")}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            My Profile
          </Link>
        </ul>
        <div className=" tw-flex tw-items-center tw-gap-2">
          <img
            onClick={() => navigate("/all-notifications")}
            className=" tw-w-10 tw-h-10 tw-cursor-pointer"
            src={notification}
            alt=""
          />
          <img
            onClick={() => navigate("/start-chatting")}
            className=" tw-w-7 tw-h-7 tw-cursor-pointer"
            src={message}
            alt=""
          />
          <img
            onClick={() => navigate("/my-profile")}
            className=" tw-w-10 tw-h-10 tw-cursor-pointer"
            src={userEllipse}
            alt=""
          />
        </div>
      </div>
      <div className=" lg:tw-hidden md:tw-block tw-block">
        <GiHamburgerMenu />
      </div>
    </div>
  );
};

export default Navbar;

// import React, { useState } from "react";
// import { GiHamburgerMenu } from "react-icons/gi";

// const MyComponent = () => {
//   const [selectedItem, setSelectedItem] = useState("Dashboard");

//   return (
//     <div className="tw-flex tw-items-center tw-bg-white tw-shadow-md tw-py-1 tw-justify-between lg:tw-px-8 md:tw-px-8 tw-px-5">
//       <div className="tw-flex tw-items-center tw-gap-5">
//         <img
//           className="tw-w-12 tw-h-12"
//           src={require("./assets/DashButton.png")}
//           alt=""
//         />
//         <p className="tw-text-[#FFC000] tw-text-lg tw-pt-3 tw-truncate tw-w-24">
//           {selectedItem}
//         </p>
//       </div>
//       <div className="tw-flex tw-items-center tw-gap-3 tw-font-Kumbh tw-text-sm">
//         <ul className="tw-flex tw-items-center tw-justify-between tw-gap-3 tw-text-[#6B7A99] tw-pt-3">
//           {[
//             "Dashboard",
//             "Students",
//             "Learning",
//             "Payment History",
//             "Lesson Guides",
//             "Grading",
//             "Communication",
//             "Attendance",
//             "Timetable",
//             "Leave",
//             "My Profile",
//           ].map((item, index) => (
//             <li
//               key={index}
//               className="tw-text-[13px] tw-cursor-pointer"
//               onClick={() => setSelectedItem(item)}
//             >
//               {item}
//             </li>
//           ))}
//         </ul>
//         <div className="tw-flex tw-items-center tw-gap-2">
//           <img
//             className="tw-w-10 tw-h-10 tw-cursor-pointer"
//             src={require("./assets/notification.png")}
//             alt=""
//             onClick={() => setSelectedItem("Notification")}
//           />
//           <img
//             className="tw-w-7 tw-h-7 tw-cursor-pointer"
//             src={require("./assets/message.png")}
//             alt=""
//             onClick={() => setSelectedItem("Message")}
//           />
//           <img
//             className="tw-w-10 tw-h-10 tw-cursor-pointer"
//             src={require("./assets/userEllipse.png")}
//             alt=""
//             onClick={() => setSelectedItem("My Profile")}
//           />
//         </div>
//       </div>
//       <div className="lg:tw-hidden md:tw-block tw-block">
//         <GiHamburgerMenu />
//       </div>
//     </div>
//   );
// };

// export default MyComponent;
