import React from "react";
import { useNavigate } from "react-router-dom";

const AllStudentsTable = ({ action }) => {
  let navigate = useNavigate();
  const headers = [
      "ID",
      "Name",
      "gender",
      "Class",
      "Parent",
      "Address",
      "Date of Birth",
      "Phone",
      "actions",
    ],
    data = [
      {
        name: "John Smith",
        gender: "male",
        Class: "Rose 5",
        Parent: "Bob Smith",
        Address: "12 Ade Str., Ikeja",
        DateofBirth: "0801 111 2222",
        Phone: "31/12/2001 ",
        actions: "View student",
      },
      {
        name: "John Smith",
        gender: "male",
        Class: "Rose 5",
        Parent: "Bob Smith",
        Address: "12 Ade Str., Ikeja",
        DateofBirth: "0801 111 2222",
        Phone: "31/12/2001",
        actions: "View student",
      },
      {
        name: "John Smith",
        gender: "male",
        Class: "Rose 5",
        Parent: "Bob Smith",
        Address: "12 Ade Str., Ikeja",
        DateofBirth: "0801 111 2222",
        Phone: "31/12/2001",
        actions: "View student",
      },
      {
        name: "John Smith",
        gender: "male",
        Class: "Rose 5",
        Parent: "Bob Smith",
        Address: "12 Ade Str., Ikeja",
        DateofBirth: "0801 111 2222",
        Phone: "31/12/2001",
        actions: "View student",
      },
      {
        name: "John Smith",
        gender: "male",
        Class: "Rose 5",
        Parent: "Bob Smith",
        Address: "12 Ade Str., Ikeja",
        DateofBirth: "0801 111 2222",
        Phone: "31/12/2001",
        actions: "View student",
      },
      {
        name: "John Smith",
        gender: "male",
        Class: "Rose 5",
        Parent: "Bob Smith",
        Address: "12 Ade Str., Ikeja",
        DateofBirth: "0801 111 2222",
        Phone: "31/12/2001 ",
        actions: "View student",
      },
    ];
  return (
    <div className=" tw-mt-5">
      <div className="tw-w-full">
        <table className="tw-table tw-w-full">
          <thead
            style={{
              paddingLeft: "1.25rem !important",
            }}
            className="tw-h-14 tw-bg-[#F0F6FF] tw-w-full tw-mb-4 tw-text-md tw-capitalize tw-font-bold kum tw-text-plain"
          >
            <tr>
              {headers?.map((it, i) => (
                <th className={`${i === 0 && "tw-pl-4"}`}>{it}</th>
              ))}
            </tr>
          </thead>
          <tbody className="tw-w-full">
            {data?.map((it, i) => (
              <tr
                key={i}
                style={{
                  boxShadow: "0px 5px 10px 0px #0000000A",
                }}
                className="tw-w-full tw-text-sm satoshi tw-mb-3 tw-font-light tw-text-black"
              >
                <td className="tw-py-3 tw-pr-4 tw-pl-3">01</td>
                <td className="tw-py-3 tw-capitalize tw-pr-4">{it?.name}</td>
                <td className="tw-py-3 tw-pr-4">{it?.gender}</td>
                <td className="tw-py-3 tw-pr-4 tw-capitalize">{it?.Class}</td>
                <td className="tw-py-3 tw-pr-4 tw-capitalize">{it?.Parent}</td>
                <td className="tw-py-3 tw-pr-4 tw-max-w-16 tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
                  {it?.Address}
                </td>
                <td className="tw-py-3 tw-pr-4 tw-capitalize">{it?.Phone}</td>
                <td className="tw-py-3 tw-pr-4">{it?.DateofBirth}</td>
                {action && (
                  <td
                    onClick={() =>
                      navigate("/student's-details", { state: it })
                    }
                    className=" tw-text-yellow tw-font-semibold tw-text-sm tw-font-body satoshi tw-cursor-pointer"
                  >
                    View student
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllStudentsTable;
