import React, { useState } from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import Buttons from "../components/buttons/buttons";
import EventsCard from "../components/Communication/EventsCard";
import AnnouncementCard from "../components/Communication/AnnouncementCard";
import { useLocation, useNavigate } from "react-router-dom";
import CreatePost from "../components/Communication/CreatePost";
import StudentNavbar from "../components/StudentNavbar";
import useAuthStore from "../data/Stores/AuthStore";
import ParentNavbar from "../components/ParentNavbar";

const Communication = () => {
  const [activeTab, setActiveTab] = useState("events");
  const [isCreatePost, setIsCreatePost] = useState(false);
  const navigate = useNavigate(),
    { state } = useLocation(),
    { userPage } = useAuthStore();

  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="COMMUNICATIONS" />
      {userPage === "teacher" ? (
        <Navbar />
      ) : userPage === "student" ? (
        <StudentNavbar />
      ) : userPage === "parent" ? (
        <ParentNavbar />
      ) : null}
      <div className="tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <div className="tw-flex tw-items-center tw-justify-between">
            <div className="tw-flex tw-items-center tw-gap-10">
              <p
                className={`tw-cursor-pointer ${
                  activeTab === "events" ? "tw-underline tw-text-[#2242B4]" : ""
                }`}
                onClick={() => setActiveTab("events")}
              >
                Events
              </p>
              <p
                className={`tw-cursor-pointer ${
                  activeTab === "announcements"
                    ? "tw-underline tw-text-[#2242B4]"
                    : ""
                }`}
                onClick={() => setActiveTab("announcements")}
              >
                Announcements
              </p>
            </div>
            {userPage === "teacher" ? (
              <>
                {activeTab === "events" && (
                  <Buttons
                    onClick={() => navigate("/create-event")}
                    bg="tw-bg-[#2242B4]"
                    color="tw-text-white"
                    text="New event"
                  />
                )}
                {activeTab === "announcements" && (
                  <Buttons
                    onClick={() => setIsCreatePost(!isCreatePost)}
                    width="tw-w-[192px]"
                    color="tw-text-white"
                    bg="tw-bg-[#2242B4]"
                    text="New announcement"
                  />
                )}
                {isCreatePost && (
                  <CreatePost onClick={() => setIsCreatePost(false)} />
                )}
              </>
            ) : (
              ""
            )}
          </div>
          <div className="tw-mt-4">
            {activeTab === "events" && <EventsCard />}
            {activeTab === "announcements" && <AnnouncementCard />}
          </div>
        </div>
      </div>
    </div>
  );
};

const Communications = () => <Communication />;

export default Communication;
