import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Removed from "../cards/students-card/Removed";

const AllGradesTable = ({ action, Edit }) => {
  const [isDelete, setIsDelete] = useState(false);
  const navigate = useNavigate();
  const handleCancel = () => {
    navigate("/add-grade");
  };

  const headers = ["Scores", "Class", "Grade", "Status", "actions"],
    data = [
      {
        Scores: "0 - 39",
        Class: "SSS 1 - SSS 3",
        Grade: "F9",
        Status: "Failed",
        Edit: "Edit",
        actions: "Delete",
      },
      {
        Scores: "40 - 44",
        Class: "SSS 1 - SSS 3",
        Grade: "E8",
        Status: "Passed",
        DateofBirth: "0801 111 2222",
        Phone: "31/12/2001",
        Edit: "Edit",
        actions: "Delete",
      },
      {
        Scores: "70 - 100",
        Class: "All Classes",
        Grade: "A",
        Status: "Excellent",
        DateofBirth: "0801 111 2222",
        Phone: "31/12/2001",
        Edit: "Edit",
        actions: "Delete",
      },
      {
        Scores: "0 - 39",
        Class: "SSS 1 - SSS 3",
        Grade: "F9",
        Status: "Failed",
        DateofBirth: "0801 111 2222",
        Phone: "31/12/2001",
        Edit: "Edit",
        actions: "Delete",
      },
      {
        Scores: "0 - 39",
        Class: "SSS 1 - SSS 3",
        Grade: "F9",
        Status: "Failed",
        DateofBirth: "0801 111 2222",
        Phone: "31/12/2001",
        Edit: "Edit",
        actions: "Delete",
      },
      {
        Scores: "0 - 39",
        Class: "SSS 1 - SSS 3",
        Grade: "F9",
        Status: "Failed",

        Edit: "Edit",
        actions: "Delete",
      },
      {
        Scores: "0 - 39",
        Class: "SSS 1 - SSS 3",
        Grade: "F9",
        Status: "Failed",

        Edit: "Edit",
        actions: "Delete",
      },
      {
        Scores: "0 - 39",
        Class: "SSS 1 - SSS 3",
        Grade: "F9",
        Status: "Failed",

        Edit: "Edit",
        actions: "Delete",
      },
      {
        Scores: "0 - 39",
        Class: "SSS 1 - SSS 3",
        Grade: "F9",
        Status: "Failed",

        Edit: "Edit",
        actions: "Delete",
      },
      {
        Scores: "0 - 39",
        Class: "SSS 1 - SSS 3",
        Grade: "F9",
        Status: "Failed",

        Edit: "Edit",
        actions: "Delete",
      },
    ];
  return (
    <div className=" tw-mt-5">
      <div className="tw-w-full">
        <table className="tw-table tw-w-full">
          <thead
            style={{
              paddingLeft: "1.25rem !important",
            }}
            className="tw-h-14 tw-bg-[#F0F6FF] tw-w-full tw-mb-4 tw-text-md tw-capitalize tw-font-bold kum tw-text-plain"
          >
            <tr>
              {headers?.map((it, i) => (
                <th className={`${i === 0 && "tw-pl-4"}`}>{it}</th>
              ))}
            </tr>
          </thead>
          <tbody className="tw-w-full">
            {data?.map((it, i) => (
              <tr
                key={i}
                style={{
                  boxShadow: "0px 5px 10px 0px #0000000A",
                }}
                className="tw-w-full tw-text-sm satoshi tw-mb-3 tw-font-light tw-text-black"
              >
                <td className="tw-py-3 tw-capitalize tw-pl-4">{it?.Scores}</td>
                <td className="tw-py-3 tw-pr-4">{it?.Class}</td>
                <td className="tw-py-3 tw-pr-4 tw-capitalize">{it?.Grade}</td>
                <td className="tw-py-3 tw-pr-4 tw-max-w-16 tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
                  {it?.Status}
                </td>

                {action && (
                  <td className=" tw-flex tw-gap-3 tw-items-center tw-pt-4 tw-font-semibold tw-text-sm tw-font-body satoshi tw-cursor-pointer">
                    <small
                      onClick={() => navigate("/edit-grade")}
                      className="Manrope tw-text-xs tw-text-[#2242B4] "
                    >
                      {it?.Edit}
                    </small>

                    <small
                      onClick={() => setIsDelete(!isDelete, { state: it })}
                      className="Manrope tw-text-xs  tw-text-[#FF6B7D]"
                    >
                      {it?.actions}
                    </small>
                    {isDelete && (
                      <Removed onCancel={handleCancel} text="‘Item Name’" />
                    )}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AllGradesTable;
