import React, { useState } from "react";
import { BranchSelectInput, SearchInput } from "../inputs";
import Buttons from "../buttons/buttons";
import { useNavigate } from "react-router-dom";

const ViewAttendanceCard = ({ onClick }) => {
  const handleCardClick = (e) => {
    e.stopPropagation();
  };
  const navigate = useNavigate();
  return (
    <div
      onClick={onClick}
      className="tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-cursor-pointer tw-bg-black tw-bg-opacity-45 tw-grid tw-justify-center tw-items-center tw-z-50"
    >
      <div
        onClick={handleCardClick}
        className="tw-z-60 tw-p-5 tw-space-y-20 tw-bg-white tw-w-[360px] tw-h-auto tw-py-5 tw-relative"
      >
        <div className="tw-relative tw-z-70">
          <div className="tw-space-y-2">
            <p className="tw-text-sm Manrope tw-font-normal tw-text-[#000]">
              Select ward
            </p>
            <SearchInput placeholder="" type="date" />
          </div>
        </div>

        <div className="tw-grid tw-justify-center tw-items-center">
          <Buttons
            onClick={() => navigate("/view-attendance")}
            css="tw-w-[168px] tw-h-[42px] tw-bg-[#2242B4] tw-font-semibold tw-text-[#fff]"
            text="View Attendance"
          />
        </div>
      </div>
    </div>
  );
};

export default ViewAttendanceCard;
