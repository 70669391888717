import React from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import Activities from "../components/cards/Activity";
import Container from "../assets/Container.png";

const home = () => {
  const card = [
    {
      id: 1,
      total: "Total Leave taken",
      num: "24",
      paid: " Paid",
      paidnum: "22",
      Unpaid: "Unpaid",
      Unpaidnum: "02",
    },
    {
      id: 2,
      total: "Total Students",
      num: "200",
      paid: " Paid",
      paidnum: "22",
      Unpaid: "Unpaid",
      Unpaidnum: "02",
    },
    {
      id: 3,
      total: "Total Subjects",
      num: "03",
      paid: " Paid",
      paidnum: "22",
      Unpaid: "Unpaid",
      Unpaidnum: "02",
    },
    {
      id: 4,
      total: "Total Classes",
      num: "10",
      paid: " Paid",
      paidnum: "22",
      Unpaid: "Unpaid",
      Unpaidnum: "02",
    },
  ];

  const Students = [
    {
      id: 1,
      userImg: require("../assets/users.png"),
      name: "Bob Smith",
      num: "001A",
    },
    {
      id: 2,
      userImg: require("../assets/users.png"),
      name: "Bob Smith",
      num: "001A",
    },
    {
      id: 3,
      userImg: require("../assets/users.png"),
      name: "Bob Smith",
      num: "001A",
    },
    {
      id: 4,
      userImg: require("../assets/users.png"),
      name: "Bob Smith",
      num: "001A",
    },
    {
      id: 5,
      userImg: require("../assets/users.png"),
      name: "Bob Smith",
      num: "001A",
    },
  ];
  const Defaulters = [
    {
      id: 1,
      userImg: require("../assets/users.png"),
      name: "Bob Smith",
      num: "001A",
      SchoolFees: "School Fees",
    },
    {
      id: 2,
      userImg: require("../assets/users.png"),
      name: "Bob Smith",
      num: "001A",
      SchoolFees: "School Fees",
    },
    {
      id: 3,
      userImg: require("../assets/users.png"),
      name: "Bob Smith",
      num: "001A",
      SchoolFees: "School Fees",
    },
    {
      id: 4,
      userImg: require("../assets/users.png"),
      name: "Bob Smith",
      num: "001A",
      SchoolFees: "School Fees",
    },
    {
      id: 5,
      userImg: require("../assets/users.png"),
      name: "Bob Smith",
      num: "001A",
      SchoolFees: "School Fees",
    },
  ];

  const Activity = [
    {
      id: 1,
      img: Container,
      text: "Graded students in Topology Assignment",
      desc: "You have graded 20 students in the last assignment",
      time: "1 Day ago",
    },
    {
      id: 2,
      img: Container,
      text: "Graded students in Topology Assignment",
      desc: "You have graded 20 students in the last assignment",
      time: "1 Day ago",
    },
    {
      id: 3,
      img: Container,
      text: "Graded students in Topology Assignment",
      desc: "You have graded 20 students in the last assignment",
      time: "1 Day ago",
    },
    {
      id: 4,
      img: Container,
      text: "Graded students in Topology Assignment",
      desc: "You have graded 20 students in the last assignment",
      time: "1 Day ago",
    },
    {
      id: 5,
      img: Container,
      text: "Graded students in Topology Assignment",
      desc: "You have graded 20 students in the last assignment",
      time: "1 Day ago",
    },
    {
      id: 6,
      img: Container,
      text: "Graded students in Topology Assignment",
      desc: "You have graded 20 students in the last assignment",
      time: "1 Day ago",
    },
    {
      id: 7,
      img: Container,
      text: "Graded students in Topology Assignment",
      desc: "You have graded 20 students in the last assignment",
      time: "1 Day ago",
    },
    {
      id: 8,
      img: Container,
      text: "Graded students in Topology Assignment",
      desc: "You have graded 20 students in the last assignment",
      time: "1 Day ago",
    },
  ];

  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="WELCOME TO YOUR DASHBOARD (2ND TERM 2023/2024)" />
      <Navbar />

      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <p className=" Kmb tw-text-[#2C323F] tw-text-xl tw-font-semibold">
            Welcome Ann!
          </p>
        </div>

        <div className="tw-bg-white  lg:tw-flex tw-justify-between lg:tw-px-10 md:tw-px-5 md:tw-grid-cols-2 tw-grid-cols-1 tw-grid tw-items-center ">
          {card.map((item, index) => (
            <React.Fragment key={item.id}>
              <div className="tw-w-56 tw-bg-white tw-shadow-xl tw-p-5 tw-h-[116px] ">
                <div className="tw-h-24 tw-space-y-2">
                  <div className=" tw-grid tw-gap-2">
                    <small className="Inter tw-text-base tw-text-[#404040] tw-font-semibold">
                      {item.total}
                    </small>
                    <small className="Inter tw-text-2xl tw-text-[#146ADC] tw-font-semibold">
                      {item.num}
                    </small>
                  </div>
                  <div className="tw-flex tw-items-center tw-gap-5">
                    <div className="tw-flex tw-items-center tw-gap-2">
                      <p className="Inter tw-text-xs tw-text-[#9E9E9E] tw-font-light">
                        {item.paid}
                      </p>
                      <p className="Inter tw-text-xs tw-font-semibold tw-text-[#1158B7]">
                        {item.paidnum}
                      </p>
                    </div>
                    <div className="tw-flex tw-items-center tw-gap-2">
                      <p className="Inter tw-text-xs tw-text-[#9E9E9E] tw-font-light">
                        {item.Unpaid}
                      </p>
                      <p className="tw-text-[#D32940] Inter tw-text-xs tw-font-semibold">
                        {item.Unpaidnum}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {index < card.length - 1 && (
                <div className="tw-flex tw-justify-center ">
                  <img
                    className="tw-h-20"
                    src={require("../assets/Primaryline.png")}
                    alt=""
                  />
                </div>
              )}
            </React.Fragment>
          ))}
        </div>

        <div className=" tw-flex tw-mt-10 tw-gap-10 ">
          <div className=" tw-w-3/5 tw-flex tw-justify-between tw-gap-10 tw-bg-white tw-shadow-right-bottom tw-pt-5 tw-pl-5 tw-pr-3 tw-pb-5">
            <div className=" tw-w-[398px]">
              <p className=" Kmb tw-text-[#54536E] tw-text-xl tw-font-semibold">
                Students Absent
              </p>
              <div className=" tw-space-y-2">
                {Students.map((i) => (
                  <div
                    key={i.id}
                    className=" tw-w-full tw-border tw-border-[#E8E8E8] tw-h-14 tw-flex tw-items-center tw-p-3 tw-gap-3"
                  >
                    <img className=" tw-w-10 tw-h-10" src={i.userImg} alt="" />
                    <div className=" tw-grid">
                      <small className=" Satoshi tw-font-semibold tw-text-base tw-text-[#000]">
                        {i.name}
                      </small>
                      <small className=" tw-text-sm tw-text-[#8A8A8A] tw-font-light">
                        {i.num}
                      </small>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className=" tw-w-[398px]">
              <p className="Kmb tw-text-[#54536E] tw-text-xl tw-font-semibold">
                Defaulters
              </p>
              <div className=" tw-space-y-2">
                {Defaulters.map((i) => (
                  <div
                    key={i.id}
                    className=" tw-w-full tw-border tw-border-[#E8E8E8] tw-h-20 tw-flex tw-items-center tw-p-3 tw-gap-3"
                  >
                    <img className=" tw-w-10 tw-h-10" src={i.userImg} alt="" />
                    <div className=" tw-grid">
                      <small className=" Satoshi tw-font-semibold tw-text-base tw-text-[#000]">
                        {i.name}
                      </small>
                      <small className=" tw-text-sm tw-text-[#8A8A8A] tw-font-light">
                        {i.num}
                      </small>
                      <small className=" tw-text-sm tw-font-light tw-mt-1 Satoshi tw-text-[#F93333]">
                        {i.SchoolFees}
                      </small>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className=" tw-w-2/5 tw-p-4 tw-shadow-left-bottom">
            <div className="">
              <p className=" tw-text-[#000] Satoshi tw-text-lg tw-font-semibold">
                Your Activity
              </p>

              <div>
                <Activities Activity={Activity} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default home;
