import React, { useState } from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import { SearchInput } from "../components/inputs";
import Avatar from "../assets/Avatar.png";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthStore from "../data/Stores/AuthStore";
import StudentNavbar from "../components/StudentNavbar";
import ParentNavbar from "../components/ParentNavbar";

const StartChatting = () => {
  const navigate = useNavigate(),
    { state } = useLocation(),
    { userPage } = useAuthStore();
  const [tab, setTab] = useState("general");
  const tabs = ["general", "students", "teachers", "parents"];
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="COMMUNICATIONS" />
      {userPage === "teacher" ? (
        <Navbar />
      ) : userPage === "student" ? (
        <StudentNavbar />
      ) : userPage === "parent" ? (
        <ParentNavbar />
      ) : null}
      <div className="tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <section className="">
          <h3 className="tw-text-xl tw-font-medium tw-text-black tw-font-kum">
            Start Chatting
          </h3>
          <div className="tw-mt-4 tw-flex tw-gap-6 tw-items-center">
            {tabs?.map((it, i) => (
              <h4
                onClick={() => setTab(it)}
                className={`${
                  tab === it
                    ? "tw-text-[#3D70F5] tw-font-semibold"
                    : "tw-text-black"
                } tw-text-base manrope tw-capitalize tw-cursor-pointer `}
              >
                {it}
              </h4>
            ))}
          </div>
          <div className="tw-w-full tw-mt-8 tw-flex tw-gap-8">
            <div className="tw-w-[25%]">
              <SearchInput placeholder={"Search by name"} />
              <div className="tw-space-y-6 tw-mt-4">
                <div className="tw-w-full tw-flex tw-items-center tw-gap-6">
                  <img src={Avatar} alt="" className=" tw-w-10 tw-h-10  " />
                  <div>
                    <strong className="tw-text-md tw-font-medium tw-text-[#434343] manrope">
                      Debs Ann
                    </strong>
                    <div>
                      <small className="tw-font-normal tw-text-xs tw-text-[#797979] manrope">
                        Basic 5 Teacher
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tw-w-[75%]">
              <div
                style={{
                  boxShadow: "0px 6px 16px 0px #0000001A",
                }}
                className="tw-w-full tw-flex tw-justify-between tw-px-7 tw-items-center tw-h-24 tw-bg-white"
              >
                <div className=" tw-flex tw-items-center tw-gap-6">
                  <img src={Avatar} alt="" className=" tw-w-10 tw-h-10" />
                  <div>
                    <strong className="tw-text-md tw-font-medium tw-text-[#434343] manrope">
                      Debs Ann
                    </strong>
                    <div>
                      <small className="tw-font-normal tw-text-xs tw-text-[#797979] manrope">
                        Basic 5 Teacher
                      </small>
                    </div>
                  </div>
                </div>
                <div className="tw-flex tw-gap-4 tw-items-center">
                  <small className="tw-text-base tw-font-normal manrope tw-text-black">
                    Online
                  </small>
                  <div className="tw-size-2 tw-rounded-full tw-bg-[#33FF00]"></div>
                </div>
              </div>
              <div className="tw-h-full tw-w-full tw-py-6">
                <div className="tw-space-y-7">
                  <div className="tw-flex tw-justify-start">
                    <div className="tw-w-1/2 tw-p-5 tw-bg-white tw-rounded-3xl">
                      <small className="tw-text-sm tw-font-light manrope tw-text-[#434343]">
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        Ut et massa mi. Aliquam in hendrerit urna. Pellentesque
                        sit amet sapien fringilla, mattis ligula consectetur,
                        ultrices mauris. Maecenas vitae mattis tellus. Nullam
                        quis imperdiet augue. Vestibulum auctor ornare leo, non
                        suscipit.
                      </small>
                    </div>
                  </div>
                  <div className="tw-flex tw-justify-end">
                    <div className="tw-w-1/2 tw-p-5 tw-bg-[#2242B4] tw-rounded-3xl">
                      <small className="tw-text-sm tw-font-light manrope tw-text-white">
                        Lorem ipsum dolor sit amet consectetur adipiscing elit
                        Ut et massa mi. Aliquam in hendrerit urna. Pellentesque
                        sit amet sapien fringilla, mattis ligula consectetur,
                        ultrices mauris. Maecenas vitae mattis tellus. Nullam
                        quis imperdiet augue. Vestibulum auctor ornare leo, non
                        suscipit.
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

const StartChattings = () => <StartChatting />;

export default StartChatting;
