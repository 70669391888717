import React, { useState } from "react";
import PageText from "../components/texts/Pagetexts";
import Header from "../components/header";
import StudentNavbar from "../components/StudentNavbar";
import { Input } from "../components/inputs";
import Buttons from "../components/buttons/buttons";
import ActionCompleted from "../components/cards/students-card/ActionCompleted";
import { useNavigate } from "react-router-dom";

const BorrowBook = () => {
  const navigate = useNavigate();
  const [isDone, setIsDone] = useState(false);
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="E-LIBRARY" />
      <StudentNavbar />
      <div className=" tw-bg-white tw-mt-5  lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <PageText text="Borrow Book" />

        <div className=" tw-grid tw-items-center tw-gap-5 tw-mt-5 ">
          <div className=" tw-flex tw-items-center tw-w-full ">
            <div className=" tw-w-1/5">
              <p className=" Manrope tw-text-base tw-text-[#000] tw-font-medium">
                Date borrowed*
              </p>
            </div>
            <div className=" tw-w-4/5">
              <Input placeholder="" width={"tw-w-[654px]"} />
            </div>
          </div>
          <div className=" tw-flex tw-items-center tw-w-full ">
            <div className=" tw-w-1/5">
              <p className=" Manrope tw-text-base tw-text-[#000] tw-font-medium">
                Book Title*
              </p>
            </div>
            <div className=" tw-w-4/5">
              <Input placeholder="" width={"tw-w-[654px]"} />
            </div>
          </div>
          <div className=" tw-flex tw-items-center tw-w-full ">
            <div className=" tw-w-1/5">
              <p className=" Manrope tw-text-base tw-text-[#000] tw-font-medium">
                Category*
              </p>
            </div>
            <div className=" tw-w-4/5">
              <Input placeholder="" width={"tw-w-[654px]"} />
            </div>
          </div>
          <div className=" tw-flex tw-items-center tw-w-full ">
            <div className=" tw-w-1/5">
              <p className=" Manrope tw-text-base tw-text-[#000] tw-font-medium">
                Date to be returned*
              </p>
            </div>
            <div className=" tw-w-4/5">
              <Input placeholder="" width={"tw-w-[654px]"} />
            </div>
          </div>
        </div>

        <div className=" tw-mt-20">
          <Buttons
            onClick={() => setIsDone(!isDone)}
            text="Borrow now"
            color={"tw-text-[#fff]"}
            bg={"tw-bg-[#2242B4]"}
          />
          {isDone && (
            <ActionCompleted
              text="Action Completed"
              onClick={() => navigate("/e-library")}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default BorrowBook;

// import React from "react";

// import Buttons from "../components/buttons/buttons";
// import PageText from "../components/texts/Pagetexts";
// import { TextInputs } from "../components/inputs";

// const BorrowBook = () => {
//   return (
//     <div>
//       <div className="tw-p-6">
//         <PageText text={"New Book"} />
//         <form action="" className="tw-mt-10">
//           <div className="tw-w-1/2 tw-space-y-4">
//             <div className="tw-flex tw-gap-8 tw-items-center">
//               <h6 className="tw-text-lg tw-w-32 tw-font-medium tw-text-black manrope">
//                 Book Title
//               </h6>
//               <div className="tw-w-full">
//                 <TextInputs placeholder={"Enter book title"} />
//               </div>
//             </div>
//             <div className="tw-flex tw-w-full tw-gap-8">
//               <h6 className="tw-text-lg tw-w-32 tw-font-medium tw-text-black manrope">
//                 Description
//               </h6>
//               <div className="tw-w-full">
//                 <textarea
//                   name=""
//                   placeholder="Description"
//                   className="tw-h-44 tw-pt-5 tw-text-sm tw-font-medium tw-rounded-xl placeholder:tw-text-[#bababa] tw-text-black kum tw-pl-5 tw-border tw-border-[#A7A7A7] tw-w-full"
//                   id=""
//                 ></textarea>
//               </div>
//             </div>
//           </div>
//           <div className="tw-mt-10 tw-flex tw-gap-6 tw-items-center">
//             <div>
//               <h6 className="tw-text-md tw-font-medium manrope tw-text-black">
//                 Upload Image (150px x 150px)
//               </h6>
//               <div>
//                 <input
//                   class="tw-block tw-w-full tw-text-sm tw-text-gray-900 tw-border tw-border-gray-300 tw-rounded-lg tw-cursor-pointer tw-bg-gray-50 dark:text-gray-400 focus:tw-outline-none"
//                   aria-describedby="file_input_help"
//                   id="file_input"
//                   type="file"
//                 />
//               </div>
//             </div>
//           </div>
//           <div className="tw-w-full tw-mt-24">
//             <div className="tw-flex tw-justify-center tw-w-full">
//               <div>
//                 <div className="tw-flex tw-justify-center tw-gap-6">
//                   <div className="tw-flex tw-gap-2 tw-items-center">
//                     <div>
//                       <strong className="tw-text-lg kum tw-text-[#4f4f4f] tw-font-medium">
//                         Add another
//                       </strong>
//                     </div>
//                   </div>
//                   <div>
//                     <strong className="tw-text-lg kum tw-text-[#4f4f4f] tw-font-medium">
//                       Import CSV
//                     </strong>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="tw-mt-12 tw-flex tw-justify-center">
//               <Buttons text={"Add book"} bg={"#2242B4"} />
//             </div>
//           </div>
//         </form>
//       </div>
//     </div>
//   );
// };

// export default BorrowBook;
