import React from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import PageText from "../components/texts/Pagetexts";
import Buttons from "../components/buttons/buttons";
import image from "../assets/image.png";
import Schedules from "../assets/Schedules.png";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthStore from "../data/Stores/AuthStore";
import StudentNavbar from "../components/StudentNavbar";
import ParentNavbar from "../components/ParentNavbar";

const AllNotifications = () => {
  const navigate = useNavigate(),
    { state } = useLocation(),
    { userPage } = useAuthStore();
  const notification = [
    {
      title: "New Notification Title",
      img: image,
      name: "Ann AdeOluwa",
      Schedules: Schedules,
      date: "31st January, 2024",
      text: " pLorem ipsum dolor sit amet consectetur adipiscing elit Utet massa mi. Aliquam in hendrerit urna. Pellentesque sitamet sapien fringilla, mattis ligula consectetur, ultricesmauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit.",
    },
    {
      title: "New Notification Title",
      img: image,
      name: "Ann AdeOluwa",
      Schedules: Schedules,
      date: "31st January, 2024",
      text: " pLorem ipsum dolor sit amet consectetur adipiscing elit Utet massa mi. Aliquam in hendrerit urna. Pellentesque sitamet sapien fringilla, mattis ligula consectetur, ultricesmauris. Maecenas vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum auctor ornare leo, non suscipit.",
    },
  ];
  return (
    <div className="tw-bg-[#F9F9F9]">
      {userPage === "teacher" ? (
        <Header text="NOTIFICATIONS" />
      ) : userPage === "student" ? (
        <Header text="NOTIFICATIONS" />
      ) : userPage === "parent" ? (
        <Header text="NOTIFICATIONS" />
      ) : null}

      {userPage === "teacher" ? (
        <Navbar />
      ) : userPage === "student" ? (
        <StudentNavbar />
      ) : userPage === "parent" ? (
        <ParentNavbar />
      ) : null}
      <div className="tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <div className=" tw-flex tw-items-center tw-justify-between">
            <PageText text="All Notifications" />

            {userPage === "teacher" ? (
              <>
                {" "}
                <Buttons
                  text="New notification"
                  bg={"tw-bg-[#2242B4]"}
                  color={"tw-text-[#fff]"}
                  width={"tw-w-[167px]"}
                />
              </>
            ) : (
              ""
            )}
          </div>

          <div className=" tw-flex tw-items-center tw-gap-10">
            <small className=" Manrope tw-text-sm tw-font-normal tw-text-[#707070]">
              Teachers
            </small>
            <small className=" Manrope tw-text-sm tw-font-normal tw-text-[#707070]">
              Admin
            </small>
          </div>

          <div className=" tw-mt-10 tw-grid tw-gap-y-5">
            {notification.map((i) => (
              <div className=" tw-bg-[#F9F9F9] tw-p-5">
                <p className=" tw-font-semibold tw-text-base tw-text-[#1A1A1A]">
                  {i.title}
                </p>

                <div className=" tw-flex tw-items-center tw-justify-between">
                  <div className=" tw-flex tw-items-center tw-gap-3">
                    <div className=" tw-rounded-full tw-gap-2 tw-border-[#C4C4C4] tw-flex tw-items-center tw-border-[1px] tw-p-1 tw-h-12">
                      <img className=" tw-w-10 tw-h-10" src={i.img} alt="" />
                      <small className=" tw-text-[#777C94]">{i.name}</small>
                    </div>
                    <div className=" tw-flex tw-items-center tw-gap-2">
                      <img
                        className=" tw-w-6 tw-h-6"
                        src={i.Schedules}
                        alt=""
                      />
                      <small className=" tw-text-[#777C94] Inter tw-text-sm">
                        {i.date}
                      </small>
                    </div>
                  </div>
                  <div className=" ">
                    <div className=" tw-rounded-full tw-bg-[#13BD8A] tw-w-6 tw-h-6"></div>
                  </div>
                </div>

                <div className=" tw-mt-3">
                  <p className=" Manrope tw-text-sm tw-font-light tw-text-[#777C94]">
                    {i.text}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const AllNotification = () => <AllNotifications />;

export default AllNotifications;
