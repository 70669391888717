import React, { useState } from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import PageText from "../components/texts/Pagetexts";
import date from "../assets/date.png";
import TodayTable from "../components/tables/TodayTable";
import Buttons from "../components/buttons/buttons";
import WeeklyTable from "../components/tables/WeeklyTable";
import PromoteStudents from "../components/cards/promote-card/PromoteStudents";
import { useNavigate } from "react-router-dom";

const MarkAttendance = () => {
  const [isStudent, setIsStudent] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Today");
  const navigate = useNavigate();
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="ATTENDANCE" />
      <Navbar />
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <PageText text="Mark Attendance" />
          <div>
            <div className="tw-flex tw-items-center tw-gap-4 tw-mt-5">
              <small
                className="tw-flex tw-items-center tw-cursor-pointer"
                onClick={() => setSelectedOption("Today")}
                style={{
                  color: selectedOption === "Today" ? "#2242B4" : "black",
                }}
              >
                <small className="tw-text-base tw-font-Kum">Today</small>
                <img className="tw-w-4 tw-h-4" src={date} alt="" />
              </small>
              <small
                className="tw-text-base tw-font-Kum tw-cursor-pointer"
                onClick={() => setSelectedOption("Weekly")}
                style={{
                  color: selectedOption === "Weekly" ? "#2242B4" : "black",
                }}
              >
                Weekly
              </small>
            </div>

            {selectedOption === "Today" && <TodayTable />}

            {selectedOption === "Weekly" && <WeeklyTable />}
          </div>
        </div>
        <div className=" tw-mt-5">
          <Buttons
            onClick={() => setIsStudent(!isStudent)}
            bg={"tw-bg-[#2242B4]"}
            color={"tw-text-[#fff]"}
            text="Mark student(s)"
          />
          {isStudent && (
            <PromoteStudents
              onRoute={() => navigate("/attendance")}
              text="Are you sure you want to mark 32 students present for today?"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MarkAttendance;
