import React from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import PageText from "../components/texts/Pagetexts";
import { RiArrowDropDownLine } from "react-icons/ri";
import Buttons from "../components/buttons/buttons";
import Addd from "../assets/Addd.png";

const EditTimetable = () => {
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="TIMETABLE" />
      <Navbar />
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <PageText text="Edit Timetable" />
        </div>

        <div className=" ">
          <div className=" tw-mt-7">
            <p className=" tw-text-[#3E4347] tw-font-normal tw-text-sm">
              MONDAY
            </p>
            <p className=" Satoshi tw-text-base tw-text-[#3E4347] tw-font-normal">
              FIRST PERIOD
            </p>
            {/* Class/Break* */}
            <div className=" tw-flex tw-items-center tw-w-[643px] tw-mt-5 ">
              <div className=" tw-w-2/6">
                <p className=" Manrope tw-text-sm tw-font-semibold">
                  Class/Break*
                </p>
              </div>
              <div className=" tw-w-full tw-flex tw-items-center tw-relative ">
                <input
                  className=" tw-rounded-md tw-border tw-h-12 tw-w-full tw-px-2 tw-outline-none"
                  type="text"
                  placeholder="Class"
                />
              </div>
            </div>
            {/* Add Subject**/}
            <div className=" tw-flex tw-items-center tw-w-[643px] tw-mt-5 ">
              <div className=" tw-w-2/6">
                <p className=" Manrope tw-text-sm tw-font-semibold">
                  Add Subject*
                </p>
              </div>
              <div className=" tw-w-full tw-flex tw-items-center tw-relative ">
                <input
                  className=" tw-rounded-md tw-border tw-h-12 tw-w-full tw-px-2 tw-outline-none"
                  type="text"
                />
              </div>
            </div>
            {/* Start time* */}
            <div className=" tw-flex tw-items-center tw-w-[643px] tw-mt-5 ">
              <div className=" tw-w-2/6">
                <p className=" Manrope tw-text-sm tw-font-semibold">
                  Start time*
                </p>
              </div>
              <div className=" tw-w-full tw-flex tw-items-center tw-relative ">
                <input
                  className=" tw-rounded-md tw-border tw-h-12 tw-w-full tw-px-2 tw-outline-none"
                  type="time"
                />
              </div>
            </div>
            {/* End time* */}
            <div className=" tw-flex tw-items-center tw-w-[643px] tw-mt-5 ">
              <div className=" tw-w-2/6">
                <p className=" Manrope tw-text-sm tw-font-semibold">
                  End time*
                </p>
              </div>
              <div className=" tw-w-full tw-flex tw-items-center tw-relative ">
                <input
                  className=" tw-rounded-md tw-border tw-h-12 tw-w-full tw-px-2 tw-outline-none"
                  type="time"
                />
              </div>
            </div>

            <div className="tw-w-[643px] tw-flex tw-justify-end tw-mt-3">
              <p className=" tw-text-[#2242B4] Satoshi tw-text-xs tw-underline">
                More Periods
              </p>
            </div>
          </div>
        </div>
        <div className=" tw-flex tw-items-center tw-justify-center tw-mt-5">
          <p className="tw-text-[#2242B4] Satoshi tw-text-xs tw-underline">
            Continue to Tuesday
          </p>
        </div>
        <div className=" tw-flex tw-items-center tw-justify-center tw-mt-20  tw-gap-5">
          <Buttons
            text="Cancel"
            color={"tw-tet-[#2242B4]"}
            css={"tw-border-[#2242B4] tw-border"}
          />
          <Buttons
            text="Create"
            bg={"tw-bg-[#2242B4]"}
            color={"tw-text-[#fff]"}
          />
        </div>
      </div>
    </div>
  );
};

export default EditTimetable;
