/* eslint-disable react/prop-types */
import { createContext } from "react";
import { useState } from "react";
import useAuthStore from "./Stores/AuthStore";

export const GlobalState = createContext();

const DataProvider = ({ children }) => {
	const [nav, setNav] = useState(false);
	let handleCapitalize = word => {
			let splitter = word.trim().split(" ");
			let firstCap = splitter[0].split("");
			let remaining = splitter.slice(1, splitter.length).join(" ");

			let firstCapOne = firstCap[0].toUpperCase();
			let firstCapTwo = firstCap.slice(1, firstCap.length).join("");

			let joinFirst = `${firstCapOne}${firstCapTwo}`;

			return `${joinFirst} ${remaining}`;
		},
		[subdomain, setSubdomain] = useState("");

	let numberWithCommas = x => {
		return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
	};

	const toggleNav = () => {
		setNav(!nav);
	};

	let auth = useAuthStore();

	let sidebarList = [
		{
			name: "Dashboard",
			url: "/dashboard",
		},
		{
			name: "Students",
			url: "/students",
		},
		{
			name: "Classes",
			url: "/classes",
		},
		{
			name: "Events",
			url: "/events",
		},
		{
			name: "Parents",
			url: "/parents",
		},
		{
			name: "Teachers",
			url: "/teachers",
		},
		{
			name: "Subjects",
			url: "/subjects",
		},
		{
			name: "My Profile",
			url: "/my-profile",
		},
	];

	const state = {
		handleCapitalize,

		numberWithCommas,

		sidebarList,
		auth,
		nav,
		toggleNav,
		setSubdomain,
		subdomain,
	};

	return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default DataProvider;
