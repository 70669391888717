import React from "react";
import Announcements from "../../assets/Announcement.png";
import { useNavigate } from "react-router-dom";

const AnnouncementCard = () => {
  const navigate = useNavigate();
  const announcement = [
    {
      id: "",
      title: "Announcements",
      text: "Lorem ipsum dolor sit amet consectetur. Ultricies amet suspendiss sed vulputate ornare id. Elit ac blandit lorem dolor congue sed euismod sed. Eget ante vel fringilla ut sem ornare vel venenatis in Urna dui nunc imperdiet varius consectetur magnis diam vivamus scelerisque. In imperdiet urna semper accumsan euismod velit. Justo sed vestibulum elit sed nunc dictum blandit. Vitae mi aenean tortodignissim. Risus semper faucibus lectus quam amet. Nibh donec morbeu ut scelerisque viverra. Sit euismod lectus faucibus mattis sapiendui. Diam nisl at sit eu eu. Dui neque ultricies risus scelerisque.",
      sptext:
        "Elementum malesuada morbi magna pellentesque tortor. Et odio adipiscing ipsum nisl. Vestibulum erat mattis etiam est pulvinar dis tincidunt amet leo. Quisqueduis arcu nec libero risus purus.",
      admin: "By Admin",
      date: "13/01/2024",
      image: Announcements,
    },
    {
      id: "",
      title: "Announcements",
      text: "Lorem ipsum dolor sit amet consectetur. Ultricies amet suspendiss sed vulputate ornare id. Elit ac blandit lorem dolor congue sed euismod sed. Eget ante vel fringilla ut sem ornare vel venenatis in Urna dui nunc imperdiet varius consectetur magnis diam vivamus scelerisque. In imperdiet urna semper accumsan euismod velit. Justo sed vestibulum elit sed nunc dictum blandit. Vitae mi aenean tortodignissim. Risus semper faucibus lectus quam amet. Nibh donec morbeu ut scelerisque viverra. Sit euismod lectus faucibus mattis sapiendui. Diam nisl at sit eu eu. Dui neque ultricies risus scelerisque.",
      sptext:
        "Elementum malesuada morbi magna pellentesque tortor. Et odio adipiscing ipsum nisl. Vestibulum erat mattis etiam est pulvinar dis tincidunt amet leo. Quisqueduis arcu nec libero risus purus.",
      admin: "By Admin",
      date: "13/01/2024",
      image: Announcements,
    },
    {
      id: "",
      title: "Announcements",
      text: "Lorem ipsum dolor sit amet consectetur. Ultricies amet suspendiss sed vulputate ornare id. Elit ac blandit lorem dolor congue sed euismod sed. Eget ante vel fringilla ut sem ornare vel venenatis in Urna dui nunc imperdiet varius consectetur magnis diam vivamus scelerisque. In imperdiet urna semper accumsan euismod velit. Justo sed vestibulum elit sed nunc dictum blandit. Vitae mi aenean tortodignissim. Risus semper faucibus lectus quam amet. Nibh donec morbeu ut scelerisque viverra. Sit euismod lectus faucibus mattis sapiendui. Diam nisl at sit eu eu. Dui neque ultricies risus scelerisque.",
      sptext:
        "Elementum malesuada morbi magna pellentesque tortor. Et odio adipiscing ipsum nisl. Vestibulum erat mattis etiam est pulvinar dis tincidunt amet leo. Quisqueduis arcu nec libero risus purus.",
      admin: "By Admin",
      date: "13/01/2024",
      image: Announcements,
    },
  ];
  return (
    <div className=" tw-grid tw-gap-5">
      {announcement.map((i) => (
        <div
          onClick={() => navigate("/create-event")}
          className=" tw-bg-white tw-shadow-lg tw-p-5  tw-cursor-pointer"
        >
          <div className=" tw-flex tw-items-center tw-justify-between">
            <p className="  tw-text-base tw-font-Kum tw-text-[#14238A] tw-font-semibold">
              {i.title}
            </p>
            <img className=" tw-w-2 tw-h-2" src={i.image} alt="" />
          </div>
          <div className="">
            <small className=" tw-text-sm Manrope ">
              {i.text}
              <p className=" tw-mt-3 ">{i.sptext}</p>
            </small>
          </div>
          <div className=" tw-flex tw-items-center tw-justify-between">
            <p className=" tw-text-[#FFC000]">{i.admin}</p>
            <p className=" tw-text-[#FFC000]">{i.date}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AnnouncementCard;
