import { createElement } from "react";

import { useParams, useNavigate } from "react-router-dom";
import useAuthStore from "./data/Stores/AuthStore";

const GeneratePage = (pageName, folder) => {
	const component = () => require(`./${folder}/${pageName}`).default;
	let navigate = useNavigate();
	try {
		return createElement(component());
	} catch (e) {
		console.log(e);
		navigate("/");
	}
};

const PageRender = () => {
  const { isAuth, userPage } = useAuthStore(),
		{ step, id, page } = useParams(),
		escape2 = ["check-out", "check-out-done", "details-register"];

	let pageName = "";
	if (step) {
		pageName = `${page}/${id}/${"[id]"}`;
	} else if (id) {
		if (
			(page === "pricing" && escape2.includes(id)) ||
			(page === "register" && escape2.includes(id)) ||
			(page === "students" && escape2.includes(id)) ||
			(page === "learning" && escape2.includes(id))
		) {
			pageName = `${page}/${id}`;
		} else {
			pageName = `${page}/${"[id]"}`;
		}
	} else {
		pageName = `${page}`;
	}
	return GeneratePage(
		pageName,
		isAuth && userPage === "parent"
			? "parents"
			: isAuth && userPage === "teacher"
			? "teachers"
			: isAuth && userPage === "student"
			? "students"
			: "screens"
	);
};

export default PageRender;
