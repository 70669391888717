import React from "react";

const LessonGuides = () => {
  return (
    <div>
      <p>Lesson Guides</p>
    </div>
  );
};

export default LessonGuides;
