import React from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import profileimg from "../assets/profileimg.png";
import editProfile from "../assets/editProfile.png";
import Buttons from "../components/buttons/buttons";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthStore from "../data/Stores/AuthStore";
import StudentNavbar from "../components/StudentNavbar";
import ParentNavbar from "../components/ParentNavbar";

export const MainMyProfile = () => {
  const { logout } = useAuthStore();
  const navigate = useNavigate(),
    { state } = useLocation(),
    { userPage } = useAuthStore();
  const profile = [
    {
      text: "ID Number:",
      desc: "001",
    },
    {
      text: "Name:",
      desc: "Ann AdeOluwa",
    },
    {
      text: "Gender:",
      desc: "Female",
    },
    {
      text: "Class:",
      desc: "ROSE 5",
    },
    {
      text: "Subject:",
      desc: "ICT & Creative Art",
    },
    {
      text: "Date of Birth:",
      desc: "02/01/2004",
    },
    {
      text: "Religion:",
      desc: "Christianity",
    },
    {
      text: "Email address:",
      desc: "anne@gmail.com",
    },
    {
      text: "Phone number:",
      desc: "+234 801 111 2222",
    },
  ];
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="MY PROFILE" />
      {userPage === "teacher" ? (
        <Navbar />
      ) : userPage === "student" ? (
        <StudentNavbar />
      ) : userPage === "parent" ? (
        <ParentNavbar />
      ) : null}
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <div className=" tw-flex ">
            <div className=" tw-w-2/5 tw-grid">
              <div className=" tw-flex tw-items-center tw-justify-center">
                <img className=" tw-w-80 tw-h-80" src={profileimg} alt="" />
              </div>
              <div className=" tw-flex tw-items-center tw-justify-center">
                {userPage === "teacher" ? (
                  <div>
                    {" "}
                    <Buttons
                      color={"tw-text-[#fff]"}
                      bg={"tw-bg-[#5790FF]"}
                      text="Payment History"
                    />
                  </div>
                ) : userPage === "student" ? (
                  <div>
                    {" "}
                    <div>
                      {" "}
                      <div className=" tw-pt-4 tw-pb-2">
                        <p className=" Manrope tw-text-lg tw-font-medium tw-text-[#000]">
                          School Fee Status
                        </p>
                      </div>
                      <div className=" tw-flex tw-justify-center">
                        <Buttons
                          color={"tw-text-[#fff]"}
                          bg={"tw-bg-[#5790FF]"}
                          text="Payment History"
                        />
                      </div>
                    </div>
                  </div>
                ) : userPage === "parent" ? (
                  <div>
                    {" "}
                    <div className=" tw-pt-4 tw-pb-2">
                      <p className=" Manrope tw-text-lg tw-font-medium tw-text-[#000]">
                        School Fee Status
                      </p>
                    </div>
                    <div className=" tw-flex tw-justify-center">
                      <Buttons
                        color={"tw-text-[#fff]"}
                        bg={"tw-bg-[#FF5757]"}
                        text="Yet to Pay"
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <div className=" tw-w-3/5">
              <div className="  tw-w-[490px]">
                <div className=" tw-w-[329px]">
                  <div className=" tw-flex tw-items-center tw-justify-between tw-pr-10">
                    <small className=" tw-font-semibold tw-text-base tw-text-[#14238A] tw-font-Kum">
                      Ann AdeOluwa
                    </small>
                    <img
                      onClick={() => navigate("/edit-profile")}
                      className=" tw-w-6 tw-h-6 tw-cursor-pointer"
                      src={editProfile}
                      alt=""
                    />
                  </div>
                  <div className=" tw-py-4">
                    <p className=" Manrope tw-text-sm tw-text-[#000] tw-font-light">
                      Sed ut perspiciatis unde omnis iste natus error sit
                      voluptatem accusantium doloremque laudantium, totam rem
                      aperiam, eaque ipsa
                    </p>
                  </div>

                  <div className="">
                    {profile.map((i) => (
                      <div className=" tw-flex tw-items-center ">
                        <div className=" tw-w-6/12">
                          <p className=" tw-font-Kum tw-font-normal tw-text-sm tw-text-[#000]">
                            {i.text}
                          </p>
                        </div>
                        <div className=" tw-w-6/12 ">
                          <p className="tw-font-Kum tw-font-semibold tw-text-base tw-text-[#000]">
                            {i.desc}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <p
            className=" tw-cursor-pointer"
            onClick={() => {
              logout();
              navigate("/");
            }}
          >
            logout
          </p>
        </div>

        <div className=" ">
          {userPage === "teacher" ? (
            <div></div>
          ) : userPage === "student" ? (
            <div className="tw-flex tw-items-center tw-justify-between tw-pl-20 tw-pr- tw-mt-10">
              <div>
                <p className=" Manrope tw-text-lg tw-text-blue">
                  View Payment History
                </p>
              </div>
              <div>
                <p className=" Manrope tw-text-base tw-font-semibold tw-text-[#3C3C3C]">
                  Attendance
                </p>
                <div className=" tw-mt-3 tw-border tw-rounded-lg tw-w-80 tw-p-3">
                  <div className=" tw-grid">
                    <small className=" Manrope tw-text-sm tw-font-medium tw-text-[#3C3C3C]">
                      Total Classes : 145
                    </small>
                    <small className=" tw-pt-3 Manrope tw-text-[13px] tw-font-normal tw-text-[#60DF6F]">
                      Attended : 120
                    </small>
                    <small className=" Manrope tw-text-[13px] tw-font-normal tw-text-[#F93333]">
                      Missed : 25
                    </small>
                  </div>
                  <div className=" tw-grid tw-justify-center tw-items-center tw-pt-3">
                    <Buttons
                      text="View details"
                      css={"tw-bg-[#2C62EE] tw-text-[#fff]"}
                    />
                  </div>
                </div>
              </div>
              <div>
                <p className=" Manrope tw-text-base tw-font-semibold tw-text-[#3C3C3C]">
                  Exams
                </p>
                <div className=" tw-mt-3 tw-border tw-rounded-lg tw-w-80 tw-p-3">
                  <div className=" tw-grid">
                    <small className=" Manrope tw-text-sm tw-font-medium tw-text-[#3C3C3C]">
                      Total Exams : 245
                    </small>
                    <div className=" tw-flex tw-justify-between tw-items-center ">
                      <small className=" tw-pt-3 Manrope tw-text-[13px] tw-font-normal tw-text-[#6B6B6B]">
                        Attended : 120
                      </small>
                      <small className=" tw-pt-3 Manrope tw-text-[13px] tw-font-normal tw-text-[#9E9E9E]">
                        Unattempt : 125
                      </small>
                    </div>

                    <div className=" tw-flex tw-justify-between tw-items-center ">
                      <small className=" Manrope tw-text-[13px] tw-font-normal tw-text-[#60DF6F]">
                        Passed : 25
                      </small>
                      <small className=" Manrope tw-text-[13px] tw-font-normal tw-text-[#F93333]">
                        Missed : 25
                      </small>
                    </div>
                  </div>
                  <div className=" tw-grid tw-justify-center tw-items-center tw-pt-3">
                    <Buttons
                      text="View details"
                      css={"tw-bg-[#2C62EE] tw-text-[#fff]"}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : userPage === "parent" ? (
            <div className="tw-flex tw-items-center tw-justify-between tw-pl-20 tw-pr-52 tw-mt-10">
              <div>
                <p className=" Manrope tw-text-lg tw-text-blue">
                  View Payment History
                </p>
              </div>
              <div>
                <p className=" Manrope tw-text-base tw-font-semibold tw-text-[#3C3C3C]">
                  Attendance
                </p>
                <div className=" tw-mt-3 tw-border tw-rounded-lg tw-w-80 tw-p-3">
                  <div className=" tw-grid">
                    <small className=" Manrope tw-text-sm tw-font-medium tw-text-[#3C3C3C]">
                      Total Classes : 145
                    </small>
                    <small className=" tw-pt-3 Manrope tw-text-[13px] tw-font-normal tw-text-[#60DF6F]">
                      Attended : 120
                    </small>
                    <small className=" Manrope tw-text-[13px] tw-font-normal tw-text-[#F93333]">
                      Missed : 25
                    </small>
                  </div>
                  <div className=" tw-grid tw-justify-center tw-items-center tw-pt-3">
                    <Buttons
                      onClick={() => navigate("/view-attendance")}
                      text="View details"
                      css={"tw-bg-[#2C62EE] tw-text-[#fff]"}
                    />
                  </div>
                </div>
              </div>
              <div>
                <p className=" Manrope tw-text-base tw-font-semibold tw-text-[#3C3C3C]">
                  Exams
                </p>
                <div className=" tw-mt-3 tw-border tw-rounded-lg tw-w-80 tw-p-3">
                  <div className=" tw-grid">
                    <small className=" Manrope tw-text-sm tw-font-medium tw-text-[#3C3C3C]">
                      Total Exams : 245
                    </small>
                    <div className=" tw-flex tw-justify-between tw-items-center tw-pr-10">
                      <small className=" tw-pt-3 Manrope tw-text-[13px] tw-font-normal tw-text-[#6B6B6B]">
                        Attended : 120
                      </small>
                      <small className=" tw-pt-3 Manrope tw-text-[13px] tw-font-normal tw-text-[#9E9E9E]">
                        Unattempt : 125
                      </small>
                    </div>

                    <div className=" tw-flex tw-justify-between tw-items-center tw-pr-10">
                      <small className=" Manrope tw-text-[13px] tw-font-normal tw-text-[#60DF6F]">
                        Passed : 25
                      </small>
                      <small className=" Manrope tw-text-[13px] tw-font-normal tw-text-[#F93333]">
                        Missed : 25
                      </small>
                    </div>
                  </div>
                  <div className=" tw-grid tw-justify-center tw-items-center tw-pt-3">
                    <Buttons
                      onClick={() => navigate("/view-attendance")}
                      text="View details"
                      css={"tw-bg-[#2C62EE] tw-text-[#fff]"}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const MyProfile = () => <MainMyProfile />;

export default MyProfile;
