import React from "react";

const ModalContainer = ({ children, handleClose }) => {
  return (
    <div
      onClick={(e) => e.target === e.currentTarget && handleClose()}
      className="tw-fixed tw-inset-0 tw-z-50 tw-bg-gray-600 tw-bg-opacity-75 tw-transition-opacity tw-duration-700 tw-flex tw-items-center tw-justify-center tw-max-h-screen"
    >
      <div className="tw-w-full tw-max-w-md tw-bg-white tw-rounded-lg tw-shadow-2xl tw-transition-all tw-ease-in-out tw-duration-700 tw-p-4">
        {children}
      </div>
    </div>
  );
};

export default ModalContainer;
