import React from "react";
import { Bar } from "react-chartjs-2";
import "chart.js/auto";
import { RiArrowDownSLine } from "react-icons/ri";

const data = {
  labels: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ],
  datasets: [
    {
      label: "Passed",
      data: [60, null, 70, null, 90, 100, null, 95, null, 100, null, 95],
      backgroundColor: "rgba(54, 162, 235, 0.2)",
      borderColor: "rgba(54, 162, 235, 1)",
      borderWidth: 1,
    },
    {
      label: "Failed",
      data: [null, 35, null, 15, null, null, 20, null, 15, null, 10, null],
      backgroundColor: "rgba(255, 99, 132, 0.2)",
      borderColor: "rgba(255, 99, 132, 1)",
      borderWidth: 1,
    },
  ],
};

const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
      labels: {
        color: "#000",
        font: {
          size: 14,
        },
      },
    },
    title: {
      display: false,
    },
  },
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        color: "rgba(0, 0, 0, 0.1)",
      },
      ticks: {
        color: "#000",
        font: {
          size: 12,
        },
      },
    },
    x: {
      grid: {
        display: false,
      },
      ticks: {
        color: "#000",
        font: {
          size: 12,
        },
      },
    },
  },
};

const ParentBarChart = () => {
  return (
    <div className="tw-w-full tw-p-5  ">
      <div className=" tw-flex tw-justify-between tw-items-center">
        <h2 className="tw-text-xl tw-font-bold tw-text-center tw-mb-4">
          Assessments Overall
        </h2>

        <div className=" tw-flex tw-justify-center tw-items-center tw-gap-2 tw-bg-[#F6F6F6] tw-w-[108px] tw-h-10">
          <small className=" Satoshi tw-text-sm tw-text-blue">This Term</small>
          <RiArrowDownSLine size={16} />
        </div>
      </div>

      <Bar data={data} options={options} />
    </div>
  );
};

export default ParentBarChart;
