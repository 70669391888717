// import React from "react";

// const ClassTimetable = () => {
//   return (
//     <div>
//       <p></p>
//     </div>
//   );
// };

// export default ClassTimetable;

import React from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import "../style/style.css";
import Buttons from "../components/buttons/buttons";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthStore from "../data/Stores/AuthStore";
import StudentNavbar from "../components/StudentNavbar";
import ParentNavbar from "../components/ParentNavbar";
const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"];
const periods = [
  [
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#E4F5FF]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#FFF4E4]",
    },

    { time: "Short Break", color: "bg-gray-100" },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#FFF1FC]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#FFFCF3]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#F9FFFF]",
    },
    { time: "Long Break", color: "bg-gray-100" },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#F9FFFF]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#F9FFFF]",
    },

    { time: "Closing", color: "bg-gray-100" },
  ],
  // Tue
  [
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#E4F5FF]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#FFF4E4]",
    },

    { time: "Short Break", color: "bg-gray-100" },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#FFF1FC]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#FFFCF3]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#F9FFFF]",
    },
    { time: "Long Break", color: "bg-gray-100" },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#F9FFFF]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#F9FFFF]",
    },

    { time: "Closing", color: "bg-gray-100" },
  ],

  //   WEd
  [
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#E4F5FF]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#FFF4E4]",
    },

    { time: "Short Break", color: "bg-gray-100" },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#FFF1FC]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#FFFCF3]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#F9FFFF]",
    },
    { time: "Long Break", color: "bg-gray-100" },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#F9FFFF]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#F9FFFF]",
    },

    { time: "Closing", color: "bg-gray-100" },
  ],

  //   Thur
  [
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#E4F5FF]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#FFF4E4]",
    },

    { time: "Short Break", color: "bg-gray-100" },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#FFF1FC]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#FFFCF3]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#F9FFFF]",
    },
    { time: "Long Break", color: "bg-gray-100" },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#F9FFFF]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#F9FFFF]",
    },

    { time: "Closing", color: "bg-gray-100" },
  ],

  //   Fri

  [
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#E4F5FF]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#FFF4E4]",
    },

    { time: "Short Break", color: "bg-gray-100" },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#FFF1FC]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#FFFCF3]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#F9FFFF]",
    },
    { time: "Long Break", color: "bg-gray-100" },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#F9FFFF]",
    },
    {
      time: "9:00am - 10:00am",
      subject: "Mathematics",
      teacher: "Ann AdeOluwa",
      color: "tw-bg-[#F9FFFF]",
    },

    { time: "Closing", color: "bg-gray-100" },
  ],
];

const ClassTimetable = () => {
  const navigate = useNavigate(),
    { state } = useLocation(),
    { userPage } = useAuthStore();
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="TIMETABLE" />
      {userPage === "teacher" ? (
        <Navbar />
      ) : userPage === "student" ? (
        <StudentNavbar />
      ) : userPage === "parent" ? (
        <ParentNavbar />
      ) : null}
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div className="tw-mx-5 tw-bg-white tw-p-5 tw-shadow-md tw-rounded-lg">
          <div className=" tw-flex tw-items-center tw-justify-between">
            <div className=" tw-w-4/5 tw-flex tw-justify-end tw-pr-40">
              <h1 className="tw-text-center tw-text-2xl tw-font-bold tw-mb-5 ">
                JSS 1 CLASS TIMETABLE
              </h1>
            </div>

            {userPage === "teacher" ? (
              <>
                {" "}
                <div className=" tw-w-3/12 tw-flex tw-justify-end">
                  <Buttons
                    onClick={() => navigate("/edit-timetable")}
                    text="Update"
                    color={"tw-text-[#fff]"}
                    bg={"tw-bg-[#2242B4]"}
                  />
                </div>
              </>
            ) : (
              ""
            )}
          </div>
          <div className="tw-overflow-x-auto">
            <table className="tw-w-full -collapse ">
              <thead>
                <tr>
                  <th className=" tw-p-2 tw-font-Kum tw-text-sm tw-text-[#000] tw-font-light  "></th>
                  <th className=" tw-p-2 tw-font-Kum tw-text-sm tw-text-[#000] tw-font-light  ">
                    TIME 1
                  </th>
                  <th className=" tw-p-2 tw-font-Kum tw-text-sm tw-text-[#000] tw-font-light  ">
                    TIME 1
                  </th>
                  <th className=" tw-p-2 tw-font-Kum tw-text-sm tw-text-[#000] tw-font-light  ">
                    TIME 1
                  </th>
                  <th className=" tw-p-2 tw-font-Kum tw-text-sm tw-text-[#000] tw-font-light  ">
                    TIME 1
                  </th>
                  <th className=" tw-p-2 tw-font-Kum tw-text-sm tw-text-[#000] tw-font-light  ">
                    TIME 1
                  </th>
                  <th className=" tw-p-2 tw-font-Kum tw-text-sm tw-text-[#000] tw-font-light  ">
                    TIME 1
                  </th>
                  <th className=" tw-p-2 tw-font-Kum tw-text-sm tw-text-[#000] tw-font-light  ">
                    TIME 1
                  </th>
                  <th className=" tw-p-2 tw-font-Kum tw-text-sm tw-text-[#000] tw-font-light  ">
                    TIME 1
                  </th>
                  <th className=" tw-p-2 tw-font-Kum tw-text-sm tw-text-[#000] tw-font-light  ">
                    TIME 1
                  </th>
                </tr>
              </thead>
              <tbody>
                {days.map((day, dayIndex) => (
                  <React.Fragment key={dayIndex}>
                    <tr className=" tw-border-b-[#fff] tw-border-b-[20px]">
                      <td className=" tw-p-2 tw-font-Kum tw-text-base tw-font-light">
                        {day}
                      </td>
                      {periods[dayIndex].map((period, periodIndex) => (
                        <td
                          key={periodIndex}
                          className={`  tw-p-2 ${period.color || ""}`}
                        >
                          {period.time === "Short Break" ||
                          period.time === "Long Break" ||
                          period.time === "Closing" ? (
                            <div className="tw-flex tw-items-center tw-justify-center tw-h-full Manrope tw-text-sm -tw-rotate-45 tw-w-[74px] tw-text-center">
                              {period.time}
                            </div>
                          ) : (
                            <>
                              <p className=" tw-font-light tw-font-Kum tw-text-[#000] tw-text-[10px]">
                                {period.time}
                              </p>
                              <p className="tw-text-sm tw-font-semibold Satoshi">
                                {period.subject}
                              </p>
                              <p className="tw-text-xs ">
                                Teacher: {period.teacher}
                              </p>
                            </>
                          )}
                        </td>
                      ))}
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

const ClassTimetables = () => <ClassTimetable />;

export default ClassTimetable;
