import React from "react";

const RemoveSuccessfull = ({ onClick, text }) => {
  return (
    <div
      onClick={onClick}
      className="tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-black tw-bg-opacity-45 tw-grid tw-justify-center tw-items-center"
    >
      <div className=" tw-bg-white tw-w-[460px] tw-h-auto tw-py-5">
        <div>
          <div className=" tw-text-center tw-text-2xl tw-font-Kum tw-text-[#474747] tw-font-semibold">
            {text} Has Been Removed
          </div>
          <p className=" Manrope tw-font-light tw-text-center tw-text-xl tw-text-[#474747]">
            successfully!
          </p>
        </div>
        <div className=" tw-grid tw-justify-center tw-items-center">
          <div className="">
            <img
              className=" tw-w-48 tw-h-48"
              src={require("../../../assets/delete-tGm5hOovrY.png")}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RemoveSuccessfull;
