import React from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";

import { RiArrowDropDownLine } from "react-icons/ri";
import AllStudentsTable from "../components/tables/AllStudentsTable";
import { BranchSelectInput, SearchInput } from "../components/inputs";
import PaymentHistoryTable from "../components/tables/PaymentHistoryTable";
import PageText from "../components/texts/Pagetexts";

const PaymentHistory = () => {
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="PAYMENT HISTORY" />
      <Navbar />
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div className=" tw-flex tw-items-center tw-gap-5 tw-pb-2">
          <PageText text="Payment History" />
          <SearchInput
            placeholder="Search by name..."
            onChange={(e) => console.log(e.target.value)}
          />
          <BranchSelectInput
            placeholder="Select by branch"
            onChange={(e) => console.log(e.target.value)}
          />
        </div>
        <PaymentHistoryTable action="View details" />
      </div>
    </div>
  );
};

const PaymentHistorys = () => <PaymentHistory />;

export default PaymentHistory;
