import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { RiArrowDropDownLine } from "react-icons/ri";

const Inputs = ({
  placeholder,
  label,
  onChange,
  type,
  name,
  css,
  onMouseEnter,
  onMouseLeave,
}) => {
  return (
    <div>
      <div>
        {" "}
        <strong className="tw-text-base satoshi tw-font-medium tw-text-[#3e4347]">
          {label}
        </strong>
      </div>
      <input
        type={type || "text"}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        className={`tw-w-full ${css} tw-pl-6 tw-h-14 tw-text-base tw-text-plain tw-font-normal satoshi tw-border tw-border-[#E0E0E0]`}
        id=""
      />
    </div>
  );
};

export const TextInputs = ({
  placeholder,
  label,
  onChange,
  type,
  name,
  css,
  onMouseEnter,
  onMouseLeave,
  value,
  ...restProps
}) => {
  return (
    <div>
      <div>
        {" "}
        <strong className="tw-text-base satoshi tw-font-medium tw-text-[#3e4347]">
          {label}
        </strong>
      </div>
      <input
        type={type || "text"}
        name={name}
        onChange={onChange}
        placeholder={placeholder}
        className={`${css} lg:tw-w-full tw-rounded-2xl tw-w-full tw-outline-none tw-mt-2  tw-pl-6 tw-h-12 tw-text-base tw-text-plain tw-font-normal satoshi tw-border tw-border-[#E0E0E0]`}
        id=""
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        value={value}
        {...restProps}
      />
    </div>
  );
};

export const PasswordInputs = ({
  placeholder,
  label,
  onChange,
  type,
  name,
  css,
  onMouseEnter,
  onMouseLeave,
  value,
  ...restProps
}) => {
  const [show, setShow] = useState(false);
  return (
    <div>
      <div>
        {" "}
        <strong className="tw-text-base satoshi tw-font-medium tw-text-[#3e4347]">
          {label}
        </strong>
      </div>
      <div
        className={`${css} lg:tw-w-full tw-rounded-2xl tw-mt-2  tw-relative tw-h-12 tw-text-base tw-text-plain tw-font-normal satoshi tw-border tw-border-[#E0E0E0]`}
      >
        <input
          type={show ? "text" : "password"}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          className={`tw-h-full tw-pl-6 tw-w-full tw-outline-none tw-rounded-2xl focus:tw-border-none`}
          id=""
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
          value={value}
          {...restProps}
        />
        <div
          onClick={() => setShow(!show)}
          className="tw-absolute tw-cursor-pointer tw-top-5 tw-right-4"
        >
          {show ? <FaEyeSlash /> : <FaEye />}
        </div>
      </div>
    </div>
  );
};

export const SearchInput = ({ placeholder, onChange, type, css }) => {
  return (
    <input
      className={` ${css} tw-border-[#DDDEEE] tw-border tw-rounded-md tw-w-80 tw-h-10 placeholder:tw-text-sm tw-px-2 placeholder-[#757576]`}
      placeholder={placeholder}
      type={type || "text"}
      onChange={onChange}
    />
  );
};
export const Input = ({ placeholder, onChange, width, height, css }) => {
  return (
    <input
      className={`tw-border-[#DDDEEE] ${width} ${css} tw-outline-none tw-border tw-rounded-md tw-h-10 placeholder:tw-text-sm tw-px-2 placeholder-[#757576]`}
      placeholder={placeholder}
      type="text"
      onChange={onChange}
    />
  );
};

export const BranchSelectInput = ({
  placeholder,
  onChange,
  borderColor,
  height,
  width,
  rounded,
  css,
  type,
}) => {
  return (
    <div className="tw-flex tw-items-center tw-relative">
      <input
        className={`tw-border-[#DDDEEE] ${css} ${rounded} tw-border tw-rounded-md tw-w-80 tw-h-10 placeholder:tw-text-sm tw-px-2 placeholder-[#757576]`}
        placeholder={placeholder}
        type={type || "text"}
        onChange={onChange}
        style={{ borderColor: borderColor, width: width, height: height }}
      />
      <RiArrowDropDownLine
        className="tw-absolute tw-right-2 tw-cursor-pointer"
        size={24}
      />
    </div>
  );
};

export const Select = () => {
  return (
    <div>
      <select
        name=""
        className="tw-w-full tw-pl-6 tw-h-14 tw-text-base tw-text-plain tw-font-normal satoshi tw-border tw-border-[#E0E0E0]"
      >
        <option value="">How did you find us?</option>
      </select>
    </div>
  );
};

export const HooksForm = ({
	methods,
	name,
	rules,
	type,
	placeholder,
	label,
	css,
	required,
	...restProps
}) => {
	return (
		<Controller
			name={name}
			control={methods.control}
			rules={rules}
			render={({ field: { onChange, name, value } }) => (
				<fieldset className="">
					<div>
						{type === "password" ? (
							<PasswordInputs
								label={label}
								value={value}
								name={name}
								onChange={onChange}
								{...restProps}
							/>
						) : (
							<TextInputs
								name={name}
								value={value}
								required={required}
								type={type}
								placeholder={placeholder}
								label={label}
								css={css}
								onChange={onChange}
								{...restProps}
							/>
						)}
						<div className="tw-w-full lg:tw-w-[440px]">
							{methods.formState.errors[name] && (
								<span className="tw-text-xs tw-font-bold tw-text-red-600">
									{methods.formState.errors[name].message}
								</span>
							)}
						</div>
					</div>
				</fieldset>
			)}
		/>
	);
};

export default Inputs;
