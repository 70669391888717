import React from "react";
import Buttons from "../buttons/buttons";
import { useNavigate } from "react-router-dom";

const AddImage = ({ onClick }) => {
  const navigate = useNavigate();
  return (
    <div className="tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-black tw-bg-opacity-45 tw-grid tw-justify-center tw-items-center">
      <div className=" tw-bg-white tw-w-[500px] tw-rounded-lg tw-p-5 tw-h-auto tw-py-5">
        <div className=" tw-flex tw-items-center tw-justify-between tw-border-b tw-border-b-[#BDBDBD] ">
          <small className=" tw-font-Kum tw-text-lg tw-font-semibold">
            Create Post
          </small>
          <small
            onClick={onClick}
            className=" tw-text-[24px] tw-font-semibold tw-cursor-pointer"
          >
            &times;
          </small>
        </div>

        <div className=" tw-grid tw-justify-center tw-mt-5">
          <p className=" tw-font-light Manrope">Not More Than 2MB</p>
        </div>
        <div className=" tw-grid tw-justify-center tw-mt-1">
          <Buttons
            text="Select Your Image to Share"
            bg={"tw-bg-[#2A72A8] Manrope"}
            width={"tw-w-[310px]"}
            color={"tw-text-[#fff]"}
          />
        </div>

        <div className=" tw-flex tw-items-center tw-justify-center tw-mt-10 tw-gap-5">
          <Buttons
            onClick={() => navigate("/create-event")}
            css={"tw-border-[#2242B4] tw-border"}
            color={"tw-text-[#2242B4]"}
            text="Cancel"
          />

          <Buttons
            onClick={() => navigate("/create-event")}
            bg={"tw-bg-[#2242B4]"}
            color={"tw-text-[#fff]"}
            text="Done"
          />
        </div>
      </div>
    </div>
  );
};

export default AddImage;
