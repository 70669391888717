import Payment from "../students/payment";

const PaymentHistorys = () => <Payment />;

export default PaymentHistorys;

// import PaymentHistory from "../teachers/payment-history";

// const PaymentHistorys = () => <PaymentHistory />;

// export default PaymentHistory;
