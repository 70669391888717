import React, { useEffect } from "react";
import Header from "../components/header";
import StudentNavbar from "../components/StudentNavbar";
import loho from "../assets/loho.png";
import profileImage from "../assets/profileImage.png";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthStore from "../data/Stores/AuthStore";
import ParentNavbar from "../components/ParentNavbar";
import Navbar from "../components/Navbar";

const VResult = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const navigate = useNavigate(),
    { state } = useLocation(),
    { userPage } = useAuthStore();

  return (
    <div className="tw-bg-[#F9F9F9]">
      {userPage === "teacher" ? (
        <Header text="EXAMS" />
      ) : userPage === "student" ? (
        <Header text="EXAMS" />
      ) : userPage === "parent" ? (
        <Header text="EXAMS" />
      ) : null}

      {userPage === "teacher" ? (
        <Navbar />
      ) : userPage === "student" ? (
        <StudentNavbar />
      ) : userPage === "parent" ? (
        <ParentNavbar />
      ) : null}
      <div className=" tw-bg-white tw-mt-5  lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div className="tw-max-w-4xl tw-mx-auto tw-my-8 p-6 tw-bg-white tw-shadow-lg tw-rounded-lg">
          <div className=" tw-bg-[#1D43C9] tw-text-white tw-relative tw-p-4 tw-rounded-t-lg tw-w-full">
            <div className=" tw-flex tw-items-center">
              <img className=" tw-absolute tw-w-24 tw-h-24" src={loho} alt="" />

              <div className=" tw-w-full ">
                <div className=" tw-grid tw-justify-center tw-w-full">
                  <strong className=" tw-text-center tw-font-Kum tw-font-bold tw-text-3xl">
                    OLIVE MOUNTAINTOP SCHOOL
                  </strong>
                  <small className=" tw-py-2 tw-text-[15px] tw-text-white tw-font-Kum tw-text-center">
                    P.M.B 2000, OGBMOSHO, OYO STATE, NIGERIA
                  </small>
                  <small className=" tw-text-[15px] tw-text-white tw-font-Kum tw-text-center">
                    UNDER G AREA, BESIDES G & G FACTORY
                  </small>

                  <div className=" tw-flex tw-items-center tw-justify-between tw-mt-5 tw-px-5">
                    <div className="">
                      <span className=" tw-font-Kum tw-text-[13px] tw-font-semibold">
                        TEL:{" "}
                      </span>
                      <span className=" tw-font-Kum tw-text-[13px] tw-font-semibold">
                        +234 81 011 222 333
                      </span>
                    </div>
                    <div className="">
                      <span className=" tw-font-Kum tw-text-[13px] tw-font-semibold">
                        EMAIL:{" "}
                      </span>
                      <span className=" tw-font-Kum tw-text-[13px] tw-font-semibold">
                        olivemountaintop@gmail.com
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-p-4 border tw-border-t-0 tw-border-gray-200">
            <div className="tw-overflow-x-auto">
              <table className="tw-min-w-full tw-bg-white tw-border tw-border-gray-200">
                <tbody>
                  <tr>
                    <th className=" tw-font-Kum  tw-px-2  tw-py-1 tw-border tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold tw-text-[#000] tw-uppercase tw-tracking-wider">
                      Name
                    </th>
                    <td className=" tw-text-xs tw-px-2 tw-py-1 tw-whitespace-no-wrap tw-border tw-border-gray-200">
                      John Smith
                    </td>
                    <th className=" tw-font-Kum  tw-px-2  tw-py-1 tw-border tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold tw-text-[#000] tw-uppercase tw-tracking-wider">
                      Adm. Number
                    </th>
                    <td className=" tw-text-xs tw-px-2 tw-py-1 tw-whitespace-no-wrap tw-border tw-border-gray-200">
                      001A
                    </td>
                    <th className=" tw-font-Kum  tw-px-2  tw-py-1 tw-border tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold tw-text-[#000] tw-uppercase tw-tracking-wider">
                      Gender
                    </th>
                    <td className=" tw-text-xs tw-px-2 tw-py-1 tw-whitespace-no-wrap tw-border tw-border-gray-200">
                      Male
                    </td>
                    <td
                      tw-text-xs
                      rowSpan="6"
                      className="tw-px-6 tw-py-1 tw-whitespace-no-wrap tw-border tw-border-gray-200"
                    >
                      <img
                        src={profileImage}
                        alt="Profile"
                        className="tw-w-24 tw-h-24 tw-rounded-full tw-object-cover"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th className=" tw-font-Kum  tw-px-2  tw-py-1 tw-border tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold tw-text-[#000] tw-uppercase tw-tracking-wider">
                      Class
                    </th>
                    <td className=" tw-text-xs tw-px-2 tw-py-1 tw-whitespace-no-wrap tw-border tw-border-gray-200">
                      Rose 5
                    </td>
                    <th className=" tw-font-Kum  tw-px-2  tw-py-1 tw-border tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold tw-text-[#000] tw-uppercase tw-tracking-wider">
                      Term
                    </th>
                    <td className=" tw-text-xs tw-px-2 tw-py-1 tw-whitespace-no-wrap tw-border tw-border-gray-200">
                      2nd Term
                    </td>
                    <th className=" tw-font-Kum  tw-px-2  tw-py-1 tw-border tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold tw-text-[#000] tw-uppercase tw-tracking-wider">
                      Year
                    </th>
                    <td className=" tw-text-xs tw-px-2 tw-py-1 tw-whitespace-no-wrap tw-border tw-border-gray-200">
                      2021/23
                    </td>
                  </tr>
                  <tr>
                    <th className=" tw-font-Kum  tw-px-2  tw-py-1 tw-border tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold tw-text-[#000] tw-uppercase tw-tracking-wider">
                      Age
                    </th>
                    <td className=" tw-text-xs tw-px-2 tw-py-1 tw-whitespace-no-wrap tw-border tw-border-gray-200">
                      12
                    </td>
                    <th className=" tw-font-Kum  tw-px-2  tw-py-1 tw-border tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold tw-text-[#000] tw-uppercase tw-tracking-wider">
                      Class Age Avg
                    </th>
                    <td className=" tw-text-xs tw-px-2 tw-py-1 tw-whitespace-no-wrap tw-border tw-border-gray-200">
                      Nil
                    </td>
                    <th className=" tw-font-Kum  tw-px-2  tw-py-1 tw-border tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold tw-text-[#000] tw-uppercase tw-tracking-wider">
                      Group Population
                    </th>
                    <td className=" tw-text-xs tw-px-2 tw-py-1 tw-whitespace-no-wrap tw-border tw-border-gray-200">
                      20
                    </td>
                  </tr>
                  <tr>
                    <th className=" tw-font-Kum  tw-px-2  tw-py-1 tw-border tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold tw-text-[#000] tw-uppercase tw-tracking-wider">
                      Times Sch. Opened
                    </th>
                    <td className=" tw-text-xs tw-px-2 tw-py-1 tw-whitespace-no-wrap tw-border tw-border-gray-200">
                      120
                    </td>
                    <th className=" tw-font-Kum  tw-px-2  tw-py-1 tw-border tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold tw-text-[#000] tw-uppercase tw-tracking-wider">
                      Times Present
                    </th>
                    <td className=" tw-text-xs tw-px-2 tw-py-1 tw-whitespace-no-wrap tw-border tw-border-gray-200">
                      100
                    </td>
                    <th className=" tw-font-Kum  tw-px-2  tw-py-1 tw-border tw-border-gray-200 tw-bg-gray-100 tw-text-left tw-text-xs tw-font-semibold tw-text-[#000] tw-uppercase tw-tracking-wider">
                      Times Present
                    </th>
                    <td className=" tw-text-xs tw-px-2 tw-py-1 tw-whitespace-no-wrap tw-border tw-border-gray-200">
                      20
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <table className="tw-w-full tw-mt-6 tw-border tw-border-gray-300">
              <thead>
                <tr className="tw-bg-gray-200">
                  <th className=" tw-font-Kum  tw-p-2 tw-border tw-border-gray-300">
                    SUBJECT
                  </th>
                  <th className="tw-p-2 tw-border tw-border-gray-300">
                    1st CA (20)
                  </th>
                  <th className="tw-p-2 tw-border tw-border-gray-300">
                    2nd CA (20)
                  </th>
                  <th className="tw-p-2 tw-border tw-border-gray-300">
                    EXAMS (60)
                  </th>
                  <th className="tw-p-2 tw-border tw-border-gray-300">
                    TOTAL (100)
                  </th>
                  <th className="tw-p-2 tw-border tw-border-gray-300">GRADE</th>
                </tr>
              </thead>
              <tbody>
                {Array(8)
                  .fill()
                  .map((_, index) => (
                    <tr key={index}>
                      <td className="tw-p-2 tw-border tw-border-gray-300">
                        Mathematics
                      </td>
                      <td className="tw-p-2 tw-border tw-border-gray-300">
                        15
                      </td>
                      <td className="tw-p-2 tw-border tw-border-gray-300">
                        15
                      </td>
                      <td className="tw-p-2 tw-border tw-border-gray-300">
                        50
                      </td>
                      <td className="tw-p-2 tw-border tw-border-gray-300">
                        80
                      </td>
                      <td className="tw-p-2 tw-border tw-border-gray-300">A</td>
                    </tr>
                  ))}
              </tbody>
            </table>
            <div className="tw-mt-4">
              <p className="tw-font-bold">PSYCHOMOTOR ASSESSMENT</p>
              {/* Add any additional information here */}
            </div>
          </div>
        </div>

        {/* <div className="tw-bg-white tw-shadow-md tw-max-w-4xl tw-mx-auto tw-mt-10tw- border">
        
          <div className=" tw-bg-[#1D43C9] tw-text-white tw-p-4 tw-rounded-t-md">
            <div className="tw-flex tw-justify-between tw-items-center">
              <div>
                <h1 className="tw-text-2xl tw-font-bold">
                  OLIVE MOUNTAINTOP SCHOOL
                </h1>
                <p>P.M.B 2000, OGBOMOSHO, OYO STATE, NIGERIA</p>
                <p>UNDER G AREA, BESIDES G & G FACTORY</p>
                <p>TEL: +234 81 011 222 333</p>
                <p>EMAIL: olivemountaintop@gmail.com</p>
              </div>
              <div>
                <img
                  src="logo.png"
                  alt="School Logo"
                  className="w-16 h-16 rounded-full"
                />
              </div>
            </div>
          </div>

         
          <div className="tw-border p-4">
            <div className="tw-grid tw-grid-cols-4 tw-gap-4">
              <div>
                <strong>Name:</strong> John Smith
              </div>
              <div>
                <strong>Adm. Number:</strong> 001A
              </div>
              <div>
                <strong>Gender:</strong> Male
              </div>
              <div>
                <img
                  src="student-photo-url"
                  alt="Student"
                  className="tw-w-16 tw-h-16 rounded-full"
                />
              </div>
              <div>
                <strong>Class:</strong> Rose 5
              </div>
              <div>
                <strong>Term:</strong> 2nd Term
              </div>
              <div>
                <strong>Year:</strong> 2021/23
              </div>
              <div>
                <strong>Age:</strong> 12
              </div>
              <div>
                <strong>Class Age Avg:</strong> Nil
              </div>
              <div>
                <strong>Group Population:</strong> 20
              </div>
              <div>
                <strong>Times Sch. Opened:</strong> 120
              </div>
              <div>
                <strong>Times Present:</strong> 100
              </div>
              <div>
                <strong>Times Present:</strong> 20
              </div>
            </div>
          </div>

   
          <div className="tw-border-t tw-mt-4">
            <table className="tw-w-full tw-text-left tw-table-auto">
              <thead>
                <tr className="tw-bg-gray-200">
                  <th className="p-2">Subject</th>
                  <th className="p-2">1st CA (20)</th>
                  <th className="p-2">2nd CA (20)</th>
                  <th className="p-2">Exams (60)</th>
                  <th className="p-2">Total (100)</th>
                  <th className="p-2">Grade</th>
                </tr>
              </thead>
              <tbody>
                {[
                  "Mathematics",
                  "Mathematics",
                  "Mathematics",
                  "Mathematics",
                  "Mathematics",
                  "Mathematics",
                  "Mathematics",
                  "Mathematics",
                ].map((subject, index) => (
                  <tr key={index} className="tw-border-b">
                    <td className="tw-p-2">{subject}</td>
                    <td className="tw-p-2">15</td>
                    <td className="tw-p-2">15</td>
                    <td className="tw-p-2">50</td>
                    <td className="tw-p-2">80</td>
                    <td className="tw-p-2">A</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="tw-mt-4 tw-p-4">
            <h2 className="tw-font-bold">PSYCHOMOTOR ASSESSMENT</h2>
           
          </div>
        </div> */}
      </div>
    </div>
  );
};

const VResults = () => <VResult />;

export default VResult;
