import React, { useEffect, useState } from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import PageText from "../components/texts/Pagetexts";
import { useLocation, useNavigate } from "react-router-dom";
import Buttons from "../components/buttons/buttons";
import { BranchSelectInput, SearchInput } from "../components/inputs";
import Quiz from "../components/cards/students-card/Quiz";
import useAuthStore from "../data/Stores/AuthStore";
import StudentNavbar from "../components/StudentNavbar";
import Exams from "../components/cards/students-card/Exams";
import General from "../components/cards/General";
import ParentNavbar from "../components/ParentNavbar";

const Results = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [selectedTab, setSelectedTab] = useState("Quiz");
  const tabs = ["General", "Quiz", "Exams", "Assignment", "Classes"];
  const cards = {
    General: (
      <div>
        <General />
      </div>
    ),
    Quiz: (
      <div className="">
        <Quiz />
      </div>
    ),
    Exams: (
      <div>
        <Exams />
      </div>
    ),
    Assignment: <div className="card">Assignment Card Content</div>,
    Classes: <div className="card">Classes Card Content</div>,
  };
  const navigate = useNavigate(),
    { state } = useLocation(),
    { userPage } = useAuthStore();

  console.log(state);
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="STUDENT’S DETAILS" />
      {userPage === "teacher" ? (
        <Navbar />
      ) : userPage === "student" ? (
        <StudentNavbar />
      ) : userPage === "parent" ? (
        <ParentNavbar />
      ) : null}
      <div className=" tw-h-full lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5 tw-bg-white tw-mt-5">
        {userPage === "teacher" ? (
          <div className=" tw-flex tw-items-center tw-gap-3">
            <img
              className=" tw-w-6 tw-h-6 tw-mb-1"
              src={require("../assets/material-symbols_arrow-back.png")}
              alt=""
            />
            <PageText text={"Your Results"} />
          </div>
        ) : userPage === "student" ? (
          <div className=" tw-flex tw-items-center tw-gap-3">
            <img
              className=" tw-w-6 tw-h-6 tw-mb-1"
              src={require("../assets/material-symbols_arrow-back.png")}
              alt=""
            />
            <PageText text={"Your Results"} />
          </div>
        ) : userPage === "parent" ? (
          <div className=" tw-flex tw-items-center tw-gap-3">
            <img
              className=" tw-w-6 tw-h-6 tw-mb-1"
              src={require("../assets/material-symbols_arrow-back.png")}
              alt=""
            />
            <PageText text={"John’s Results"} />
          </div>
        ) : null}

        <div className="">
          <div className=" tw-flex tw-items-center tw-justify-between">
            <div className=" tw-justify-start tw-mt-3">
              <div className=" tw-flex tw-items-center tw-gap-4">
                <img
                  className=" tw-w-24 tw-h-24"
                  src={require("../assets/studentImg.png")}
                  alt=""
                />
                <div className=" tw-grid">
                  <div className=" Satoshi tw-text-xl tw-font-semibold tw-text-[#3C3C3C]">
                    John Smith
                  </div>
                  <small className=" Satoshi tw-text-[#3C3C3C] tw-text-sm">
                    Student ID : SMS001A
                  </small>
                  <small className="Satoshi tw-text-[#3C3C3C] tw-text-sm">
                    Gender : Male
                  </small>
                </div>
              </div>
            </div>
            {userPage === "teacher" ? (
              <div className=" tw-justify-end">
                <Buttons
                  onClick={() => navigate("/v-result")}
                  color="tw-text-[#2242B4]"
                  css="tw-border-[#2242B4] tw-border tw-text-sm"
                  text="View Repord Card"
                  width={"tw-w-[211px]"}
                />
              </div>
            ) : userPage === "student" ? (
              <div className=" tw-justify-end">
                <Buttons
                  onClick={() => navigate("/v-result")}
                  color="tw-text-[#2242B4]"
                  css="tw-border-[#2242B4] tw-border tw-text-sm"
                  text="View Repord Card"
                  width={"tw-w-[211px]"}
                />
              </div>
            ) : userPage === "parent" ? (
              <div></div>
            ) : null}
          </div>
        </div>

        <div className=" tw-flex tw-items-center tw-gap-5 tw-pb-2 tw-mt-5">
          <SearchInput
            placeholder="Search by name..."
            onChange={(e) => console.log(e.target.value)}
          />
          <BranchSelectInput
            placeholder="Select by branch"
            onChange={(e) => console.log(e.target.value)}
          />
        </div>

        <div>
          <ul className="tw-flex tw-items-center tw-gap-10">
            {tabs.map((tab) => (
              <li
                key={tab}
                className={`Inter tw-text-base tw-cursor-pointer ${
                  selectedTab === tab
                    ? "tw-text-[#3D70F5] tw-underline"
                    : "tw-text-[#707070]"
                }`}
                onClick={() => setSelectedTab(tab)}
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tw-mt-4">{cards[selectedTab]}</div>
        </div>

        {/* 
        <ul className=" tw-flex tw-items-center tw-gap-10">
          <li className=" Inter tw-text-base tw-text-[#707070] tw-cursor-pointer">
            General{" "}
          </li>
          <li className=" Inter tw-text-base tw-text-[#707070] tw-cursor-pointer">
            Quiz
          </li>
          <li className=" Inter tw-text-base tw-text-[#707070] tw-cursor-pointer">
            Exams
          </li>
          <li className=" Inter tw-text-base tw-text-[#707070] tw-cursor-pointer">
            Assignment
          </li>
          <li className=" Inter tw-text-base tw-text-[#707070] tw-cursor-pointer">
            Classes
          </li>
        </ul> */}
      </div>
    </div>
  );
};

const MyResults = () => <Results />;

export default Results;
