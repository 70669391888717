import React from "react";
import Header from "../components/header";
import ParentNavbar from "../components/ParentNavbar";
import PageText from "../components/texts/Pagetexts";
import Buttons from "../components/buttons/buttons";
import ManageWardsTable from "../components/tables/ManageWardsTable";
import { useNavigate } from "react-router-dom";

const ManageWards = () => {
  const navigate = useNavigate();
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="MANAGE WARD(S)" />
      <ParentNavbar />
      <div className=" tw-mt-5  lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <div className=" tw-flex tw-items-center tw-justify-between">
            <PageText text="My Wards" />
            <Buttons
              onClick={() => navigate("/new-ward")}
              text="Add kid"
              css={"tw-text-[#fff] tw-w-[106px] tw-h-[41px] tw-bg-[#2242B4]"}
            />
          </div>

          <div>
            <ManageWardsTable action="View details" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManageWards;
