// import React from "react";

// const CreateEvent = () => {
//   return (
//     <div>
//       <p></p>
//     </div>
//   );
// };

// export default CreateEvent;

import React, { useState } from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import userimage from "../assets/userimage.png";
import { Button } from "bootstrap";
import Buttons from "../components/buttons/buttons";
import ActionDone from "../components/cards/ActionDone";
import { useNavigate } from "react-router-dom";
import create from "../assets/create.png";

const CreateEvent = () => {
  const [isUpdate, setIsUpdate] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="COMMUNICATIONS" />
      <Navbar />
      <div className="tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <p className=" tw-font-Kum tw-text-base tw-text-[#000] tw-font-semibold">
            Create Event
          </p>
        </div>
        <div className="  tw-w-[800px] tw-space-y-5">
          <div className=" tw-flex tw-items-center">
            <div className=" tw-w-1/4">
              <small className=" tw-text-sm tw-font-semibold tw-text-[#000]">
                Event Name*
              </small>
            </div>
            <div className=" tw-w-3/4">
              <input
                placeholder="End of the Year Party"
                type="text"
                className=" tw-rounded-md  tw-outline-none tw-border tw-w-full tw-h-12 placeholder:tw-text-sm tw-text-sm tw-placeholder-text-[#757576] tw-px-2"
              />
            </div>
          </div>
          <div className=" tw-flex tw-items-center">
            <div className=" tw-w-1/4">
              <small className=" tw-text-sm tw-font-semibold tw-text-[#000]">
                Event Date*
              </small>
            </div>
            <div className=" tw-w-3/4">
              <input
                placeholder="End of the Year Party"
                type="date"
                className=" tw-rounded-md tw-w-full tw-outline-none tw-border tw-h-12 placeholder:tw-text-sm tw-text-sm tw-placeholder-text-[#757576] tw-px-2"
              />
            </div>
          </div>
          <div className=" tw-flex ">
            <div className=" tw-w-1/4">
              <small className=" tw-text-sm tw-font-semibold tw-text-[#000]">
                Event Date*
              </small>
            </div>
            <div className=" tw-w-3/4">
              <textarea
                className=" tw-border tw-rounded-md tw-w-full tw-outline-none tw-p-2"
                name=""
                rows={5}
                cols={2}
                id=""
              ></textarea>
            </div>
          </div>
        </div>
        <div className=" tw-mt-10">
          <div className=" tw-flex tw-items-center tw-gap-5">
            <img className=" tw-w-48 tw-h-48" src={create} alt="" />
            <div>
              <p className=" Manrope tw-text-sm tw-text-[#000] tw-font-semibold ">
                Change Photo (150px X 150px)
              </p>
              <div className=" tw-flex tw-items-center">
                <Buttons css={"tw-text-[14px] tw-border "} text="Choose File" />
                <small className=" tw-text-xs tw-text-[#757576]">
                  No File Chosen
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className=" tw-flex tw-items-center tw-justify-center tw-mt-10 tw-gap-5">
          <Buttons
            onClick={() => setIsUpdate(!isUpdate)}
            bg={"tw-bg-[#2242B4]"}
            color={"tw-text-[#fff]"}
            text="Add event"
          />
          {isUpdate && (
            <ActionDone text="Action Done" onClick={() => setIsUpdate(false)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CreateEvent;
