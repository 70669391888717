import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const TodayTable = ({ action }) => {
  const navigate = useNavigate();
  const [checkedItems, setCheckedItems] = useState({});

  const headers = [
    "",
    "ID",
    "Name",
    "Gender",
    "Class",
    "Parent",
    "Address",
    "Date of Birth",
    "Phone",
  ];

  const data = [
    {
      id: 1,
      name: "John Smith",
      gender: "male",
      Class: "Rose 5",
      Parent: "Bob Smith",
      Address: "12 Ade Str., Ikeja",
      DateofBirth: "31/12/2001",
      Phone: "0801 111 2222",
    },
    {
      id: 2,
      name: "John Smith",
      gender: "male",
      Class: "Rose 5",
      Parent: "Bob Smith",
      Address: "12 Ade Str., Ikeja",
      DateofBirth: "31/12/2001",
      Phone: "0801 111 2222",
    },
    {
      id: 3,
      name: "John Smith",
      gender: "male",
      Class: "Rose 5",
      Parent: "Bob Smith",
      Address: "12 Ade Str., Ikeja",
      DateofBirth: "31/12/2001",
      Phone: "0801 111 2222",
    },
    {
      id: 4,
      name: "John Smith",
      gender: "male",
      Class: "Rose 5",
      Parent: "Bob Smith",
      Address: "12 Ade Str., Ikeja",
      DateofBirth: "31/12/2001",
      Phone: "0801 111 2222",
    },
    {
      id: 5,
      name: "John Smith",
      gender: "male",
      Class: "Rose 5",
      Parent: "Bob Smith",
      Address: "12 Ade Str., Ikeja",
      DateofBirth: "31/12/2001",
      Phone: "0801 111 2222",
    },
    {
      id: 6,
      name: "John Smith",
      gender: "male",
      Class: "Rose 5",
      Parent: "Bob Smith",
      Address: "12 Ade Str., Ikeja",
      DateofBirth: "31/12/2001",
      Phone: "0801 111 2222",
    },
    {
      id: 7,
      name: "John Smith",
      gender: "male",
      Class: "Rose 5",
      Parent: "Bob Smith",
      Address: "12 Ade Str., Ikeja",
      DateofBirth: "31/12/2001",
      Phone: "0801 111 2222",
    },
  ];

  const handleHeaderCheckboxChange = () => {
    const allChecked = Object.keys(checkedItems).length === data.length;
    const newCheckedItems = {};

    if (!allChecked) {
      data.forEach((item) => {
        newCheckedItems[item.id] = true;
      });
    }

    setCheckedItems(newCheckedItems);
  };

  const handleCheckboxChange = (id) => {
    setCheckedItems((prev) => {
      const newCheckedItems = { ...prev };
      if (newCheckedItems[id]) {
        delete newCheckedItems[id];
      } else {
        newCheckedItems[id] = true;
      }
      return newCheckedItems;
    });
  };

  return (
    <div className="tw-mt-5">
      <div className="tw-w-full">
        <table className="tw-table tw-w-full">
          <thead
            style={{
              paddingLeft: "1.25rem !important",
            }}
            className="tw-h-14 tw-bg-[#F0F6FF] tw-w-full tw-mb-4 tw-text-md tw-capitalize tw-font-bold kum tw-text-plain"
          >
            <tr>
              {headers?.map((header, index) => (
                <th key={index} className={`${index === 0 && "tw-pl-4"}`}>
                  {index === 0 ? (
                    <input
                      type="checkbox"
                      onChange={handleHeaderCheckboxChange}
                      checked={Object.keys(checkedItems).length === data.length}
                    />
                  ) : (
                    header
                  )}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="tw-w-full">
            {data?.map((item, index) => (
              <tr
                key={index}
                style={{
                  boxShadow: "0px 5px 10px 0px #0000000A",
                }}
                className="tw-w-full tw-text-sm satoshi tw-mb-3 tw-font-light tw-text-black"
              >
                <td className="tw-py-3 tw-pr-4 tw-pl-4">
                  <input
                    type="checkbox"
                    checked={!!checkedItems[item.id]}
                    onChange={() => handleCheckboxChange(item.id)}
                  />
                </td>
                <td className="tw-py-3 tw-pr-4 tw-pl-3">{item.id}</td>
                <td className="tw-py-3 tw-capitalize tw-pr-4">{item.name}</td>
                <td className="tw-py-3 tw-pr-4">{item.gender}</td>
                <td className="tw-py-3 tw-pr-4 tw-capitalize">{item.Class}</td>
                <td className="tw-py-3 tw-pr-4 tw-capitalize">{item.Parent}</td>
                <td className="tw-py-3 tw-pr-4 tw-max-w-16 tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
                  {item.Address}
                </td>
                <td className="tw-py-3 tw-pr-4 tw-capitalize">{item.Phone}</td>
                <td className="tw-py-3 tw-pr-4">{item.DateofBirth}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TodayTable;
