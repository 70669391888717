import React from "react";
import CheckAnimation from "./CheckAnimation";

const ActionCompleted = ({ onClick, text }) => {
  return (
    <div className="tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-black tw-bg-opacity-45 tw-grid tw-justify-center tw-items-center">
      <div className=" tw-bg-white tw-w-[360px] tw-h-auto tw-py-5">
        <div>
          <div className=" tw-text-center tw-text-2xl tw-font-Kum tw-text-[#474747] tw-font-semibold">
            {text}
          </div>
          <p className=" Manrope tw-font-light tw-text-center tw-text-xl tw-text-[#474747]">
            successfully!
          </p>
        </div>
        <div className=" tw-grid tw-justify-center tw-items-center tw-py-5">
          <div className=" tw-w-20 tw-h-20">
            {" "}
            <CheckAnimation />
          </div>
        </div>

        <div className=" tw-flex tw-items-center tw-justify-center tw-pt-5">
          <p
            onClick={onClick}
            className="tw-text-sm tw-text-[#2242B4] tw-cursor-pointer"
          >
            OKAY!
          </p>
        </div>
      </div>
    </div>
  );
};

export default ActionCompleted;
