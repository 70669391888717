// import React from "react";

// const ExamDetails = () => {
//   return (
//     <div>
//       <p></p>
//     </div>
//   );
// };

// export default ExamDetails;

import React from "react";

// import { TimeStamp } from "../assessments/[id]";
import Check from "../assets/mdi_check-all.png";
import VectorClo from "../assets/VectorClo.png";
import VectorCal from "../assets/VectorCal.png";
import Vectorone from "../assets/Vectorone.png";
import Icon from "../assets/Icon.png";
import People from "../assets/People.png";
import { FaEye } from "react-icons/fa";
import Img from "../assets/People.png";
import Elipone from "../assets/Ellipse154.png";
import Ellipsetwo from "../assets/Ellipsetwo.png";
import Ellipsefour from "../assets/Ellipsefour.png";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import PageText from "../components/texts/Pagetexts";

const ExamDetails = () => {
  const coordinators = [
    {
      img: Elipone,
      text: "Ann AdeOluwa",
    },
    {
      img: Ellipsetwo,
      text: "Ann AdeOluwa",
    },
    {
      img: Ellipsefour,
      text: "Ann AdeOluwa",
    },
    {
      img: Ellipsetwo,
      text: "Ann AdeOluwa",
    },
  ];
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="STUDENT’S DETAILS" />
      <Navbar />
      <div className="tw-p-5">
        <div className="tw-h-full lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5 tw-bg-white tw-mt-5 ">
          <div>
            <PageText text={"Exam Detail"} />
            <div className="tw-h-10 tw-w-full tw-rounded-xl  tw-flex tw-items-center tw-gap-2">
              <div className=" tw-w-[442px] tw-rounded-md tw-bg-[#ECF2FF] tw-grid tw-justify-center tw-items-center  ">
                <p className=" tw-text-[#3D70F5] Inter tw-relative tw-top-2 tw-text-base">
                  Result Declared on 12:30 AM | 22 December 2023
                </p>
              </div>
              <img className=" tw-w-4 tw-h-4" src={Check} alt="" />
            </div>

            <div className=" tw-mt-3">
              <small className=" Manrope tw-text-[#434343] tw-text-base">
                2023/2024 2nd Term Exam
              </small>
            </div>
            <div className=" tw-flex tw-items-center tw-gap-5 tw-mt-3">
              <div className=" tw-items-center tw-flex tw-gap-2">
                <img className=" tw-w-4 tw-h-4" src={VectorClo} alt="" />
                <small className=" Manrope tw-text-sm tw-font-light tw-text-[#989898]">
                  12:40 P:M
                </small>
              </div>
              <div className=" tw-items-center tw-flex tw-gap-2">
                <img className=" tw-w-4 tw-h-4" src={VectorCal} alt="" />
                <small className=" Manrope tw-text-sm tw-font-light tw-text-[#989898]">
                  03 Dec 2023
                </small>
              </div>
            </div>
            <div className=" tw-mt-5">
              <p className=" Manrope tw-text-[#434343] tw-text-xs tw-font-light">
                Subject:{" "}
                <span className=" tw-font-normal tw-text-sm">
                  Computer Science
                </span>
              </p>

              <div className=" tw-flex tw-items-center tw-gap-5">
                <p className=" Manrope tw-text-lg tw-font-semibold tw-text-[#434343]">
                  Total Marks : 100
                </p>
                <p className=" Manrope tw-text-lg tw-font-semibold tw-text-[#434343]">
                  Total Questions : 50
                </p>
              </div>

              <p className=" Manrope tw-text-sm tw-text-[#434343] tw-font-light">
                Passing Percentage{" "}
                <span className="Manrope tw-text-sm tw-text-[#3D70F5] tw-font-light">
                  70%
                </span>
              </p>
            </div>

            <div className="">
              <small>Question Paper</small>
              <div className=" tw-flex tw-items-center tw-gap-2">
                <div className=" tw-flex tw-items-center tw-justify-center tw-border tw-border-[#D7D7D7] tw-w-72 tw-rounded-md ">
                  <p className=" tw-relative tw-top-2 Manrope tw-text-xs tw-font-semibold tw-text-[#434343]">
                    Networking Mid Term Question Paper.pdf
                  </p>
                </div>
                <p className=" tw-pt-3 tw-font-semibold Manrope tw-text-xs tw-text-[#3D70F5]">
                  View Questions
                </p>
              </div>
            </div>
            <div className=" tw-mt-5">
              <strong className=" tw-font-semibold tw-text-sm tw-text-[#000]">
                Coordinators
              </strong>
              <div className=" tw-flex tw-items-center tw-gap-5 tw-mt-2">
                {coordinators.map((i) => (
                  <div className=" tw-flex tw-items-center tw-gap-2">
                    <img className=" tw-w-6 tw-h-6" src={i.img} alt="" />{" "}
                    <small className=" tw-font-light Manrope tw-text-sm">
                      {i.text}
                    </small>{" "}
                  </div>
                ))}
              </div>
            </div>
            <div className=" tw-flex tw-mt-5 ">
              <div className=" tw-w-56">
                <strong className="  tw-text-sm Manrope tw-font-semibold tw-text-[#000]">
                  Students in Class
                </strong>
                <div className=" tw-flex tw-gap-2 tw-items-center tw-mt-2 ">
                  <img className=" tw-w-16 tw-h-6" src={People} alt="" />
                  <small>+45</small>
                </div>
                <small className=" tw-font-normal tw-text-[#3D70F5] Manrope">
                  View student’s results
                </small>
              </div>
              <div className=" tw-w-56">
                <strong className="  tw-text-sm Manrope tw-font-semibold tw-text-[#000]">
                  Students participated in exam
                </strong>
                <div className=" tw-flex tw-gap-2 tw-items-center tw-mt-2 ">
                  <img className=" tw-w-16 tw-h-6" src={People} alt="" />
                  <small>+45</small>
                </div>
              </div>
            </div>

            <div className=" tw-mt-5">
              <div className=" tw-flex tw-items-center  tw-gap-10">
                <small className=" tw-text-[#2242B4] tw-text-sm Manrope tw-font-semibold">
                  Teacher’s Notes
                </small>
                <img
                  className=" tw-w-[17px] tw-h-[16.17px]"
                  src={Vectorone}
                  alt=""
                />
              </div>
              <div className=" tw-w-[561px]">
                <p className="Manrope tw-text-sm tw-text-[#434343]">
                  Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et
                  massa mi. Aliquam in hendrerit urna. Pellentesque sit amet
                  sapien fringilla, mattis ligula consectetur, ultrices mauris.
                  Maecenas vitae mattis tellus. Nullam quis imperdiet augue.
                  Vestibulum auctor ornare leo, non suscipit magna interdum eu.
                  Curabitur pellentesque nibh nibh, at maximus ante fermentum
                  sit amet. Pellentesque commodo lacus at sodales sodales.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamDetails;
