import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { TOKEN } from "../Config";

const AuthStore = set => ({
	token: localStorage.getItem(TOKEN) || null,
	user: null,
	isAuth: false,
	loading: false,
	isRegistered: false,
	isLoggedIn: false,
	isUpdated: false,
	isPassword: null,
	institutionType: null,
	school: null,
	plans: null,
	userPage: "",
	login: payload => {
		localStorage.setItem(TOKEN, payload?.token);
		let predata = payload?.data || payload;
		set(
			state => ({
				user: predata,
				token: payload?.token,
				isLoggedIn: true,
				userPage: predata?.privilege,
			}),
			false,
			"login"
		);
	},
	setUser: payload => {
		set(state => ({ isUpdated: true, user: payload?.data }), false, "setUser");
	},
	setSchool: payload => {
		set(state => ({ school: payload?.data || payload }), false, "setSchool");
	},
	setPlans: payload => {
		set(state => ({ plans: payload?.data || payload }), false, "setPlans");
	},
	setInstitution: payload => {
		set(
			state => ({ institutionType: payload?.data || payload }),
			false,
			"setInstitution"
		);
	},
	getUser: payload => {
		let predata = payload?.data || payload;
		if (payload?.token) {
			localStorage.setItem(TOKEN, payload?.token);
		}
		set(
			state => ({
				user: predata,
				isAuth: predata ? true : false,
				loading: false,
				userPage: predata?.privilege || state?.userPage,
			}),
			false,
			"getUser"
		);
	},
	getUserFail: () => {
		set(state => ({ isAuth: false, loading: false }));
	},
	getUserLoading: () => {
		set(state => ({ loading: true }));
	},
	setPassword: () => {
		set(state => ({ isPassword: true }));
	},
	setUserFail: () => {
		set(state => ({
			isUpdated: false,
			isLoggedIn: false,
			isRegistered: false,
			isPassword: false,
		}));
	},
	logout: () => {
		localStorage.removeItem(TOKEN);
		localStorage.clear();
		set(
			state => ({
				isAuth: false,
				user: null,
				token: null,
				role: null,
			}),
			false,
			"logout"
		);
	},
});

const useAuthStore = create(
	devtools(
		persist(AuthStore, {
			name: "cedumas-pst",
		})
	)
);

export const MergedData = (data, payload) => {
	let ids = new Set(payload.map(d => d._id));
	let updatateData = [...payload, ...data.filter(d => !ids.has(d._id))];
	return updatateData?.sort((a, b) => a?.createdAt - b?.createdAt);
};

export const EditData = (data, payload) => {
	let updatateData =
		data?.length > 0
			? data.map(item => (item._id !== payload._id ? item : payload))
			: data;
	return updatateData;
};

export const DeleteData = (data, payload) => {
	let filterItem =
		data?.length > 0 ? [...data.filter(item => item._id !== payload._id)] : [];
	return filterItem;
};

export default useAuthStore;

export const getStorageUser = ({ getUser, logout }) => {
	if (localStorage.getItem(TOKEN)) {
		if (getUser) {
			getUser(JSON.parse(localStorage.getItem(TOKEN)));
		}
	} else {
		if (logout) logout();
	}
};
