// import React from "react";

// const StudentNavbar = () => {
//   return (
//     <div>
//       <p></p>
//     </div>
//   );
// };

// export default StudentNavbar;

import React, { useState } from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { Link, useNavigate } from "react-router-dom";
import DashButton from "../assets/DashButton.png";
import notification from "../assets/notification.png";
import ChevronDown from "../assets/ChevronDown.png";
import message from "../assets/message.png";
import userEllipse from "../assets/userEllipse.png";
import SelectScores from "./cards/grades-card/SelectScores";
import Buttons from "./buttons/buttons";

const StudentNavbar = () => {
  const [istoggle, setIsToggle] = useState(false);
  const [isLearning, setIsLearning] = useState(false);
  const [isGradings, setIsGradings] = useState(false);
  const [isAllGrades, setIsAllGrades] = useState(false);
  const [currentPage, setCurrentPage] = useState("Dashboard");

  const navigate = useNavigate();

  const handleLinkClick = (text) => {
    setCurrentPage(text);
    navigate(text); // Navigate to the clicked link
  };
  return (
    <div className=" tw-flex tw-items-center tw-bg-white tw-shadow-md tw-py-1 tw-justify-between lg:tw-px-8 md:tw-px-8 tw-px-5">
      <div className="tw-flex tw-items-center tw-gap-5">
        <img className="tw-w-12 tw-h-12" src={DashButton} alt="" />
        <p className="tw-text-[#FFC000] tw-text-lg tw-pt-3 tw-truncate tw-w-24">
          {currentPage}
        </p>
      </div>
      <div className=" tw-flex tw-items-center tw-gap-3 tw-font-Kumbh tw-text-sm">
        <ul className=" tw-flex tw-items-center tw-justify-between tw-gap-3 tw-text-[#6B7A99] tw-pt-3">
          <Link
            to="/home"
            className="tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
            onClick={() => handleLinkClick("Dashboard")}
          >
            Dashboard
          </Link>
          <Link
            to="/your-results"
            onClick={() => handleLinkClick("Students")}
            className="tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Check Results
          </Link>

          <Link
            to="/assessments"
            onClick={() => setIsLearning(!isLearning)}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Assessments
          </Link>

          <Link
            to="/e-library"
            onClick={() => handleLinkClick("Payment History")}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Library
          </Link>
          <Link
            to="/notes"
            onClick={() => handleLinkClick("Lesson Guides")}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Notes
          </Link>
          <Link
            to="/virtual-classroom"
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Virtual Classroom
          </Link>

          <Link
            to="/communication"
            onClick={() => handleLinkClick("Communication")}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Communication
          </Link>
          <Link
            to="/payment"
            onClick={() => handleLinkClick("Attendance")}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Payment
          </Link>
          <Link
            to="/attendance"
            onClick={() => handleLinkClick("Attendance")}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Attendance
          </Link>
          <Link
            to="/timetable"
            onClick={() => handleLinkClick("Timetable")}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            Timetable
          </Link>

          <Link
            to="/my-profile"
            onClick={() => handleLinkClick("My Profile")}
            className=" tw-no-underline tw-text-[#6B7A99] tw-text-[13px] tw-cursor-pointer"
          >
            My Profile
          </Link>
        </ul>
        <div className=" tw-flex tw-items-center tw-gap-2">
          <img
            onClick={() => navigate("/all-notifications")}
            className=" tw-w-10 tw-h-10 tw-cursor-pointer"
            src={notification}
            alt=""
          />
          <img
            onClick={() => navigate("/start-chatting")}
            className=" tw-w-7 tw-h-7 tw-cursor-pointer"
            src={message}
            alt=""
          />
          <img
            onClick={() => navigate("/my-profile")}
            className=" tw-w-10 tw-h-10 tw-cursor-pointer"
            src={userEllipse}
            alt=""
          />
        </div>
      </div>
      <div className=" lg:tw-hidden md:tw-block tw-block">
        <GiHamburgerMenu />
      </div>
    </div>
  );
};

export default StudentNavbar;
