import React from "react";

const Activities = ({ Activity }) => {
  return (
    <div>
      <div className=" tw-space-y-5">
        {Activity.map((i) => (
          <div
            key={i.id}
            className=" tw-h-10 tw-flex tw-items-center tw-justify-between"
          >
            <img className=" tw-w-10 tw-h-10" src={i.img} alt="" />
            <div className=" tw-grid tw-mb-1">
              <small className=" Satoshi tw-text-[13px] tw-font-semibold tw-text-[#2C323F]">
                {i.text}
              </small>
              <small className=" Satoshi tw-text-[11px] tw-font-light tw-text-[#808191]">
                {i.desc}
              </small>
            </div>
            <div className=" tw-mt-3 tw-ml-3">
              <p className=" tw-w-16 tw-h-6 tw-bg-[#EDF5FF] tw-rounded-full tw-text-[#808191] tw-text-[11px] tw-font-light tw-font-Roboto tw-flex tw-justify-center tw-items-center ">
                {i.time}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Activities;
