// import React from "react";

// const QuizCard = () => {
//   return (
//     <div>
//       <p></p>
//     </div>
//   );
// };

// export default QuizCard;

import React from "react";
import { useNavigate } from "react-router-dom";

const QuizCard = () => {
  const navigate = useNavigate();
  const colors = ["#FFF3F3", "#F3F9FF", "#F6FFF3", "#FAF2DE"];
  const getRandomColor = () =>
    colors[Math.floor(Math.random() * colors.length)];

  const assignments = [
    {
      submitted: "Submitted",
      text: "General Knowledge Homework",
      questions: "20 questions",
      assignment: "Go to Quiz",
    },
    {
      submitted: "Submitted",
      text: "General Knowledge Homework",
      questions: "20 questions",
      assignment: "Go to Quiz",
    },
    {
      submitted: "Submitted",
      text: "General Knowledge Homework",
      questions: "20 questions",
      assignment: "Go to Quiz",
    },
    {
      submitted: "Submitted",
      text: "General Knowledge Homework",
      questions: "20 questions",
      assignment: "Go to Quiz",
    },
    {
      submitted: "Submitted",
      text: "General Knowledge Homework",
      questions: "20 questions",
      assignment: "Go to Quiz",
    },
    {
      submitted: "Submitted",
      text: "General Knowledge Homework",
      questions: "20 questions",
      assignment: "Go to Quiz",
    },
    {
      submitted: "Submitted",
      text: "General Knowledge Homework",
      questions: "20 questions",
      assignment: "Go to Quiz",
    },
    {
      submitted: "Submitted",
      text: "General Knowledge Homework",
      questions: "20 questions",
      assignment: "Go to Quiz",
    },
  ];

  return (
    <div className="tw-grid tw-justify-center tw-items-center">
      <div className="tw-grid tw-grid-cols-4 tw-gap-5 tw-justify-center">
        {assignments.map((assignment, index) => (
          <div
            key={index}
            className="tw-w-64 tw-rounded-xl tw-grid tw-p-3"
            style={{ backgroundColor: getRandomColor() }}
          >
            <div className="tw-flex tw-items-center tw-justify-end">
              <div className="tw-flex tw-items-center tw-gap-2">
                <small className="Satoshi tw-text-[#000] tw-text-sm">
                  {assignment.submitted}
                </small>
                <div className="tw-bg-[#3BD92D] tw-w-2 tw-h-2 tw-rounded-full"></div>
              </div>
            </div>
            <div className="tw-items-center tw-justify-center tw-grid tw-mt-5">
              <small className="tw-text-center Satoshi tw-text-sm tw-font-normal">
                {assignment.text}
              </small>
              <strong className="tw-text-center Satoshi tw-text-[#1B1B1B] tw-font-semibold tw-text-base">
                {assignment.questions}
              </strong>
            </div>
            <div className="tw-grid tw-justify-center tw-items-center tw-mt-7">
              <p
                onClick={() => navigate("/assignment-questions")}
                className="tw-text-center tw-cursor-pointer Satoshi tw-text-sm tw-font-medium tw-text-[#2242B4]"
              >
                {assignment.assignment}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QuizCard;
