import React from "react";

// import PageText from "../../components/texts/pageText";

import Profile from "../../assets/profile.png";
import Mail from "../../assets/mail.png";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Header from "../../components/header";

import Buttons from "../../components/buttons/buttons";
import Navbar from "../../components/Navbar";

const StudentDetails = () => {
  const navigate = useNavigate(),
    { state } = useLocation();
  const details = [
    {
      title: "ID Number",
      value: "",
    },
    {
      title: "Name",
      value: `${state?.firstName || ""} ${state?.lastName || ""}`,
    },
    {
      title: "Gender",
      value: state?.gender,
    },
    {
      title: "Parent Name",
      value: `${state?.extras?.parent?.firstName || ""} ${
        state?.extras?.parent?.lastName || ""
      }`,
    },
    {
      title: "Class",
      value: state?.extras?.class?.name,
    },
    {
      title: "Date of Birth",
      value: state?.dateOfBirth
        ? moment(state?.dateOfBirth).format("DD/MM/YYYY")
        : null,
    },
    {
      title: "Religion",
      value: state?.religion || state?.extras?.religion,
    },
    {
      title: "Father Occupation",
      value: state?.extras?.parent?.extras?.occupation,
    },
    {
      title: "Email Address",
      value: state?.email,
    },
    {
      title: "Admission Date",
      value: state?.extras?.admissionDate
        ? moment(state?.extras?.admissionDate).format("DD/MM/YYYY")
        : null,
    },
  ];

  return (
    <div>
      <Header text="STUDENT’S DETAILS" />
      <Navbar />
      <div className="tw-mx-8 tw-h-full">
        {/* <PageText text={"Vew Student's Details"} /> */}
        <div className="tw-flex  tw-mt-8 tw-gap-4 tw-h-full">
          <div className="tw-w-[30%] tw-relative tw-h-full">
            <img src={Profile} alt="" className="" />
            <img src={Mail} alt="" className="tw-mt-6 tw-mx-auto" />
            <div className="tw-mt-20">
              <div className="tw-flex tw-justify-center">
                <div className="tw-mx-auto">
                  <h5 className="tw-text-xl tw-text-center tw-font-medium tw-text-black satoshi">
                    School Fee Status
                  </h5>
                  <div className="tw-flex tw-mt-4 tw-justify-center">
                    <Buttons bg={"#FF5757"} text={"Yet to Pay"} />
                  </div>
                  <h6 className="tw-text-xl tw-mt-6 tw-font-bold tw-text-csblue satoshi tw-text-center tw-cursor-pointer">
                    View Payment History
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-w-[40%]">
            <h4 className="tw-text-xl tw-font-bold tw-text-primary kum">
              {state?.firstName} {state?.lastName}
            </h4>
            <p className="tw-text-base tw-font-light tw-text-black manrope">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            </p>
            <div className="tw-space-y-4 tw-mt-5">
              {details?.map((it) => (
                <div className="tw-flex tw-gap-10">
                  <div className="tw-w-32">
                    <small className="tw-text-sm tw-font-normal kum tw-text-black">
                      {it?.title}:
                    </small>
                  </div>
                  <div className="">
                    <strong
                      className={`tw-text-sm tw-font-bold manrope tw-text-black ${
                        ["Gender", "Religion"]?.includes(it?.title)
                          ? "tw-capitalize"
                          : ""
                      }`}
                    >
                      {it?.value}
                    </strong>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="tw-w-[30%]">
            <div className="tw-grid tw-mt-8 tw-gap-3 tw-grid-cols-2">
              <Buttons
                bg={"white"}
                css={"tw-border tw-border-[#FF5757] tw-w-full"}
                color={"#FF5757"}
                text={"Delete Student"}
              />
              <Buttons
                onClick={() => navigate("/students/edit-student")}
                css={"tw-w-full"}
                text={"Edit details"}
              />
            </div>
            <div className="tw-mt-16">
              <h4 className="tw-text-base tw-font-semibold tw-text-[#3C3C3C] manrope">
                Attendance
              </h4>
              <div className="tw-p-6 tw-border tw-border-[#ECECEC] tw-rounded-md">
                <h5 className="tw-text-secondary tw-font-medium tw-text-sm manrope">
                  Total Classes : 145
                </h5>
                <h6 className="tw-text-xs tw-font-normal manrope tw-text-[#60DF6F]">
                  Attended : 120
                </h6>
                <h6 className="tw-text-xs tw-font-normal tw-text-[#F93333] manrope">
                  Missed : 25
                </h6>
                <div className="tw-mt-4 tw-flex tw-justify-end">
                  <Buttons bg={"#2C62EE"} text={"View details"} />
                </div>
              </div>
            </div>
            <div className="tw-mt-16">
              <h4 className="tw-text-base tw-font-semibold tw-text-[#3C3C3C] manrope">
                Exams
              </h4>
              <div className="tw-p-6 tw-border tw-border-[#ECECEC] tw-rounded-md">
                <h5 className="tw-text-secondary tw-font-medium tw-text-sm manrope">
                  Total Exams : 245
                </h5>
                <div className="tw-flex tw-justify-between tw-items-center tw-w-full">
                  <div>
                    <h5 className="tw-text-[#6B6B6B] tw-font-medium tw-text-xs manrope">
                      Attempted : 120
                    </h5>
                    <h6 className="tw-text-xs tw-font-normal manrope tw-text-[#60DF6F]">
                      Passed : 120
                    </h6>
                  </div>
                  <div>
                    <h5 className="tw-text-[#6B6B6B] tw-font-medium tw-text-xs manrope">
                      Unattempted : 120
                    </h5>
                    <h6 className="tw-text-xs tw-font-normal manrope tw-text-[#F93333]">
                      Failed : 120
                    </h6>
                  </div>
                </div>
                <div className="tw-mt-4 tw-flex tw-justify-end">
                  <Buttons bg={"#2C62EE"} text={"View details"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDetails;
