// import React from "react";

// const ELibrary = () => {
//   return (
//     <div>
//       <p></p>
//     </div>
//   );
// };

// export default ELibrary;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Books from "../assets/Books.png";
import Header from "../components/header";
import StudentNavbar from "../components/StudentNavbar";
import PageText from "../components/texts/Pagetexts";
import { SearchInput } from "../components/inputs";
import Buttons from "../components/buttons/buttons";
import eLib from "../assets/eLib.png";

const ELibrary = () => {
  const navigate = useNavigate(),
    [tab, setTab] = useState("all"),
    tabs = [
      "all",
      "available",
      "popular",
      "free",
      "paid",
      "borrowed",
      "returned",
    ],
    books = [
      {
        title: "The Principle of Design",
        author: "by Murray Cope",
        desc: "Lorem ipsum dolor sit amet consectetur. Diam egestas.e",
        mode: "popular",
      },
      {
        title: "The Principle of Design",
        author: "by Murray Cope",
        desc: "Lorem ipsum dolor sit amet consectetur. Diam egestas.e",
        mode: "free",
      },
      {
        title: "The Principle of Design",
        author: "by Murray Cope",
        desc: "Lorem ipsum dolor sit amet consectetur. Diam egestas.e",
        mode: "borrowed",
      },
      {
        title: "The Principle of Design",
        author: "by Murray Cope",
        desc: "Lorem ipsum dolor sit amet consectetur. Diam egestas.e",
        mode: "popular",
      },
      {
        title: "The Principle of Design",
        author: "by Murray Cope",
        desc: "Lorem ipsum dolor sit amet consectetur. Diam egestas.e",
        mode: "popular",
      },
      {
        title: "The Principle of Design",
        author: "by Murray Cope",
        desc: "Lorem ipsum dolor sit amet consectetur. Diam egestas.e",
        mode: "popular",
      },
    ];

  const library = [
    {
      title: "The Principle of Design",
      author: "by Murray Cope",
      desc: "Lorem ipsum dolor sit amet consectetur. Diam egestas.e",
      mode: "Read now",
    },
    {
      title: "The Principle of Design",
      author: "by Murray Cope",
      desc: "Lorem ipsum dolor sit amet consectetur. Diam egestas.e",
      mode: "Read now",
    },
    {
      title: "The Principle of Design",
      author: "by Murray Cope",
      desc: "Lorem ipsum dolor sit amet consectetur. Diam egestas.e",
      mode: "Read now",
    },
    {
      title: "The Principle of Design",
      author: "by Murray Cope",
      desc: "Lorem ipsum dolor sit amet consectetur. Diam egestas.e",
      mode: "Read now",
    },
    {
      title: "The Principle of Design",
      author: "by Murray Cope",
      desc: "Lorem ipsum dolor sit amet consectetur. Diam egestas.e",
      mode: "Read now",
    },
    {
      title: "The Principle of Design",
      author: "by Murray Cope",
      desc: "Lorem ipsum dolor sit amet consectetur. Diam egestas.e",
      mode: "Read now",
    },
  ];

  const [activeTab, setActiveTab] = useState("all");

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="E-LIBRARY" />
      <StudentNavbar />
      <div className=" tw-bg-white tw-mt-5  lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <div className="tw-flex tw-justify-between tw-items-center">
            <div className="tw-flex tw-gap-5 tw-items-center">
              <div>
                <div className="tw-flex tw-items-center tw-gap-5">
                  <strong
                    className={`tw-font-Kum tw-font-medium tw-text-lg ${
                      activeTab === "all" ? "tw-text-[#2242B4]" : ""
                    }`}
                    onClick={() => handleTabClick("all")}
                    style={{ cursor: "pointer" }}
                  >
                    All Library Data
                  </strong>
                  <strong
                    className={`tw-font-Kum tw-font-medium tw-text-lg ${
                      activeTab === "e-library" ? "tw-text-[#2242B4]" : ""
                    }`}
                    onClick={() => handleTabClick("e-library")}
                    style={{ cursor: "pointer" }}
                  >
                    E-Library
                  </strong>
                </div>
              </div>
              <SearchInput placeholder={"Search by name"} />
            </div>
            <div className="tw-flex tw-gap-5">
              {activeTab === "all" && (
                <Buttons
                  onClick={() => navigate("/borrow-book")}
                  text={"Borrow book"}
                  bg={"tw-bg-[#2242B4]"}
                  color={"tw-text-[#fff]"}
                />
              )}
            </div>
          </div>
          <div className="tw-mt-10">
            <div>
              {activeTab === "all" && (
                <div className="tw-mt-5">
                  <div className="tw-mt-10">
                    <div className="tw-flex tw-gap-6 tw-items-center">
                      {tabs?.map((it) => (
                        <h4
                          onClick={() => setTab(it)}
                          className={`tw-text-base manrope tw-font-medium tw-capitalize tw-cursor-pointer
                   ${tab === it ? "tw-text-[#3D70F5]" : "tw-text-[#707070]"}`}
                        >
                          {it}
                        </h4>
                      ))}
                    </div>
                    <div className="tw-mt-10 tw-grid tw-grid-cols-5 tw-gap-3">
                      {books?.map((it, i) => (
                        <div
                          onClick={() => navigate(`/e-library/${i}`)}
                          key={i}
                          style={{
                            boxShadow: "0px 10px 20px 0px #0000001A",
                          }}
                          className="tw-h-80 tw-cursor-pointer tw-bg-white tw-relative"
                        >
                          <img src={Books} alt="" className="tw-rounded-t-xl" />
                          <div className="tw-p-4">
                            <h6 className="tw-text-sm tw-font-bold manrope tw-text-black">
                              {it?.title}
                            </h6>
                            <small className="tw-text-md tw-font-light tw-text-black manrope">
                              {it?.author}
                            </small>
                            <div className="tw-mt-3">
                              <div className="tw-flex tw-justify-between tw-items-end">
                                <p className="tw-text-xs tw-w-36 tw-font-extralight tw-text-black manrope">
                                  {it?.desc}
                                </p>
                                <p className="tw-text-[10px] tw-font-bold manrope tw-uppercase  tw-text-yellow">
                                  {it?.mode}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
              {activeTab === "e-library" && (
                <div className="tw-mt-5">
                  <div className="tw-mt-10">
                    <div className="tw-flex tw-gap-6 tw-items-center">
                      {tabs?.map((it) => (
                        <h4
                          onClick={() => setTab(it)}
                          className={`tw-text-base manrope tw-font-medium tw-capitalize tw-cursor-pointer
                   ${tab === it ? "tw-text-[#3D70F5]" : "tw-text-[#707070]"}`}
                        >
                          {it}
                        </h4>
                      ))}
                    </div>
                    <div className="tw-mt-10 tw-grid tw-grid-cols-5 tw-gap-3">
                      {library?.map((it, i) => (
                        <div
                          key={i}
                          style={{
                            boxShadow: "0px 10px 20px 0px #0000001A",
                          }}
                          className="tw-h-80 tw-cursor-pointer tw-bg-white tw-relative"
                        >
                          <img src={eLib} alt="" className="tw-rounded-t-xl" />
                          <div className="tw-p-4">
                            <h6 className="tw-text-sm tw-font-bold manrope tw-text-black">
                              {it?.title}
                            </h6>
                            <small className="tw-text-md tw-font-light tw-text-black manrope">
                              {it?.author}
                            </small>
                            <div className="tw-mt-3">
                              <div className="tw-flex tw-justify-between tw-items-end">
                                <p className="tw-text-xs tw-w-32 tw-font-extralight tw-text-black manrope">
                                  {it?.desc}
                                </p>
                                <p
                                  onClick={() => navigate("/book")}
                                  className="tw-text-[10px] tw-font-bold manrope tw-uppercase  tw-text-[#2242B4]"
                                >
                                  {it?.mode}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ELibrary;
