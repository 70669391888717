// import React, { useState } from "react";
// import Header from "../components/header";
// import Navbar from "../components/Navbar";
// import PageText from "../components/texts/Pagetexts";
// import InputScoreTable from "../components/tables/InputScoreTable";

// const GradeInputing = () => {
//   const [showCard, setShowCard] = useState(null);
//   const [previousInputsClicked, setPreviousInputsClicked] =
//     useState("Input score");
//   const [activeItem, setActiveItem] = useState("Input score");

//   const handleCardToggle = (card) => {
//     setShowCard(showCard === card ? null : card);
//     setActiveItem(showCard === card ? null : card);
//   };

//   const handlePreviousInputsClick = () => {
//     setPreviousInputsClicked(true);
//     handleCardToggle("previousInputs");
//   };

//   return (
//     <div className="tw-bg-[#F9F9F9]">
//       <Header text="GRADE INPUTTING" />
//       <Navbar />
//       <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
//         <div>
//           <div className="tw-flex tw-items-center tw-gap-5">
//             <p
//               className=" tw-cursor-pointer tw-font-Kum tw-text-lg tw-font-semibold"
//               onClick={() => handleCardToggle("inputScore")}
//               style={{
//                 color: activeItem === "inputScore" ? "#2242B4" : "black",
//               }}
//             >
//               {previousInputsClicked ? "New score" : "Input score"}
//             </p>
//             <p
//               className=" tw-cursor-pointer tw-font-Kum tw-text-lg tw-font-semibold"
//               onClick={handlePreviousInputsClick}
//               style={{
//                 color: activeItem === "previousInputs" ? "#2242B4" : "black",
//               }}
//             >
//               Previous Inputs
//             </p>
//           </div>

//           {showCard === "inputScore" && <InputScoreTable />}
//           {showCard === "previousInputs" && (
//             <div className="card">
//               <p>This is the Previous Inputs card</p>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default GradeInputing;

import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import InputScoreTable from "../components/tables/InputScoreTable";
import PreviousInputsTable from "../components/tables/PreviousInputsTable";

const GradeInputing = () => {
  const [showCard, setShowCard] = useState("inputScore");
  const [previousInputsClicked, setPreviousInputsClicked] = useState(false);
  const [activeItem, setActiveItem] = useState("inputScore");

  const handleCardToggle = (card) => {
    setShowCard(card);
    setActiveItem(card);
  };

  const handlePreviousInputsClick = () => {
    setPreviousInputsClicked(true);
    handleCardToggle("previousInputs");
  };

  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="GRADE INPUTTING" />
      <Navbar />
      <div className="tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <div className="tw-flex tw-items-center tw-gap-5">
            <p
              className="tw-cursor-pointer tw-font-Kum tw-text-lg tw-font-semibold"
              onClick={() => handleCardToggle("inputScore")}
              style={{
                color: activeItem === "inputScore" ? "#2242B4" : "black",
              }}
            >
              {previousInputsClicked ? "New score" : "Input score"}
            </p>
            <p
              className="tw-cursor-pointer tw-font-Kum tw-text-lg tw-font-semibold"
              onClick={handlePreviousInputsClick}
              style={{
                color: activeItem === "previousInputs" ? "#2242B4" : "black",
              }}
            >
              Previous Inputs
            </p>
          </div>

          {showCard === "inputScore" && <InputScoreTable />}
          {showCard === "previousInputs" && <PreviousInputsTable />}
        </div>
      </div>
    </div>
  );
};

export default GradeInputing;
