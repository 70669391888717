import React from "react";
import Delete from "../../assets/delete-tGm5hOovrY.png";
import CheckAnimation from "./students-card/CheckAnimation";

const ActionDone = ({ onClick, text }) => {
  return (
    <div
      onClick={onClick}
      className="tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-black tw-bg-opacity-45 tw-grid tw-justify-center tw-items-center"
    >
      <div className=" tw-bg-white tw-w-[460px] tw-h-auto tw-py-5">
        <div>
          <div className=" tw-text-center tw-text-2xl tw-font-Kum tw-text-[#474747] tw-font-semibold">
            {text}
          </div>
          <p className=" Manrope tw-font-light tw-text-center tw-text-xl tw-text-[#474747]">
            successfully!
          </p>
        </div>
        <div className=" tw-grid tw-justify-center tw-items-center">
          <div className=" tw-w-20 tw-h-20">
            {" "}
            <CheckAnimation />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActionDone;
