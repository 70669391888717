import React from "react";

const PageText = ({ text }) => {
  return (
    <div>
      <h2 className="tw-text-xl kum tw-capitalize tw-font-medium tw-text-black">
        {text}
      </h2>
    </div>
  );
};

export default PageText;
