import React from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  Ticks,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarChart = () => {
  const data = {
    labels: ["", "", "", "", "", "", "", "", "", "", "", ""],
    datasets: [
      {
        label: "Boys",
        data: [400, 300, 450, 500, 600, 350, 300, 400, 450, 500, 600, 550],
        backgroundColor: "#30d6ef",
        borderRadius: { topLeft: 15, topRight: 15 },
      },
      {
        label: "Girls",
        data: [350, 400, 500, 600, 700, 400, 350, 300, 400, 450, 500, 600],
        backgroundColor: "#ce30ef",
        borderRadius: { topLeft: 15, topRight: 15 },
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: "Bar Chart",
      },
    },
    scales: {
      y: {
        beginAtZero: true,

        max: 800,
      },
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },

        max: 800,
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Bar data={data} options={options} />
    </div>
  );
};

export default BarChart;
