import React, { useState } from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import { BranchSelectInput, SearchInput } from "../components/inputs";
import AllStudentsTable from "../components/tables/AllStudentsTable";
import Buttons from "../components/buttons/buttons";
import classImage from "../assets/Group237566.png";
import ExamsTable from "../components/tables/ExamsTable";
import NewQuiz from "../components/cards/exams-card/NewQuiz";

const Learning = () => {
  const [isExams, setIsExams] = useState(false);
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="EXAMS" />
      <Navbar />
      <div className="tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div className="tw-flex tw-justify-between tw-items-center">
          <div className="tw-flex tw-items-center tw-gap-5 tw-pb-2">
            <p className="tw-mt-3 tw-font-Kum tw-text-base tw-font-semibold">
              Past Exams
            </p>
            <SearchInput
              placeholder="Search by subject..."
              onChange={(e) => console.log(e.target.value)}
            />
            <SearchInput
              placeholder="Search by name..."
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
          <div>
            <Buttons
              onClick={() => setIsExams(!isExams)}
              text="Set exam"
              color="tw-text-[#fff]"
              bg="tw-bg-[#2242B4]"
            />
            {isExams && <NewQuiz onClick={() => setIsExams(false)} />}
          </div>
        </div>
        <ExamsTable action="View student" />
      </div>
    </div>
  );
};

export default Learning;
