import React from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import PageText from "../components/texts/Pagetexts";
import Buttons from "../components/buttons/buttons";
import { BranchSelectInput, SearchInput } from "../components/inputs";
import TimetableTable from "../components/tables/TimetableTable";
import { useNavigate } from "react-router-dom";

const Timetable = () => {
  const navigate = useNavigate();
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="TIMETABLE" />
      <Navbar />
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div className=" tw-flex tw-items-center tw-justify-between">
          <div className=" tw-flex tw-items-center tw-gap-5 tw-pb-2">
            <PageText text="Timetable" />
            <SearchInput
              placeholder="Search by name..."
              onChange={(e) => console.log(e.target.value)}
            />
            <BranchSelectInput
              placeholder="Select by branch"
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
          <Buttons
            onClick={() => navigate("/new-timetable")}
            bg={"tw-bg-[#2242B4]"}
            width={"tw-w-[170px]"}
            text="Create timetable"
            color={"tw-text-[#fff]"}
          />
        </div>

        <div>
          <TimetableTable action="View timetable" />
        </div>
      </div>
    </div>
  );
};

export default Timetable;
