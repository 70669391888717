import React, { useState } from "react";
import processing from "../../../assets/processing.png";
import Buttons from "../../buttons/buttons";
import ActionDone from "../ActionDone";
import { useNavigate } from "react-router-dom";

const PromoteStudents = ({ onClick, text, onRoute }) => {
  const navigate = useNavigate();
  const [isActionDone, setIsActionDone] = useState(false);
  return (
    <div className="tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-black tw-bg-opacity-45 tw-grid tw-justify-center tw-items-center">
      <div className=" tw-bg-white tw-w-[460px] tw-h-auto tw-py-5">
        <div className=" tw-grid tw-justify-center tw-items-center">
          <p className=" tw-w-96 tw-text-center tw-font-semibold tw-font-Kum tw-text-2xl tw-text-[#474747] ">
            {text}
          </p>
        </div>
        <div className=" tw-justify-center tw-grid">
          <small className=" Manrope tw-text-base tw-text-[#474747] tw-font-light">
            This action can not be reversed
          </small>
        </div>
        <div className=" tw-grid tw-justify-center ">
          <img className=" tw-w-56 tw-h-40" src={processing} alt="" />
        </div>

        <div className=" tw-flex tw-items-center tw-justify-center tw-gap-5">
          <Buttons
            onClick={onRoute}
            text="Cancel"
            color={"tw-text-[#2242B4]"}
            css={"tw-border tw-border-[#2242B4]"}
          />
          <Buttons
            onClick={() => setIsActionDone(!isActionDone)}
            text="Continue"
            color={"tw-text-[#fff]"}
            bg={"tw-bg-[#2242B4]"}
          />
          {isActionDone && (
            <ActionDone
              text="Action Done"
              onClick={() => setIsActionDone(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default PromoteStudents;
