import React, { useEffect, useState } from "react";
import Header from "../components/header";
import StudentNavbar from "../components/StudentNavbar";
import materialsymbols from "../assets/materialsymbols.png";
import duotone from "../assets/duotone.png";
import fluent from "../assets/fluent.png";
import book from "../assets/book.png";
import Buttons from "../components/buttons/buttons";
import ActionCompleted from "../components/cards/students-card/ActionCompleted";
import { useNavigate } from "react-router-dom";

const AssignmentQuestion = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();
  const [isSubmit, setIsSubmit] = useState(false);
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="ASSIGNMENTS" />
      <StudentNavbar />
      <div className=" tw-bg-white tw-mt-5  lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div className=" tw-pr-96">
          <div className="">
            <div className=" tw-grid">
              <strong className="Satoshi">Question 1</strong>
              <small className="Satoshi tw-text-[13px]">
                Which elements can you not use for branding?
              </small>
            </div>
            <div className=" tw-grid tw-mt-3">
              <strong className="Satoshi">Answer:</strong>
              <textarea
                className=" tw-p-2 tw-outline-none tw-border tw-w-full tw-h-44 tw-rounded-lg"
                name=""
                id=""
              ></textarea>

              <div className=" tw-flex tw-justify-between tw-items-center">
                <div className=" tw-flex tw-items-center tw-gap-5">
                  <div className=" tw-cursor-pointer tw-flex tw-items-center tw-gap-2">
                    <small className="Satoshi">Attach Image</small>
                    <img
                      className=" tw-w-4 tw-h-4"
                      src={materialsymbols}
                      alt=""
                    />
                  </div>
                  <div className=" tw-cursor-pointer tw-flex tw-items-center tw-gap-2 tw-mt-1">
                    <small className="Satoshi">Upload Document</small>
                    <img className=" tw-w-3 tw-h-3" src={duotone} alt="" />
                  </div>
                </div>
                <div>
                  <img className=" tw-w-4 tw-h-4" src={fluent} alt="" />
                </div>
              </div>
            </div>
          </div>

          {/*  */}
          <div className=" tw-mt-8">
            <div className=" tw-grid">
              <strong className="Satoshi">Question 2</strong>
              <small className="Satoshi tw-text-[13px]">
                What does the image below represents?
              </small>
            </div>
            <div className=" tw-grid tw-mt-3">
              <strong className=" Satoshi">Answer:</strong>
              <div className=" tw-flex tw-gap-4 tw-items-center tw-my-2">
                <div>
                  <img className=" tw-w-20 tw-h-20" src={book} alt="" />
                </div>
                <div className=" tw-flex tw-items-center tw-gap-5">
                  <div className=" tw-flex tw-items-center tw-gap-2">
                    <strong className=" tw-text-sm tw-font-semibold Satoshi">
                      A:
                    </strong>
                    <div className=" tw-h-8 tw-px-3 tw-rounded-lg tw-border">
                      <small className=" tw-text-xs Satoshi">Book</small>
                    </div>
                  </div>
                  <div className=" tw-flex tw-items-center tw-gap-2">
                    <strong className="tw-text-sm tw-font-semibold Satoshi">
                      B:
                    </strong>
                    <div className=" tw-h-8 tw-px-3 tw-rounded-lg tw-border">
                      <small className=" tw-text-xs Satoshi">Book</small>
                    </div>
                  </div>
                  <div className=" tw-flex tw-items-center tw-gap-2">
                    <strong className="tw-text-sm tw-font-semibold Satoshi">
                      C:
                    </strong>
                    <div className=" tw-h-8 tw-px-3 tw-rounded-lg tw-border">
                      <small className=" tw-text-xs Satoshi">Book</small>
                    </div>
                  </div>
                  <div className=" tw-flex tw-items-center tw-gap-2">
                    <strong className="tw-text-sm tw-font-semibold Satoshi">
                      D:
                    </strong>
                    <div className=" tw-h-8 tw-px-3 tw-rounded-lg tw-border">
                      <small className=" tw-text-xs Satoshi">Book</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" tw-flex tw-items-center tw-justify-center">
          <Buttons
            onClick={() => setIsSubmit(!isSubmit)}
            text="Submit"
            color={"tw-text-[#fff]"}
            bg={"tw-bg-[#2242B4]"}
          />
        </div>
        {isSubmit && (
          <ActionCompleted
            onClick={() => navigate("/assessments")}
            text="Action Completed"
          />
        )}
      </div>
    </div>
  );
};

export default AssignmentQuestion;
