import React from "react";
import { FaArrowLeft } from "react-icons/fa";
import PageText from "../../texts/Pagetexts";
import StudentImgs from "../../../assets/StudentImgs.png";
import RClock from "../../../assets/RClock.png";
import RSchedule from "../../../assets/RSchedule.png";
import { useLocation, useNavigate } from "react-router-dom";
import Buttons from "../../buttons/buttons";

const ExamDetails = ({ onClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { card } = location.state || {};
  return (
    <div
      //   onClick={onClick}
      className=" tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-black tw-bg-opacity-45 tw-grid tw-justify-center tw-items-center"
    >
      <div className=" tw-bg-white tw-w-[930px] tw-overflow-y-scroll tw-p-5 tw-h-[600px] ">
        <div className=" tw-flex tw-items-center tw-gap-2">
          <FaArrowLeft
            size={24}
            onClick={onClick}
            className=" tw-cursor-pointer"
          />
          <div className=" tw-mt-1">
            <PageText text="Exam Result Details" />
          </div>
        </div>

        <div className=" tw-flex tw-items-center tw-justify-between">
          <div className=" tw-flex tw-items-center tw-gap-3">
            <img className=" tw-w-24 tw-h-24" src={StudentImgs} alt="" />
            <div className="">
              <strong className=" tw-font-semibold tw-text-lg Satoshi">
                John Smith
              </strong>
              <div>
                <span className=" tw-text-sm tw-font-medium Satoshi tw-text-[#3C3C3C]">
                  Student ID :
                </span>{" "}
                <span className=" tw-text-sm tw-font-medium Satoshi tw-text-[#3C3C3C]">
                  SMS001A
                </span>
              </div>
              <div>
                <span className=" tw-text-sm tw-font-medium Satoshi tw-text-[#3C3C3C]">
                  Gender :
                </span>{" "}
                <span className=" tw-text-sm tw-font-medium Satoshi tw-text-[#3C3C3C]">
                  Male
                </span>
              </div>
            </div>
          </div>
          <div>
            <Buttons
              onClick={() => navigate("/v-result")}
              width={"tw-w-[200px]"}
              css={"tw-border tw-border-[#2242B4]"}
              color={"tw-text-[#2242B4]"}
              text="View Repord Card"
            />
          </div>
        </div>

        <div className=" tw-mt-5 ">
          <strong className=" Inter tw-font-bold tw-text-xl tw-text-[#434343]">
            Mid-Term, End of Topic Assessment.
          </strong>

          <div className=" tw-flex tw-items-center tw-gap-5 tw-mt-2">
            <div className=" tw-flex tw-gap-1 tw-items-center">
              <img className=" tw-w-4 tw-h-4" src={RClock} alt="" />
              <small className=" tw-font-normal Satoshi tw-text-sm tw-text-[#989898]">
                12:40 P:M
              </small>
            </div>
            <div className=" tw-flex tw-gap-1 tw-items-center">
              <img className=" tw-w-4 tw-h-4" src={RSchedule} alt="" />
              <small className=" tw-font-light Satoshi tw-text-sm tw-text-[#3C3C3C]">
                31-01-2024
              </small>
            </div>

            <div>
              <div className=" tw-flex tw-justify-center tw-items-center tw-bg-[#ECFFEE] tw-w-36 tw-h-8 tw-rounded-lg">
                <small className=" tw-font-normal Inter tw-text-[#44CCA3] tw-text-base">
                  Status :{" "}
                  <span className="tw-font-normal Inter tw-text-[#44CCA3] tw-text-base">
                    Pass
                  </span>
                </small>
              </div>
            </div>
          </div>

          <div className=" tw-mt-5">
            <div className=" tw-text-base tw-text-[#434343]">
              <span className=" tw-font-light Satoshi">Subject: </span>{" "}
              <span className=" tw-font-semibold Satoshi">Mathematics</span>
            </div>
            <div className=" tw-mt-3">
              <div className=" tw-text-base tw-text-[#434343]">
                <span className=" Satoshi tw-text-sm">Total Marks : </span>{" "}
                <span className=" Satoshi tw-text-sm tw-font-bold">50</span>
              </div>
              <div className=" tw-text-base tw-text-[#434343] tw-flex tw-gap-6">
                <span className=" Satoshi tw-text-sm">
                  TPassing Percentage:{" "}
                </span>{" "}
                <span className=" Satoshi tw-text-sm tw-text-[#3D70F5] tw-font-light">
                  70%
                </span>
              </div>
            </div>
          </div>
          <div className=" tw-mt-5">
            <div>
              <strong className=" Satoshi tw-text-base tw-text-[#434343] ">
                Question Paper
              </strong>
              <div className=" tw-flex tw-w-72  tw-border tw-rounded-lg tw-justify-center tw-items-center tw-h-8 tw-mt-2">
                <small className="Satoshi tw-text-sm tw-text-[#434343]">
                  Mathematics Mid Term Question Paper.pdf
                </small>
              </div>
            </div>
            <div className=" tw-mt-5">
              <p className=" tw-text-[#434343] Satoshi tw-text-xl tw-font-bold ">
                Result
              </p>
              <strong className=" Satoshi tw-text-base tw-text-[#434343] ">
                Question Paper
              </strong>
              <div className=" tw-flex tw-w-72  tw-border tw-rounded-lg tw-justify-center tw-items-center tw-h-8 tw-mt-2">
                <small className="Satoshi tw-text-sm tw-text-[#434343]">
                  Mathematics Mid Term Question Paper.pdf
                </small>
              </div>
            </div>
          </div>

          <div className=" tw-mt-5">
            <small className=" tw-font-normal Satoshi tw-text-[#434343] tw-text-sm">
              Student’s Score
            </small>
            <div className=" tw-border tw-rounded-lg tw-w-20 tw-h-8 tw-justify-center tw-grid tw-items-center">
              <p className=" tw-text-[#474747] tw-text-sm">39/50</p>
            </div>
          </div>

          <div>
            <div className=" tw-text-base tw-text-[#434343] tw-flex tw-gap-6 tw-mt-5">
              <span className=" Satoshi tw-text-sm">Student’s Percentage </span>{" "}
              <span className=" Satoshi tw-text-sm tw-text-[#44CCA3] tw-font-light">
                75%
              </span>
            </div>
          </div>

          <div className=" tw-grid tw-mt-5 tw-gap-3">
            <small className=" tw-text-[#434343] tw-font-semibold tw-text-base">
              Teacher’s Comments
            </small>
            <small className=" tw-text-[#434343] tw-text-[13px]">
              Lorem ipsum dolor sit amet consectetur adipiscing elit Ut et massa
              mi. Aliquam in hendrerit urna. Pellentesque sit amet sapien
              fringilla, mattis ligula consectetur, ultrices mauris. Maecenas
              vitae mattis tellus. Nullam quis imperdiet augue. Vestibulum
              auctor ornare leo, non suscipit magna interdum eu. Curabitur
              pellentesque nibh nibh, at maximus ante fermentum sit amet.
              Pellentesque commodo lacus at sodales sodales.{" "}
            </small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamDetails;
