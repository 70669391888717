import React, { useContext, useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import useAuthStore from "./data/Stores/AuthStore";
import HomeParent from "./parents/home";
import HomeTeacher from "./teachers/home";
import HomeStudent from "./students/home";
import Login from "./screens/login";
import PageRender from "./PageRender";
import { ToastContainer } from "react-toastify";
import useErrorStore from "./data/Stores/ErrrorStore";
import { GlobalState } from "./data/Context";
import axios from "axios";
import ModalContainer from "./components/modalcontainer";
import { PrimaryBtn } from "./components/buttons/buttons";

const Routers = () => {
	const { isAuth, userPage } = useAuthStore(),
		{ error, clearErrors } = useErrorStore(),
		{ setSubdomain } = useContext(GlobalState);
	const location = useLocation();

	useEffect(() => {
		window.scrollTo(0, 0);
	}, [location.pathname]);

	useEffect(() => {
		// Get the hostname from the window location
		const hostname = window.location.hostname;

		if (!hostname?.includes(".vercel.app")) {
			// Split the hostname by '.' to get subdomain
			const parts = hostname.split(".");

			// Check if there's a subdomain (more than 2 parts in the hostname)
			if (parts.length > (hostname?.includes("localhost") ? 1 : 2)) {
				// Subdomain is the first part of the hostname
				const subdomain = parts[0];
				console.log({ subdomain });

				let retrieveSubdomain = async () => {
					try {
						let res,
							body = {
								schoolSubdomain: subdomain,
							};

						res = await axios.post(`api/v1/school/workspace-subdomain`, {
							...body,
						});
						console.log({ resp: res?.data });
						setSubdomain(res?.data?.data);
						axios.defaults.headers.common["school-source"] =
							res?.data?.data?._id;
						let state = res?.data?.data;
						if (state?.schoolImage?.url) {
							let link = document.querySelector("link[rel~='icon']");
							let link2 = document.querySelector(
								"link[rel~='apple-touch-icon']"
							);
							// let title = document.querySelector("title");
							if (!link) {
								link = document.createElement("link");
								link.rel = "icon";
								document.getElementsByTagName("head")[0].appendChild(link);
							}
							if (!link2) {
								link2 = document.createElement("link");
								link2.rel = "apple-touch-icon";
								document.getElementsByTagName("head")[0].appendChild(link2);
							}
							link.href = state?.schoolImage?.url;
							link2.href = state?.schoolImage?.url;
						}
						if (res?.data?.data?.schoolName)
							document.title = res?.data?.data?.schoolName;
					} catch (err) {
						delete axios.defaults.headers.common["school-source"];
						let newUrl = window.location.href;
						newUrl = newUrl?.replace(`${subdomain}.`, "");
						window.location.replace(newUrl);
						setSubdomain("");
						if (err) console.log({ error: err.response?.data, err });
						let error = err.response?.data?.error;
						console.log({ error }, "subdomain");
					}
				};
				if (subdomain && !["www"]?.includes(subdomain)) retrieveSubdomain();
				// setSubdomain(subdomain);
				console.log({ location, hostname: window.location });
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location]);

	return (
		<>
			<ToastContainer />
			<Routes>
				<Route
					path="/"
					element={
						isAuth && userPage === "parent" ? (
							<HomeParent />
						) : isAuth && userPage === "teacher" ? (
							<HomeTeacher />
						) : isAuth && userPage === "student" ? (
							<HomeStudent />
						) : (
							<Login />
						)
					}
				/>
				<Route path="/:page" element={<PageRender />} />
				<Route path="/:page/:id" element={<PageRender />} />
				<Route path="/:page/:id/:step" element={<PageRender />} />
			</Routes>
			{error?.length > 0 && (
				<ModalContainer handleClose={() => clearErrors()}>
					<div className="tw-p-5">
						<h2 className="tw-font-bold satoshi tw-text-red-800 tw-text-2xl">
							Error
						</h2>
						<div className="downH2 tw-flex tw-flex-col">
							{error?.map((item, i) => (
								<p key={i} className="tw-fw-bold Lexend tw-w-100">
									<span className="fontInherit tw-me-2">
										{error?.length !== 1 && <>{i + 1}.</>}
									</span>{" "}
									{item?.message || item || null}{" "}
								</p>
							))}
							<div className=" tw-mt-4 tw-flex tw-justify-center tw-py-3">
								<PrimaryBtn
									onClick={clearErrors}
									text={"Close"}
									css={
										"tw-bg-yellow tw-h-10 tw-w-32 tw-rounded-xl tw-text-base tw-font-bold tw-text-black satoshi"
									}
								/>
							</div>
						</div>
					</div>
				</ModalContainer>
			)}
		</>
	);
};

export default Routers;
