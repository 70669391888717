import React from "react";
import { BranchSelectInput } from "../../inputs";
import Buttons from "../../buttons/buttons";
import { useNavigate } from "react-router-dom";

const NewQuiz = ({ onClick }) => {
  const navigate = useNavigate();
  return (
    <div
      onClick={onClick}
      className=" tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-black tw-bg-opacity-45 tw-grid tw-justify-center tw-items-center"
    >
      <div className=" tw-bg-white tw-w-[430px] tw-h-auto tw-py-10 ">
        <div className=" tw-grid tw-justify-center tw-items-center tw-space-y-8">
          <div className=" tw-grid tw-justify-center tw-space-y-2">
            <small className=" tw-text-center  tw-text-sm Manrope tw-text-[#000] tw-font-semibold">
              Subject
            </small>
            <div className=" tw-grid tw-justify-center">
              <BranchSelectInput
                placeholder="Please Select Subject"
                onChange={(e) => console.log(e.target.value)}
                borderColor={"#2242B4"}
                width={"216px"}
                height={"49px"}
              />
            </div>
          </div>
          <div className=" tw-grid tw-justify-center tw-space-y-2">
            <small className=" tw-text-center  tw-text-sm Manrope tw-text-[#000] tw-font-semibold">
              Class
            </small>
            <div className=" tw-grid tw-justify-center">
              <BranchSelectInput
                placeholder="Please Select Subject"
                onChange={(e) => console.log(e.target.value)}
                borderColor={"#2242B4"}
                width={"216px"}
                height={"49px"}
              />
            </div>
          </div>

          <div className=" tw-pt-20 tw-flex tw-items-center tw-justify-center">
            <Buttons
              onClick={() => navigate("/questions")}
              text="Set quiz"
              bg={"tw-bg-[#2242B4]"}
              color={" tw-text-[#fff]"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewQuiz;
