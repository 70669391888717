import React from "react";
import { motion } from "framer-motion";

const Dot = ({ size, color, angle, distance, delay }) => {
  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: [0, 1, 0] }}
      transition={{ repeat: Infinity, duration: 1.5, delay }}
      style={{
        width: size,
        height: size,
        borderRadius: "50%",
        background: color,
        position: "absolute",
        transform: `rotate(${angle}deg) translate(${distance}px)`,
        transformOrigin: "center",
      }}
    />
  );
};

export default Dot;
