import React from "react";
import { motion } from "framer-motion";
import Dot from "./Dot";

const dots = [
  { size: 6, color: "#30efc3", angle: 0, distance: 50, delay: 0 },
  { size: 8, color: "#30ef7b", angle: 72, distance: 50, delay: 0.3 },
  { size: 5, color: "#30efc3", angle: 144, distance: 50, delay: 0.6 },
  { size: 7, color: "#30ef7b", angle: 216, distance: 50, delay: 0.9 },
  { size: 6, color: "#30efc3", angle: 288, distance: 50, delay: 1.2 },
];

const CheckAnimation = () => {
  return (
    <motion.div
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{ duration: 0.5 }}
      style={{
        width: "100%",
        height: "100%",
        borderRadius: "50%",
        background: "linear-gradient(145deg, #30ef7b, #30efc3)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      {dots.map((dot, index) => (
        <Dot key={index} {...dot} />
      ))}
      <motion.svg
        width="50"
        height="50"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <motion.path
          d="M5 13l4 4L19 7"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          initial={{ pathLength: 0 }}
          animate={{ pathLength: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
        />
      </motion.svg>
    </motion.div>
  );
};

export default CheckAnimation;

// import React from "react";
// import { motion } from "framer-motion";

// const CheckAnimation = () => {
//   return (
//     <motion.div
//       initial={{ scale: 0 }}
//       animate={{ scale: 1 }}
//       transition={{ duration: 0.5 }}
//       style={{
//         width: "100%",
//         height: "100%",
//         borderRadius: "50%",
//         background: "linear-gradient(145deg, #30ef7b, #30efc3)",
//         display: "flex",
//         justifyContent: "center",
//         alignItems: "center",
//         boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
//       }}
//     >
//       <motion.svg
//         initial={{ pathLength: 0 }}
//         animate={{ pathLength: 1 }}
//         transition={{ duration: 0.5, delay: 0.5 }}
//         width="50"
//         height="50"
//         viewBox="0 0 24 24"
//         fill="none"
//         xmlns="http://www.w3.org/2000/svg"
//       >
//         <motion.path
//           d="M5 13l4 4L19 7"
//           stroke="white"
//           strokeWidth="2"
//           strokeLinecap="round"
//           strokeLinejoin="round"
//         />
//       </motion.svg>
//     </motion.div>
//   );
// };

// export default CheckAnimation;
