// import React from "react";

// const LiveSession = () => {
//   return (
//     <div>
//       <p></p>
//     </div>
//   );
// };

// export default LiveSession;

import React from "react";
import imone from "../assets/imone.png";
import imtwo from "../assets/imtwo.png";
import imthree from "../assets/imthree.png";
import imfour from "../assets/imfour.png";
import imfive from "../assets/imfive.png";
import imsis from "../assets/imsis.png";
import imseven from "../assets/imseven.png";
import imeight from "../assets/imeight.png";
import imnine from "../assets/imnine.png";
import PageText from "../components/texts/Pagetexts";
import Buttons from "../components/buttons/buttons";
import Header from "../components/header";
import StudentNavbar from "../components/StudentNavbar";

const LiveSession = () => {
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="E-LIBRARY" />
      <StudentNavbar />
      <div className=" tw-bg-white tw-mt-5  lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <section className="tw-mx-6">
          <PageText text={"Mathematics Class by Miss Ann"} />
          <div className="tw-flex tw-gap-10 ">
            <div className="tw-w-[80%] tw-h-[90vh] tw-relative tw-p-6 tw-rounded-xl">
              <div className=" tw-bg-primary tw-p-4 tw-text-white tw-flex tw-justify-between tw-items-center">
                <div className=" tw-grid">
                  <strong>
                    The fundamentals of rationalization and Complex surd
                  </strong>
                  <small>Ann Adeoluwa</small>
                </div>
                <div>
                  <Buttons
                    width={"tw-w-[75px]"}
                    css={"tw-h-[32px]"}
                    text="00:50:10"
                    bg={"tw-bg-[#FFC000]"}
                  />
                </div>
              </div>
              <div className=" tw-h-full tw-grid tw-items-end tw-bg-red-200 tw-pb-5 tw-px-5">
                <div className=" tw-justify-end tw-items-end tw-grid ">
                  <div className=" tw-flex tw-items-end tw-gap-20">
                    <div className=" tw-w-[338px] tw-h-16 tw-rounded-xl tw-gap-8 tw-bg-white tw-flex tw-justify-center tw-items-center">
                      <img
                        className=" tw-w-6 tw-h-6 tw-cursor-pointer"
                        src={imone}
                        alt=""
                      />
                      <img
                        className=" tw-w-6 tw-h-6 tw-cursor-pointer"
                        src={imtwo}
                        alt=""
                      />
                      <img
                        className=" tw-w-6 tw-h-6 tw-cursor-pointer"
                        src={imthree}
                        alt=""
                      />
                      <img
                        className=" tw-w-6 tw-h-6 tw-cursor-pointer"
                        src={imfour}
                        alt=""
                      />
                    </div>
                    <div className=" tw-space-y-5">
                      <div>
                        <img
                          className=" tw-w-36 tw-h-28 tw-cursor-pointer"
                          src={imfive}
                          alt=""
                        />
                      </div>
                      <div>
                        <img
                          className=" tw-w-36 tw-h-28 tw-cursor-pointer"
                          src={imsis}
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className=" tw-relative tw-w-[20%] tw-bg-[#FBFFFF] tw-mt-5">
              <div className=" ">
                <div className=" tw-mt-5 tw-flex tw-justify-between tw-items-center">
                  <small className=" tw-font-semibold tw-text-base tw-text-[#000]">
                    Live Chat
                  </small>
                  <strong className=" tw-cursor-pointer">&times;</strong>
                </div>
                <div className=" tw-mt-5 ">
                  <div className=" ">
                    <div className=" tw-flex tw-gap-3">
                      <img className=" tw-w-7 tw-h-7" src={imseven} alt="" />
                      <div>
                        <p className=" tw-font-normal tw-text-base tw-text-[#000]">
                          BOB
                        </p>
                        <div className=" tw-border tw-border-[#CCCCCC] tw-py-2 tw-items-center tw-grid tw-justify-center  tw-w-auto  tw-rounded-xl tw-px-3 ">
                          <small className=" tw-text-[#868585] tw-font-Kum tw-text-[13px]">
                            Hello everyone!
                          </small>
                        </div>
                        <div>
                          <p className=" tw-font-Kum tw-text-[13px] tw-text-[#B2B1B1]">
                            2 min ago
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className=" tw-float-right">
                    <div>
                      <div className=" tw-flex tw-justify-end">
                        <small className=" tw-font-normal tw-text-sm tw-font-Kum tw-text-[#000]">
                          You
                        </small>
                      </div>
                      <div className=" tw-bg-[#FFC000] tw-text-white tw-py-2 tw-items-center tw-grid tw-justify-center  tw-w-auto  tw-rounded-xl tw-px-3 ">
                        <small className=" tw-text-[#ff] tw-font-Kum tw-text-[13px]">
                          Lorem ipsum dolor sit amet
                        </small>
                      </div>
                      <div className="tw-flex tw-justify-end">
                        <p className=" tw-font-Kum tw-text-[13px] tw-text-[#B2B1B1]">
                          2 min ago
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className=" tw-grid tw-absolute tw-bottom-2 tw-left-0 tw-right-0">
                <div className=" tw-w-full tw-h-12 tw-rounded-full tw-px-2 tw-bg-[#F6F7FA] tw-flex tw-justify-center tw-items-center">
                  <img
                    className=" tw-cursor-pointer tw-w-5 tw-h-5"
                    src={imeight}
                    alt=""
                  />
                  <input
                    placeholder="Write message"
                    className=" tw-w-36 placeholder:tw-text-xs placeholder:tw-text-[#AAA6A6] tw-bg-[#F6F7FA] tw-font-Kum tw-text-sm tw-px-2 tw-border-none tw-outline-none"
                    type="text"
                    name=""
                    id=""
                  />
                  <img
                    className=" tw-cursor-pointer tw-w-4 tw-h-4"
                    src={imnine}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default LiveSession;
