// import React from "react";

// const EditScoreInput = () => {
//   return (
//     <div>
//       <p></p>
//     </div>
//   );
// };

// export default EditScoreInput;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import EditVector from "../assets/EditVector.png";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import PageText from "../components/texts/Pagetexts";
import Buttons from "../components/buttons/buttons";
import ActionDone from "../components/cards/ActionDone";

const EditScoreInput = () => {
  const [isUpgrade, setIsUpgrade] = useState(false);
  const navigate = useNavigate();
  const initialData = [
    {
      name: "JOHN Smith O.",
      firstCA: "",
      secondCA: "",
      exams: "",
      total: "",
      grade: "",
    },
    {
      name: "JOHN Smith O.",
      firstCA: "",
      secondCA: "",
      exams: "",
      total: "",
      grade: "",
    },
    {
      name: "JOHN Smith O.",
      firstCA: "",
      secondCA: "",
      exams: "",
      total: "",
      grade: "",
    },
    {
      name: "JOHN Smith O.",
      firstCA: "",
      secondCA: "",
      exams: "",
      total: "",
      grade: "",
    },
    {
      name: "JOHN Smith O.",
      firstCA: "",
      secondCA: "",
      exams: "",
      total: "",
      grade: "",
    },
    {
      name: "JOHN Smith O.",
      firstCA: "",
      secondCA: "",
      exams: "",
      total: "",
      grade: "",
    },
    {
      name: "JOHN Smith O.",
      firstCA: "",
      secondCA: "",
      exams: "",
      total: "",
      grade: "",
    },
  ];

  const [data, setData] = useState(initialData);

  const handleInputChange = (index, field, value) => {
    const updatedData = [...data];
    const numericValue = value === "" ? "" : Number(value);

    if (
      field === "firstCA" &&
      (numericValue === "" || (numericValue >= 0 && numericValue <= 20))
    ) {
      updatedData[index][field] = numericValue;
    } else if (
      field === "secondCA" &&
      (numericValue === "" || (numericValue >= 0 && numericValue <= 20))
    ) {
      updatedData[index][field] = numericValue;
    } else if (
      field === "exams" &&
      (numericValue === "" || (numericValue >= 0 && numericValue <= 60))
    ) {
      updatedData[index][field] = numericValue;
    }

    updatedData[index].total =
      (Number(updatedData[index].firstCA) || 0) +
      (Number(updatedData[index].secondCA) || 0) +
      (Number(updatedData[index].exams) || 0);

    const total = updatedData[index].total;
    if (total >= 70) updatedData[index].grade = "A";
    else if (total >= 60) updatedData[index].grade = "B";
    else if (total >= 50) updatedData[index].grade = "C";
    else if (total >= 40) updatedData[index].grade = "D";
    else if (total >= 30) updatedData[index].grade = "E";
    else updatedData[index].grade = "F";

    setData(updatedData);
  };

  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="GRADE CALCULATIONS" />
      <Navbar />
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <div className=" tw-mt-5">
            <div className="tw-w-full">
              <table className="tw-w-full tw-border">
                <thead>
                  <tr className="tw-bg-[#F0F6FF] tw-border tw-border-[#F0F6FF] ">
                    <th className="tw-border tw-px-4 tw-py-2">STUDENTS</th>
                    <th className="tw-border tw-px-4">1st CA (20)</th>
                    <th className="tw-border tw-px-4">2nd CA (20)</th>
                    <th className="tw-border tw-px-4">EXAMS (60)</th>
                    <th className="tw-border tw-px-4">TOTAL (100)</th>
                    <th className="tw-border tw-px-4">GRADE</th>
                  </tr>
                </thead>
                <tbody>
                  {data.map((student, index) => (
                    <tr key={index}>
                      <td className="tw-border tw-px-4 Manrope tw-text-sm tw-font-light tw-text-[#000]">
                        {student.name}
                      </td>
                      <td className="tw-border tw-px-4 tw-py-1">
                        <input
                          type="number"
                          value={student.firstCA}
                          onChange={(e) =>
                            handleInputChange(index, "firstCA", e.target.value)
                          }
                          className="tw-w-full tw-outline-none placeholder:tw-text-xs placeholder:tw-text-[#000] tw-text-xs"
                          max="20"
                          min="0"
                          placeholder="Enter scores"
                        />
                      </td>
                      <td className="tw-border tw-px-4">
                        <input
                          type="number"
                          value={student.secondCA}
                          onChange={(e) =>
                            handleInputChange(index, "secondCA", e.target.value)
                          }
                          className="tw-w-full tw-outline-none placeholder:tw-text-xs placeholder:tw-text-[#000] tw-text-xs"
                          max="20"
                          min="0"
                          placeholder="Enter scores"
                        />
                      </td>
                      <td className="tw-border tw-px-4">
                        <input
                          type="number"
                          value={student.exams}
                          onChange={(e) =>
                            handleInputChange(index, "exams", e.target.value)
                          }
                          className="tw-w-full tw-outline-none placeholder:tw-text-xs placeholder:tw-text-[#000] tw-text-xs"
                          max="60"
                          min="0"
                          placeholder="Enter scores"
                        />
                      </td>
                      <td className="tw-border tw-px-4 tw-text-xs">
                        {student.total || ""}
                      </td>
                      <td className="tw-border tw-px-4 tw-text-xs">
                        {student.grade || ""}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className=" tw-flex tw-items-center tw-justify-center tw-mt-32 tw-gap-5">
          <Buttons
            onClick={() => navigate("/grade-inputing")}
            css={"tw-border-[#2242B4] tw-border"}
            color={"tw-text-[#2242B4]"}
            text="Cancel"
          />
          <Buttons
            onClick={() => setIsUpgrade(!isUpgrade)}
            color={"tw-text-[#fff] "}
            bg={"tw-bg-[#2242B4]"}
            text="Upgrade"
          />
          {isUpgrade && (
            <ActionDone
              text="Action Done"
              onClick={() => setIsUpgrade(false)}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditScoreInput;
