import { useState } from "react";
import useErrorStore from "../data/Stores/ErrrorStore";
import { apiCall } from "../data/useGenFetcher";
import { DefaultBox } from "./login";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import PrimaryBtn from "../components/buttons/buttons";
import OtpInput from "react18-otp-input";

const Activate = () => {
	const navigate = useNavigate(),
		[state, setState] = useState({}),
		[loading, setLoading] = useState(null),
		{ returnErrors } = useErrorStore();
	let handleSubmit = async e => {
		e?.preventDefault();
		let errArr2 = [];
		if (!state?.otp) errArr2?.push("OTP is required");
		if (errArr2?.length > 0) return errArr2?.forEach(it => toast.info(it));
		setLoading(true);
		let newState = { ...state };

		let { response, errArr, errMsg } = await apiCall({
			type: "post",
			url: "/api/v1/user/otp",
			data: newState,
		});
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			navigate("/login");
			return;
		}
		setLoading(false);
	};

	return (
		<>
			<DefaultBox text={"Enter the TOKEN recieved"}>
				<form
					className="lg:tw-space-y-6 md:tw-space-y-6 tw-space-y-6 tw-bg-white lg:tw-w-[667px] md:tw-w-[467px] tw-w-[350px] tw-rounded-md lg:tw-py-10 md:tw-py-16 tw-py-10 lg:tw-px-32 md:tw-px-10 tw-px-5"
					onSubmit={handleSubmit}>
					<div className=" tw-space-y-1">
						<small className=" tw-text-[#383838] tw-font-Nunito tw-text-sm">
							Welcome!!
						</small>
						<p className=" tw-text-2xl tw-font-Nunito tw-text-[#272525] tw-font-bold">
							Enter the TOKEN recieved
						</p>
					</div>
					<div>
						<OtpInput
							numInputs={6}
							shouldAutoFocus={true}
							isInputNum={true}
							renderSeparator={<span> - </span>}
							renderInput={props => <input {...props} />}
							inputStyle={"tw-h-12 tw-border tw-text-xl  w-100"}
							containerStyle={{ width: "300px" }}
							value={state?.otp}
							onChange={otp => setState({ ...state, otp })}
							isInputNumber={true}
						/>
					</div>
					<div className="tw-mt-8">
						<PrimaryBtn
							text={"Submit"}
							css={`tw-mt-3 tw-bg-[#FFC000] tw-w-60 tw-h-10 tw-text-white tw-border tw-border-[#A7A7A7] Kum tw-text-sm`}
							bg="#FFC000"
							loading={loading}
							onClick={handleSubmit}
						/>
					</div>
				</form>
			</DefaultBox>
		</>
	);
};

export default Activate;
