import React from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";

const LessonGuides = () => {
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="MY STUDENTS" />
      <Navbar />
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <ul className=" tw-flex tw-items-center tw-gap-8">
          <li className="Inter tw-text-base tw-cursor-pointer ">
            Lesson Guides
          </li>
          <li className="Inter tw-text-base tw-cursor-pointer ">
            Lesson Notes
          </li>
          <li className="Inter tw-text-base tw-cursor-pointer ">Class Notes</li>
        </ul>
      </div>
    </div>
  );
};

export default LessonGuides;

// import React, { useState } from "react";

// const LessonGuides = () => {
//   const [selectedTab, setSelectedTab] = useState("Quiz");
//   const tabs = ["General", "Quiz", "Exams", "Assignment", "Classes"];
//   const cards = {
//     General: <div className="card">General Card Content</div>,
//     Quiz: (
//       <div className="">
//         <LessonGuides />
//       </div>
//     ),
//     Exams: <div className="card">Exams Card Content</div>,
//     Assignment: <div className="card">Assignment Card Content</div>,
//     Classes: <div className="card">Classes Card Content</div>,
//   };
//   return (
//     <div>
//       <div>
//         <ul className="tw-flex tw-items-center tw-gap-10">
//           {tabs.map((tab) => (
//             <li
//               key={tab}
//               className={`Inter tw-text-base tw-cursor-pointer ${
//                 selectedTab === tab
//                   ? "tw-text-[#3D70F5] tw-underline"
//                   : "tw-text-[#707070]"
//               }`}
//               onClick={() => setSelectedTab(tab)}
//             >
//               {tab}
//             </li>
//           ))}
//         </ul>
//         <div className="tw-mt-4">{cards[selectedTab]}</div>
//       </div>
//     </div>
//   );
// };

// export default LessonGuides;
