import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const ManageWardsTable = ({ action }) => {
  const navigate = useNavigate();
  //   const [checkedItems, setCheckedItems] = useState({});

  const headers = ["ID", "Name", "Date joined", "Class", "Actions"];

  const data = [
    {
      id: 1,
      name: "John Smith",
      Datejoined: "31/12/2023",
      Class: "Basic 5",

      actions: "View details",
    },
    {
      id: 2,
      name: "John Smith",
      Datejoined: "31/12/2023",
      Class: "Basic 5",

      actions: "View details",
    },
    {
      id: 3,
      name: "John Smith",
      Datejoined: "31/12/2023",
      Class: "Basic 5",

      actions: "View details",
    },
  ];

  //   const handleHeaderCheckboxChange = () => {
  //     const allChecked = Object.keys(checkedItems).length === data.length;
  //     const newCheckedItems = {};

  //     if (!allChecked) {
  //       data.forEach((item) => {
  //         newCheckedItems[item.id] = true;
  //       });
  //     }

  //     setCheckedItems(newCheckedItems);
  //   };

  //   const handleCheckboxChange = (id) => {
  //     setCheckedItems((prev) => {
  //       const newCheckedItems = { ...prev };
  //       if (newCheckedItems[id]) {
  //         delete newCheckedItems[id];
  //       } else {
  //         newCheckedItems[id] = true;
  //       }
  //       return newCheckedItems;
  //     });
  //   };

  return (
    <div className="tw-mt-5">
      <div className="tw-w-full">
        <table className="tw-table tw-w-full">
          <thead
            style={{
              paddingLeft: "1.25rem !important",
            }}
            className="tw-h-14 tw-bg-[#F0F6FF] tw-w-full tw-mb-4 tw-text-md tw-capitalize tw-font-bold kum tw-text-plain"
          >
            <tr>
              {headers?.map((header, index) => (
                <th key={index} className={`${index === 0 && "tw-pl-4"}`}>
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="tw-w-full">
            {data?.map((item, index) => (
              <tr
                key={index}
                style={{
                  boxShadow: "0px 5px 10px 0px #0000000A",
                }}
                className="tw-w-full tw-text-sm satoshi tw-mb-3 tw-font-light tw-text-black"
              >
                <td className="tw-py-3 tw-pr-4 tw-pl-3">{item.id}</td>
                <td className="tw-py-3 tw-capitalize tw-pr-4">{item.name}</td>
                <td className="tw-py-3 tw-pr-4">{item.Datejoined}</td>
                <td className="tw-py-3 tw-pr-4 tw-capitalize">{item.Class}</td>

                {action && (
                  <td
                    onClick={() => navigate("/view-details", { state: item })}
                    className="tw-text-yellow tw-font-semibold tw-text-sm tw-font-body satoshi tw-cursor-pointer"
                  >
                    {item.actions}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default ManageWardsTable;
