import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { HooksForm } from "../components/inputs";
import useAuthStore from "../data/Stores/AuthStore";
import { toast } from "react-toastify";
// import { TOKEN } from "../data/Config";
import useGenFetcher, { apiCall } from "../data/useGenFetcher";
import useErrorStore from "../data/Stores/ErrrorStore";
import { FormProvider, useForm } from "react-hook-form";
import { PrimaryBtn } from "../components/buttons/buttons";

const Login = () => {
	return (
		<div>
			<MainSection />
		</div>
	);
};

const MainSection = () => {
	const [hoverText, setHoverText] = useState("Get Started");
	// const navigate = useNavigate(),
	// 	[data, setData] = useState({
	// 		email: "",
	// 		password: "",
	// 	}),
	// 	{ login, setUserPage, resetUserPage } = useAuthStore(),
	// 	textChange = e => {
	// 		let { name, value } = e?.target;
	// 		setData({ ...data, [name]: value });
	// 	},
	// 	handleSubmit = e => {
	// 		e?.preventDefault();
	// 		resetUserPage();
	// 		let userEmail = data?.email?.trim()?.toLowerCase();
	// 		let userPassword = data?.password,
	// 			userPage = "";
	// 		if (
	// 			userEmail === "parent@cedumas.com" &&
	// 			userPassword === "Cedumas@1234"
	// 		) {
	// 			setUserPage("parent");
	// 			userPage = "parent";
	// 		} else if (
	// 			userEmail === "teacher@cedumas.com" &&
	// 			userPassword === "Cedumas@1234"
	// 		) {
	// 			setUserPage("teacher");
	// 			userPage = "teacher";
	// 		} else if (
	// 			userEmail === "student@cedumas.com" &&
	// 			userPassword === "Cedumas@1234"
	// 		) {
	// 			setUserPage("student");
	// 			userPage = "student";
	// 		} else {
	// 			toast.error(
	// 				"Incorrect email or password. Please enter valid credentials."
	// 			);
	// 			return;
	// 		}
	// 		const token = "sound_map_auth_token";
	// 		localStorage.setItem(TOKEN, JSON.stringify({ ...data, token, userPage }));
	// 		login({ ...data, token, userPage });
	// 		toast.success(`Welcome ${userPage}`);
	// 		navigate("/");
	// 	};
	// const isFormValid =
	// 	data?.email?.trim() !== "" && data?.password?.trim() !== "";
	// console.log({ data });

	const { isAuth, login } = useAuthStore(),
		{ loadUser } = useGenFetcher(),
		{ returnErrors } = useErrorStore(),
		navigate = useNavigate(),
		[loading, setLoading] = useState(false);

	const methods = useForm({
		defaultValues: {
			email: "",
			password: "",
		},
	});

	useEffect(() => {
		if (isAuth) {
			navigate("/");
		}
	});
	const handleSubmit = async data => {
		if (!data?.email || !data?.password) return;
		setLoading(true);
		let { response, errArr, errMsg } = await apiCall({
			type: "post",
			url: "/api/v1/user/login",
			data,
		});
		// console.log({ response, errArr, errMsg });
		if (errArr) {
			setLoading(false);
			return returnErrors(errArr);
		}
		if (errMsg) {
			setLoading(false);
			return toast.error(errMsg);
		}
		setLoading(false);
		if (response) {
			login(response);
			loadUser();
			navigate("/");
			return;
		}
		setLoading(false);
	};

	return (
		<DefaultBox>
			{/* <form
				onSubmit={handleSubmit}
				className=" lg:tw-space-y-6 md:tw-space-y-6 tw-space-y-6 tw-bg-white lg:tw-w-[667px] md:tw-w-[467px] tw-w-[350px] tw-rounded-md lg:tw-py-10 md:tw-py-16 tw-py-10 lg:tw-px-32 md:tw-px-10 tw-px-5">
				<div className=" tw-space-y-1">
					<small className=" tw-text-[#383838] tw-font-Nunito tw-text-sm">
						Welcome!!
					</small>
					<p className=" tw-text-2xl tw-font-Nunito tw-text-[#272525] tw-font-bold">
						{hoverText}
					</p>
				</div>

				<div className="tw-space-y-5">
					<div>
						<TextInputs
							onMouseEnter={() => setHoverText("Email address")}
							onMouseLeave={() => setHoverText("Get Started")}
							placeholder={"Enter email address"}
							label={"Email address"}
							css={"tw-max-w-md"}
							onChange={textChange}
							name="email"
							type="email"
							value={data?.email}
						/>
					</div>
					<div>
						<PasswordInputs
							onMouseEnter={() => setHoverText("Enter Password")}
							onMouseLeave={() => setHoverText("Get Started")}
							label={"Password"}
							placeholder={"Enter password"}
							onChange={textChange}
							name="password"
							type="password"
							value={data?.password}
						/>
					</div>
					<div className="tw-flex tw-justify-between tw-items-center">
						<div className="tw-flex tw-items-center tw-gap-2">
							<input type="checkbox" />
							<small className="tw-text-sm tw-font-Nunito tw-text-[#515151]">
								Remember me
							</small>
						</div>
						<small className="tw-cursor-pointer tw-text-[#14ADD6] tw-font-Nunito tw-text-sm">
							I forgot my password
						</small>
					</div>
				</div>

				<div className=" tw-flex tw-items-center tw-justify-center ">
					<button
						disabled={!isFormValid}
						className={`tw-mt-3 tw-bg-[#FFC000] tw-w-60 tw-h-10 tw-text-white tw-border tw-border-[#A7A7A7] Kum tw-text-sm ${
							!isFormValid ? "tw-cursor-not-allowed tw-bg-opacity-40" : ""
						}`}
						type="submit"
						onMouseEnter={() => setHoverText("Login")}
						onMouseLeave={() => setHoverText("Get Started")}
						onClick={handleSubmit}>
						Login
					</button>
				</div>
			</form> */}
			<FormProvider {...methods}>
				<form
					className=" lg:tw-space-y-6 md:tw-space-y-6 tw-space-y-6 tw-bg-white lg:tw-w-[667px] md:tw-w-[467px] tw-w-[350px] tw-rounded-md lg:tw-py-10 md:tw-py-16 tw-py-10 lg:tw-px-32 md:tw-px-10 tw-px-5"
					onSubmit={methods.handleSubmit(handleSubmit)}>
					<div className=" tw-space-y-1">
						<small className=" tw-text-[#383838] tw-font-Nunito tw-text-sm">
							Welcome!!
						</small>
						<p className=" tw-text-2xl tw-font-Nunito tw-text-[#272525] tw-font-bold">
							{hoverText}
						</p>
					</div>
					<div className="tw-space-y-4">
						{/* Email */}
						<HooksForm
							rules={{
								required: "This field is required",
								pattern: {
									value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/g,
									message: "You must enter a valid email address",
								},
							}}
							methods={methods}
							name="email"
							placeholder={"admin@example.com"}
							label={"Email address"}
							css={"tw-w-full"}
							type="email"
						/>
						{/* Password */}
						<HooksForm
							rules={{
								required: "This field is required",
								pattern: {
									value: /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).{8,}$/g,
									message:
										"Your password must have at least one uppercase letter, one lowercase letter, one digit, one special character, and a minimum length of 8 characters.",
								},
							}}
							methods={methods}
							name="password"
							type="password"
							label={"Password"}
							placeholder={"Enter your password"}
						/>

						<div className="tw-flex tw-justify-between tw-items-center">
							<div className="tw-flex tw-items-center tw-gap-2">
								<input type="checkbox" />
								<small className="tw-text-sm tw-font-Nunito tw-text-[#515151]">
									Remember me
								</small>
							</div>
							<small className="tw-cursor-pointer tw-text-[#14ADD6] tw-font-Nunito tw-text-sm">
								I forgot my password
							</small>
						</div>
					</div>
					<div className="tw-mt-6 tw-w-full tw-flex tw-justify-center">
						<PrimaryBtn
							// onClick={handleSubmit}
							loading={loading}
							text={"Login"}
							css={`tw-mt-3 tw-bg-[#FFC000] tw-w-60 tw-h-10 tw-text-white tw-border tw-border-[#A7A7A7] Kum tw-text-sm`}
							bg="#FFC000"
							type="submit"
							onMouseEnter={() => setHoverText("Login")}
							onMouseLeave={() => setHoverText("Get Started")}
						/>
					</div>
				</form>
			</FormProvider>
		</DefaultBox>
	);
};

export default Login;

export const DefaultBox = ({ children }) => {
	return (
		<>
			<div className=" lg:tw-space-y-5 md:tw-space-y-0 tw-space-y-5 tw-bg-gradient-to-b tw-from-lightblue lg:tw-h-screen md:tw-h-[100vh] tw-h-screen lg:tw-pt-10 md:tw-pt-24 tw-pt-10 tw-to-deepblue tw-grid tw-justify-center lg:tw-pb-0 md:tw-pb-24 tw-pb-0 tw-items-center ">
				<div className=" tw-flex tw-items-center tw-justify-center">
					<img
						className=" tw-w-14 tw-h-14"
						src={require("../assets/Logo.png")}
						alt=""
					/>
				</div>
				{children}
			</div>
		</>
	);
};
