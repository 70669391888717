import React, { useEffect } from "react";
import Header from "../components/header";
import StudentNavbar from "../components/StudentNavbar";
import PageText from "../components/texts/Pagetexts";
import ebook from "../assets/ebook.png";

const Book = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="E-LIBRARY" />
      <StudentNavbar />
      <div className=" tw-bg-white tw-mt-5  lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <PageText text="THE PRINCIPLE OF DESIGNS" />

          <div className=" tw-flex tw-justify-between tw-gap-10 tw-mt-5">
            <div className=" tw-w-1/3">
              <img src={ebook} alt="" />
            </div>
            <div className=" tw-w-4/6">
              <p className=" tw-text-blue tw-font-Kum tw-text-xl tw-font-semibold">
                CHAPTER 1 - TITLE CHAPTER
              </p>
              <p className=" tw-text-sm Manrope tw-text-[#504F4F]">
                Lorem ipsum dolor sit amet consectetur. Risus vel quis vitae
                fermentum viverra est non. Sed ipsum sollicitudin integer
                blandit rhoncus etiam amet accumsan gravida. In varius risus sem
                vitae tempor. Libero tellus felis ut egestas cursus enim non
                varius. Facilisis facilisis sed venenatis odio mi etiam.
                Fermentum tincidunt venenatis tristique blandit feugiat neque.
                Viverra imperdiet laoreet erat libero eleifend eu. Viverra
                pharetra adipiscing sit mattis pellentesque id ipsum. Molestie
                lectus nisi in pellentesque quis semper. Porttitor sed ultrices
                imperdiet arcu vitae cras. Senectus tempus lorem turpis ac id
                nec euismod. Tristique urna adipiscing diam ac molestie dolor eu
                felis
              </p>
              <p className="tw-text-sm Manrope tw-text-[#504F4F]">
                Nisl netus nec diam feugiat eu accumsan bibendum diam duis.
                Vitae mattis ultrices ullamcorper posuere laoreet varius
                ultricies pulvinar donec. Bibendum praesent sapien metus in
                ullamcorper. Senectus tellus enim ipsum ullamcorper pellentesque
                tristique.
              </p>

              <ul className=" tw-list-disc">
                <li className=" tw-text-[15px] Manrope tw-text-[#756F6F]">
                  {" "}
                  Bibendum praesent sapien metus in ullamcorpe
                </li>
                <li className=" tw-text-[15px] Manrope tw-text-[#756F6F]">
                  {" "}
                  Bibendum praesent sapien metus in ullamcorpe
                </li>
                <li className=" tw-text-[15px] Manrope tw-text-[#756F6F]">
                  {" "}
                  Bibendum praesent sapien metus in ullamcorpe
                </li>
                <li className=" tw-text-[15px] Manrope tw-text-[#756F6F]">
                  {" "}
                  Bibendum praesent sapien metus in ullamcorpe
                </li>
                <li className=" tw-text-[15px] Manrope tw-text-[#756F6F]">
                  {" "}
                  Bibendum praesent sapien metus in ullamcorpe
                </li>
                <li className=" tw-text-[15px] Manrope tw-text-[#756F6F]">
                  {" "}
                  Bibendum praesent sapien metus in ullamcorpe
                </li>
              </ul>
            </div>
          </div>
        </div>
        <p className="tw-text-sm Manrope tw-text-[#504F4F] tw-mt-5">
          Lorem ipsum dolor sit amet consectetur. Risus vel quis vitae fermentum
          viverra est non. Sed ipsum sollicitudin integer blandit rhoncus etiam
          amet accumsan gravida. In varius risus sem vitae tempor. Libero tellus
          felis ut egestas cursus enim non varius. Facilisis facilisis sed
          venenatis odio mi etiam. Fermentum tincidunt venenatis tristique
          blandit feugiat neque. Viverra imperdiet laoreet erat libero eleifend
          eu. Viverra pharetra adipiscing sit mattis pellentesque id ipsum.
          Molestie lectus nisi in pellentesque quis semper. Porttitor sed
          ultrices imperdiet arcu vitae cras. Senectus tempus lorem turpis ac id
          nec euismod. Tristique urna adipiscing diam ac molestie dolor eu felis
        </p>
      </div>
    </div>
  );
};

export default Book;
