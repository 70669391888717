import React, { useState } from "react";
import ScheduleCal from "../../assets/ScheduleCal.png";
import Clock from "../../assets/Clock.png";
import ExamDetails from "./students-card/ExamDetails";

const General = () => {
  const [isExam, setIsExam] = useState(false);
  const cards = [
    {
      title: "Passed",
      topic: " Mid-Term, End of Topic Assessment",
      subject: "Subject - Mathematics",
      cal: ScheduleCal,
      date: "31-01-2024",
      clock: Clock,
      time: "12:30 AM - 01:40 PM",
      Percentage: "Passing Percentage",
      ScoredPercentage: "Scored Percentage",
      count: "70%",
      scored: "65%",
      btn: "View Details",
    },
    {
      title: "Failed",
      topic: "Mid-Term, End of Topic Assessment",
      subject: "Subject - Mathematics",
      cal: ScheduleCal,
      date: "31-01-2024",
      clock: Clock,
      time: "12:30 AM - 01:40 PM",
      Percentage: "Passing Percentage",
      ScoredPercentage: "Scored Percentage",
      count: "70%",
      scored: "65%",
      btn: "View Details",
    },
    {
      title: "Passed",
      topic: "Mid-Term, End of Topic Assessment",
      subject: "Subject - Mathematics",
      cal: ScheduleCal,
      date: "31-01-2024",
      clock: Clock,
      time: "12:30 AM - 01:40 PM",
      Percentage: "Passing Percentage",
      ScoredPercentage: "Scored Percentage",
      count: "70%",
      scored: "65%",
      btn: "View Details",
    },
    {
      title: "Passed",
      topic: "Mid-Term, End of Topic Assessment",
      subject: "Subject - Mathematics",
      cal: ScheduleCal,
      date: "31-01-2024",
      clock: Clock,
      time: "12:30 AM - 01:40 PM",
      Percentage: "Passing Percentage",
      ScoredPercentage: "Scored Percentage",
      count: "70%",
      scored: "65%",
      btn: "View Details",
    },
    {
      title: "Missed",
      topic: "Mid-Term, End of Topic Assessment",
      subject: "Subject - Mathematics",
      cal: ScheduleCal,
      date: "31-01-2024",
      clock: Clock,
      time: "12:30 AM - 01:40 PM",
      Percentage: "Passing Percentage",
      ScoredPercentage: "Scored Percentage",
      count: "70%",
      scored: "65%",
      btn: "View Details",
    },
    {
      title: "Passed",
      topic: "Mid-Term, End of Topic Assessment",
      subject: "Subject - Mathematics",
      cal: ScheduleCal,
      date: "31-01-2024",
      clock: Clock,
      time: "12:30 AM - 01:40 PM",
      Percentage: "Passing Percentage",
      ScoredPercentage: "Scored Percentage",
      count: "70%",
      scored: "65%",
      btn: "View Details",
    },
    {
      title: "Failed",
      topic: "Mid-Term, End of Topic Assessment",
      subject: "Subject - Mathematics",
      cal: ScheduleCal,
      date: "31-01-2024",
      clock: Clock,
      time: "12:30 AM - 01:40 PM",
      Percentage: "Passing Percentage",
      ScoredPercentage: "Scored Percentage",
      count: "70%",
      scored: "65%",
      btn: "View Details",
    },
    {
      title: "Passed",
      topic: "Mid-Term, End of Topic Assessment",
      subject: "Subject - Mathematics",
      cal: ScheduleCal,
      date: "31-01-2024",
      clock: Clock,
      time: "12:30 AM - 01:40 PM",
      Percentage: "Passing Percentage",
      ScoredPercentage: "Scored Percentage",
      count: "70%",
      scored: "65%",
      btn: "View Details",
    },
    {
      title: "Missed",
      topic: "Mid-Term, End of Topic Assessment",
      subject: "Subject - Mathematics",
      cal: ScheduleCal,
      date: "31-01-2024",
      clock: Clock,
      time: "12:30 AM - 01:40 PM",
      Percentage: "Passing Percentage",
      ScoredPercentage: "Scored Percentage",
      count: "70%",
      scored: "65%",
      btn: "View Details",
    },
  ];

  return (
    <div className=" ">
      <div className="tw-flex tw-flex-wrap tw-gap-10 tw-justify-between ">
        {cards.map((card, index) => {
          let bgColor, textColor;

          switch (card.title) {
            case "Failed":
              bgColor = "#FFEFEF";
              textColor = "#F93333";
              break;
            case "Missed":
              bgColor = "#E6E6E6";
              textColor = "#9A9A9A";
              break;
            case "Passed":
              bgColor = "#EFFFF5";
              textColor = "#28A745"; // Assuming green color for Passed
              break;
            default:
              bgColor = "#FFFFFF";
              textColor = "#000000";
          }

          return (
            <div
              key={index}
              className="card tw-border tw-border-[#E6E6E6] tw-w-[350px] tw-grid tw-rounded-lg tw-pl-3 tw-relative"
            >
              <div className="">
                <div className="tw-flex tw-justify-end tw-items-center">
                  <small
                    className={`tw-rounded-tr-lg tw-rounded-bl-lg tw-h-6 tw-w-16 tw-grid tw-justify-center tw-items-center`}
                    style={{ backgroundColor: bgColor, color: textColor }}
                  >
                    {card.title}
                  </small>
                </div>

                <div className="tw-pr-10 tw-mt-5">
                  <small className="Satoshi tw-font-semibold tw-text-[#3C3C3C] tw-text-lg">
                    {card.topic}
                  </small>
                </div>
                <small>{card.subject}</small>
                <div className="tw-flex tw-items-center tw-gap-5">
                  <div className="tw-flex tw-items-center tw-gap-1">
                    <img className="tw-w-5 tw-h-5" src={card.cal} alt="" />
                    <p className=" tw-text-sm tw-text-[#3C3C3C]">{card.date}</p>
                  </div>
                  <div className="tw-flex tw-items-center tw-gap-1">
                    <img className="tw-w-5 tw-h-5" src={card.clock} alt="" />
                    <p className=" tw-text-sm tw-text-[#3C3C3C]">{card.time}</p>
                  </div>
                </div>
                <div className="">
                  <div className="tw-flex tw-gap-10">
                    <p className="Satoshi tw-text-xs tw-text-[#3C3C3C]">
                      {card.Percentage}
                    </p>
                    <p className="Satoshi tw-text-xs tw-text-[#3D70F5]">
                      {card.count}
                    </p>
                  </div>
                  <div className="tw-flex tw-gap-10">
                    <p className="Satoshi tw-text-xs tw-text-[#3C3C3C]">
                      {card.ScoredPercentage}
                    </p>
                    <p className="Satoshi tw-text-xs tw-text-[#F93333]">
                      {card.scored}
                    </p>
                  </div>
                </div>
              </div>
              <div className=" tw-h-20 tw-w-full tw-bg-white"></div>
              <div className=" ">
                <div className="tw-grid tw-absolute tw-bottom-3 tw-left-3 tw-right-3 ">
                  <button
                    onClick={() => setIsExam(!isExam, { state: { card } })}
                    className="tw-w-full tw-bg-[#3D70F5] tw-h-10 tw-text-white Inter tw-text-sm"
                  >
                    {card.btn}
                  </button>
                </div>
                {isExam && <ExamDetails onClick={() => setIsExam(false)} />}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default General;
