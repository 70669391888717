import React, { useEffect } from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import PageText from "../components/texts/Pagetexts";
import booksstack from "../assets/booksstack.png";
import studtwo from "../assets/studtwo.png";
import stdthree from "../assets/stdthree.png";
import Activities from "../components/cards/Activity";
import Container from "../assets/Container.png";
import pencil from "../assets/pencil.png";
import stdfive from "../assets/stdfive.png";
import BarChart from "../components/chart/BarChart";
import StudentNavbar from "../components/StudentNavbar";

const Dashboard = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const Activity = [
    {
      id: 1,
      img: Container,
      text: "Graded students in Topology Assignment",
      desc: "You have graded 20 students in the last assignment",
      time: "1 Day ago",
    },
    {
      id: 2,
      img: Container,
      text: "Graded students in Topology Assignment",
      desc: "You have graded 20 students in the last assignment",
      time: "1 Day ago",
    },
    {
      id: 3,
      img: Container,
      text: "Graded students in Topology Assignment",
      desc: "You have graded 20 students in the last assignment",
      time: "1 Day ago",
    },
    {
      id: 4,
      img: Container,
      text: "Graded students in Topology Assignment",
      desc: "You have graded 20 students in the last assignment",
      time: "1 Day ago",
    },
  ];

  const cards = [
    {
      img: booksstack,
      height: "45.05px",
      width: "60px",
      num: "8",
      text: "Subjects",
    },
    {
      img: studtwo,
      height: "67.57px",
      width: "90px",
      num: "32",
      text: "Mid-Term Exam",
      lighttext: "/40",
    },
    {
      img: stdthree,
      height: "60px",
      width: "60px",
      num: "102",
      text: "Avg Assessments",
      lighttext: "/120",
    },
    {
      img: pencil,
      height: "75.08px",
      width: "100px",
      num: "46",
      text: "Attendance",
      lighttext: "/50",
    },
    {
      img: stdfive,
      height: "45.05px",
      width: "60px",
      num: "6",
      text: "Class Notes",
      lighttext: "",
    },
    {
      img: booksstack,
      height: "45.05px",
      width: "60px",
      num: "50,000",
      text: "Pending Payment",
      lighttext: "",
    },
  ];
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="WELCOME TO YOUR DASHBOARD (2ND TERM 2023/2024)" />
      <StudentNavbar />
      <div className="  lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <PageText text="Welcome Tito!" />
        </div>

        <div className=" tw-grid tw-grid-cols-6 tw-justify-center tw-items-center tw-gap-10">
          {cards.map((i) => (
            <div className=" tw-w-44 tw-h-40 tw-shadow-lg tw-rounded-lg tw-bg-white tw-py-5 tw-grid ">
              <div className=" tw-flex tw-items-center tw-justify-center">
                <img
                  style={{ width: i.width, height: i.height }}
                  className=""
                  src={i.img}
                  alt=""
                />
              </div>
              <div className=" tw-flex tw-justify-center tw-items-center">
                <strong className=" tw-font-semibold Satoshi tw-text-[#000]">
                  {i.num} <span className=" tw-font-light">{i.lighttext}</span>
                </strong>
              </div>
              <div className=" tw-flex tw-justify-center tw-items-center">
                <strong className=" tw-font-light Satoshi tw-text-[#000]">
                  {i.text}
                </strong>
              </div>
            </div>
          ))}
        </div>

        <div className=" tw-flex tw-items-center tw-justify-between tw-gap-10 tw-mt-5">
          <div className=" tw-w-1/2 tw-bg-white tw-p-5 tw-rounded-lg tw-shadow-md">
            <p className=" tw-text-[#000] Satoshi tw-text-lg tw-font-semibold">
              Your Activity
            </p>

            <div>
              <Activities Activity={Activity} />
            </div>
          </div>

          <div className=" tw-w-1/2 tw-bg-white tw-px-5 tw-py-2 tw-rounded-lg tw-shadow-md">
            <div>
              <strong className=" tw-font-Kum tw-font-semibold tw-text-base  tw-text-[#000]">
                Number of Students in Class
              </strong>
            </div>
            <BarChart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
