// import React from "react";

// const PreviousInputsTable = () => {
//   return (
//     <div>
//       <p></p>
//     </div>
//   );
// };

// export default PreviousInputsTable;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import PageText from "../texts/Pagetexts";
import EditVector from "../../assets/EditVector.png";

const PreviousInputsTable = () => {
  const navigate = useNavigate();
  const initialData = [
    {
      name: "JOHN Smith O.",
      firstCA: "",
      secondCA: "",
      exams: "",
      total: "",
      grade: "",
    },
    {
      name: "JOHN Smith O.",
      firstCA: "",
      secondCA: "",
      exams: "",
      total: "",
      grade: "",
    },
    {
      name: "JOHN Smith O.",
      firstCA: "",
      secondCA: "",
      exams: "",
      total: "",
      grade: "",
    },
    {
      name: "JOHN Smith O.",
      firstCA: "",
      secondCA: "",
      exams: "",
      total: "",
      grade: "",
    },
    {
      name: "JOHN Smith O.",
      firstCA: "",
      secondCA: "",
      exams: "",
      total: "",
      grade: "",
    },
    {
      name: "JOHN Smith O.",
      firstCA: "",
      secondCA: "",
      exams: "",
      total: "",
      grade: "",
    },
    {
      name: "JOHN Smith O.",
      firstCA: "",
      secondCA: "",
      exams: "",
      total: "",
      grade: "",
    },
  ];

  const [data, setData] = useState(initialData);

  const handleInputChange = (index, field, value) => {
    const updatedData = [...data];
    const numericValue = value === "" ? "" : Number(value);

    if (
      field === "firstCA" &&
      (numericValue === "" || (numericValue >= 0 && numericValue <= 20))
    ) {
      updatedData[index][field] = numericValue;
    } else if (
      field === "secondCA" &&
      (numericValue === "" || (numericValue >= 0 && numericValue <= 20))
    ) {
      updatedData[index][field] = numericValue;
    } else if (
      field === "exams" &&
      (numericValue === "" || (numericValue >= 0 && numericValue <= 60))
    ) {
      updatedData[index][field] = numericValue;
    }

    updatedData[index].total =
      (Number(updatedData[index].firstCA) || 0) +
      (Number(updatedData[index].secondCA) || 0) +
      (Number(updatedData[index].exams) || 0);

    const total = updatedData[index].total;
    if (total >= 70) updatedData[index].grade = "A";
    else if (total >= 60) updatedData[index].grade = "B";
    else if (total >= 50) updatedData[index].grade = "C";
    else if (total >= 40) updatedData[index].grade = "D";
    else if (total >= 30) updatedData[index].grade = "E";
    else updatedData[index].grade = "F";

    setData(updatedData);
  };

  return (
    <div className=" tw-mt-5">
      <div className=" tw-flex tw-items-center tw-gap-3">
        <PageText text="SCORES INPUT FOR MATHEMATICS  (S.S 1A)" />
        <img
          className=" tw-w-3 tw-h-3 tw-cursor-pointer"
          onClick={() => navigate("/edit-score-input")}
          src={EditVector}
          alt=""
        />
      </div>
      <div className="tw-w-full">
        <table className="tw-w-full tw-border">
          <thead>
            <tr className="tw-bg-[#F0F6FF] tw-border tw-border-[#F0F6FF] ">
              <th className="tw-border tw-px-4 tw-py-2">STUDENTS</th>
              <th className="tw-border tw-px-4">1st CA (20)</th>
              <th className="tw-border tw-px-4">2nd CA (20)</th>
              <th className="tw-border tw-px-4">EXAMS (60)</th>
              <th className="tw-border tw-px-4">TOTAL (100)</th>
              <th className="tw-border tw-px-4">GRADE</th>
            </tr>
          </thead>
          <tbody>
            {data.map((student, index) => (
              <tr key={index}>
                <td className="tw-border tw-px-4 Manrope tw-text-sm tw-font-light tw-text-[#000]">
                  {student.name}
                </td>
                <td className="tw-border tw-px-4 tw-py-1">
                  <input
                    type="number"
                    value={student.firstCA}
                    onChange={(e) =>
                      handleInputChange(index, "firstCA", e.target.value)
                    }
                    className="tw-w-full tw-outline-none placeholder:tw-text-xs placeholder:tw-text-[#000] tw-text-xs"
                    max="20"
                    min="0"
                    placeholder="Enter scores"
                  />
                </td>
                <td className="tw-border tw-px-4">
                  <input
                    type="number"
                    value={student.secondCA}
                    onChange={(e) =>
                      handleInputChange(index, "secondCA", e.target.value)
                    }
                    className="tw-w-full tw-outline-none placeholder:tw-text-xs placeholder:tw-text-[#000] tw-text-xs"
                    max="20"
                    min="0"
                    placeholder="Enter scores"
                  />
                </td>
                <td className="tw-border tw-px-4">
                  <input
                    type="number"
                    value={student.exams}
                    onChange={(e) =>
                      handleInputChange(index, "exams", e.target.value)
                    }
                    className="tw-w-full tw-outline-none placeholder:tw-text-xs placeholder:tw-text-[#000] tw-text-xs"
                    max="60"
                    min="0"
                    placeholder="Enter scores"
                  />
                </td>
                <td className="tw-border tw-px-4 tw-text-xs">
                  {student.total || ""}
                </td>
                <td className="tw-border tw-px-4 tw-text-xs">
                  {student.grade || ""}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PreviousInputsTable;
