import React, { useState } from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";

import { useLocation, useNavigate } from "react-router-dom";
import Buttons from "../components/buttons/buttons";
import Removed from "../components/cards/students-card/Removed";

const EventDetails = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  console.log(state);
  const [isDelete, setIsDelete] = useState(false);
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="COMMUNICATIONS" />
      <Navbar />
      <div className="tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div className="tw-flex tw-gap-5">
          <div className="tw-w-2/5">
            <div className="tw-grid tw-justify-center tw-gap-4">
              <img className="tw-w-80 tw-h-80" src={state?.userimage} alt="" />
              <p className="tw-font-Kum tw-text-lg tw-text-[#14238A] tw-text-center tw-font-semibold">
                {state?.title || "End of the Year Party"}
              </p>
            </div>
          </div>
          <div className="tw-w-3/5">
            <div>
              <p className="tw-font-Kum tw-text-lg tw-text-[#14238A] tw-font-semibold">
                Event Date: {state?.date}
              </p>
              <p className=" tw-text-sm Manrope">{state?.text}</p>
              <p className=" tw-text-sm Manrope">{state?.sptext}</p>
            </div>
          </div>
        </div>

        <div className=" tw-flex tw-items-center tw-justify-center tw-mt-10 tw-gap-5">
          <Buttons
            onClick={() => setIsDelete(!isDelete)}
            css={"tw-border-[#FF5757] tw-border"}
            color={"tw-text-[#FF5757]"}
            text="Delete event"
          />
          {isDelete && (
            <Removed
              onCancel={() => navigate("/communication")}
              text="‘Full Name’ "
            />
          )}
          <Buttons
            onClick={() => navigate("/edit-details")}
            bg={"tw-bg-[#2242B4]"}
            color={"tw-text-[#fff]"}
            text="Edit details"
          />
        </div>
      </div>
    </div>
  );
};

export default EventDetails;
