import React, { useState } from "react";

// import PageText from "../../components/texts/pageText";

import Profile from "../assets/profile.png";
import Mail from "../assets/mail.png";
import { useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import Buttons from "../components/buttons/buttons";
import PageText from "../components/texts/Pagetexts";
import Removed from "../components/cards/students-card/Removed";

const StudentDetails = () => {
  const [isRemoved, setIsRemoved] = useState(false);
  const navigate = useNavigate(),
    { state } = useLocation();

  const details = [
    {
      title: "ID Number",
      value: "",
    },
    {
      title: "Name",
      value: state.name,
    },
    {
      title: "Gender",
      value: state.gender,
    },
    {
      title: "Parent Name",
      value: state.Parent,
    },
    {
      title: "Class",
      value: state.Class,
    },
    {
      title: "Date of Birth",
      value: state.Phone,
    },
    {
      title: "Religion",
      value: "Christianity",
    },
    {
      title: "Father Occupation",
      value: "Sales Manager",
    },
    {
      title: "Email Address",
      value: "josmith@gmail.com",
    },
    {
      title: "Admission Date",
      value: "12/12/2022",
    },
  ];
  const handleCancel = () => {
    navigate("/students");
  };

  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="STUDENT’S DETAILS" />
      <Navbar />
      <div className=" tw-h-full lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5 tw-bg-white tw-mt-5">
        <PageText text={"Vew Student's Details"} />
        <div className="tw-flex  tw-mt-8 tw-gap-4 tw-h-full ">
          <div className="tw-w-[30%] tw-relative tw-h-full">
            <img src={Profile} alt="" className=" tw-w-80 tw-h-80" />
            <img
              src={Mail}
              alt=""
              className="tw-mt-6 tw-mx-auto tw-w-9 tw-h-9"
            />
            <div className="tw-mt-20">
              <div className="tw-flex tw-justify-center">
                <div className="tw-mx-auto">
                  <h5 className="tw-text-xl tw-text-center tw-font-medium tw-text-black satoshi">
                    School Fee Status
                  </h5>
                  <div className="tw-flex tw-mt-4 tw-justify-center">
                    <Buttons
                      bg={"tw-bg-[#FF5757]"}
                      text={"Yet to Pay"}
                      color={"tw-text-[#fff]"}
                    />
                  </div>
                  <h6 className="tw-text-xl tw-mt-6 tw-font-bold tw-text-csblue satoshi tw-text-center tw-cursor-pointer">
                    View Payment History
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="tw-w-[40%]">
            <h4 className="tw-text-xl tw-font-bold tw-text-primary kum">
              {state?.firstName} {state?.lastName}
            </h4>
            <p className="tw-text-base tw-font-light tw-text-black manrope">
              Sed ut perspiciatis unde omnis iste natus error sit voluptatem
              accusantium doloremque laudantium, totam rem aperiam, eaque ipsa
            </p>
            <div className="tw-space-y-4 tw-mt-5">
              {details?.map((it) => (
                <div className="tw-flex tw-gap-10">
                  <div className="tw-w-32">
                    <small className="tw-text-sm tw-font-normal kum tw-text-black">
                      {it?.title}:
                    </small>
                  </div>
                  <div className="">
                    <strong
                      className={`tw-text-sm tw-font-bold manrope tw-text-black ${
                        ["Gender", "Religion"]?.includes(it?.title)
                          ? "tw-capitalize"
                          : ""
                      }`}
                    >
                      {it?.value}
                    </strong>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="tw-w-[30%]">
            <div className="tw-grid tw-mt-8 tw-gap-3 tw-grid-cols-2">
              <Buttons
                onClick={() => setIsRemoved(!isRemoved)}
                bg={"tw-bg-[#fff]"}
                css={"tw-border tw-border-[#FF5757] tw-w-full"}
                color={"tw-text-[#FF5757]"}
                text={"Delete Student"}
              />
              {isRemoved && (
                <Removed onCancel={handleCancel} text="‘Full Name’ " />
              )}
              <Buttons
                onClick={() => navigate("/students/edit-student")}
                css={"tw-w-full"}
                text={"Edit details"}
                color={"tw-text-[#FF5757]"}
              />
            </div>
            <div className="tw-mt-16">
              <h4 className="tw-text-base tw-font-semibold tw-text-[#3C3C3C] manrope">
                Attendance
              </h4>
              <div className="tw-p-6 tw-border tw-border-[#ECECEC] tw-rounded-md">
                <h5 className="tw-text-secondary tw-font-medium tw-text-sm manrope">
                  Total Classes : 145
                </h5>
                <h6 className="tw-text-xs tw-font-normal manrope tw-text-[#60DF6F]">
                  Attended : 120
                </h6>
                <h6 className="tw-text-xs tw-font-normal tw-text-[#F93333] manrope">
                  Missed : 25
                </h6>
                <div className="tw-mt-4 tw-flex tw-justify-end">
                  <Buttons
                    onClick={() => navigate("/view-details")}
                    bg={"tw-bg-[#2C62EE]"}
                    text={"View details"}
                    color={"tw-text-[#fff]"}
                  />
                </div>
              </div>
            </div>
            <div className="tw-mt-16">
              <h4 className="tw-text-base tw-font-semibold tw-text-[#3C3C3C] manrope">
                Exams
              </h4>
              <div className="tw-p-6 tw-border tw-border-[#ECECEC] tw-rounded-md">
                <h5 className="tw-text-secondary tw-font-medium tw-text-sm manrope">
                  Total Exams : 245
                </h5>
                <div className="tw-flex tw-justify-between tw-items-center tw-w-full">
                  <div>
                    <h5 className="tw-text-[#6B6B6B] tw-font-medium tw-text-xs manrope">
                      Attempted : 120
                    </h5>
                    <h6 className="tw-text-xs tw-font-normal manrope tw-text-[#60DF6F]">
                      Passed : 120
                    </h6>
                  </div>
                  <div>
                    <h5 className="tw-text-[#6B6B6B] tw-font-medium tw-text-xs manrope">
                      Unattempted : 120
                    </h5>
                    <h6 className="tw-text-xs tw-font-normal manrope tw-text-[#F93333]">
                      Failed : 120
                    </h6>
                  </div>
                </div>
                <div className="tw-mt-4 tw-flex tw-justify-end">
                  <Buttons
                    onClick={() => navigate("/results")}
                    bg={"tw-bg-[#2C62EE]"}
                    text={"View details"}
                    color={"tw-text-[#fff]"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StudentDetails;
