import React from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import PageText from "../components/texts/Pagetexts";
import Attend from "../assets/Attend.png";
import { useNavigate } from "react-router-dom";

const Attendance = () => {
  const navigate = useNavigate();
  const classList = [
    {
      img: Attend,
      class: "JSS 1",
    },
    {
      img: Attend,
      class: "JSS 1",
    },
    {
      img: Attend,
      class: "JSS 1",
    },
    {
      img: Attend,
      class: "JSS 1",
    },
    {
      img: Attend,
      class: "JSS 1",
    },
    {
      img: Attend,
      class: "JSS 1",
    },
  ];
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="ATTENDANCE" />
      <Navbar />
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <PageText text="Mark Attendance" />

          <div className=" tw-flex tw-items-center tw-gap-8">
            {classList.map((i) => (
              <div
                onClick={() => navigate("/mark-attendance")}
                className=" tw-grid tw-justify-center tw-items-center tw-cursor-pointer tw-bg-[#F6FAFF] tw-w-24 tw-h-24 tw-rounded-lg tw-pt-3"
              >
                <img className=" tw-w-6 tw-h-6" src={i.img} alt="" />
                <p className=" tw-font-Kum tw-text-base tw-text-[#282828] tw-font-light">
                  {i.class}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Attendance;
