import React from "react";
import Header from "../components/header";
import StudentNavbar from "../components/StudentNavbar";
import PageText from "../components/texts/Pagetexts";
import Buttons from "../components/buttons/buttons";
import uploadd from "../assets/uploadd.png";

const Notes = () => {
  const Card = [
    {
      title: "Mathematics Notes",
      date: "15/02/2024",
      text: "Uploaded by Miss Ann AdeOluwa",
    },
    {
      title: "Mathematics Notes",
      date: "15/02/2024",
      text: "Uploaded by Miss Ann AdeOluwa",
    },
    {
      title: "Mathematics Notes",
      date: "15/02/2024",
      text: "Uploaded by Miss Ann AdeOluwa",
    },
    {
      title: "Mathematics Notes",
      date: "15/02/2024",
      text: "Uploaded by Miss Ann AdeOluwa",
    },
    {
      title: "Mathematics Notes",
      date: "15/02/2024",
      text: "Uploaded by Miss Ann AdeOluwa",
    },
    {
      title: "Mathematics Notes",
      date: "15/02/2024",
      text: "Uploaded by Miss Ann AdeOluwa",
    },
    {
      title: "Mathematics Notes",
      date: "15/02/2024",
      text: "Uploaded by Miss Ann AdeOluwa",
    },
    {
      title: "Mathematics Notes",
      date: "15/02/2024",
      text: "Uploaded by Miss Ann AdeOluwa",
    },
  ];
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="CLASS NOTES" />
      <StudentNavbar />
      <div className=" tw-bg-white tw-mt-5  lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <PageText text="All Class Notes" />
        </div>

        <div className=" tw-grid tw-justify-center tw-items-center tw-pt-5">
          <div className=" tw-grid tw-grid-cols-4 tw-gap-5">
            {Card.map((i) => (
              <div className=" tw-w-64  tw-border tw-rounded-lg tw-shadow-md tw-bg-white tw-p-5 hover:tw-scale-105 tw-duration-300 tw-ease-in">
                <div className=" tw-flex tw-items-center tw-justify-between">
                  <small className=" tw-text-sm Satoshi tw-text-[#000]">
                    {i.title}
                  </small>
                  <small className="tw-text-sm Satoshi tw-text-[#FFC000]">
                    {i.date}
                  </small>
                </div>
                <div className=" tw-w-52 tw-py-4">
                  <strong className=" Satoshi tw-text-[15px] tw-font-bold">
                    {i.text}
                  </strong>
                </div>
                <div className=" tw-flex tw-items-center tw-gap-2">
                  <Buttons
                    css={
                      "tw-text-blue tw-border tw-border-blue tw-text-xs tw-w-[170px]"
                    }
                    text="Download DOC PDF"
                  />
                  <img
                    className=" tw-w-4 tw-h-4 tw-cursor-pointer"
                    src={uploadd}
                    alt=""
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notes;
