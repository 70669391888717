import React, { useState } from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";

import { BranchSelectInput, SearchInput } from "../components/inputs";
import PageText from "../components/texts/Pagetexts";
import Buttons from "../components/buttons/buttons";
import PromoteStudentsTable from "../components/tables/PromoteStudentsTable";
import PromoteStudents from "../components/cards/promote-card/PromoteStudents";
import { useNavigate } from "react-router-dom";

const Students = () => {
  const [isPromote, setIsPromote] = useState(false);
  const navigate = useNavigate();
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="PROMOTIONS" />
      <Navbar />
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div className=" tw-flex tw-items-center tw-gap-5 tw-pb-2">
          <PageText text="Promote Students" />

          <SearchInput
            placeholder="Search by name..."
            onChange={(e) => console.log(e.target.value)}
          />
          <BranchSelectInput
            placeholder="Select by branch"
            onChange={(e) => console.log(e.target.value)}
          />
        </div>
        <PromoteStudentsTable action="View student" />

        <div className=" tw-mt-5">
          <Buttons
            onClick={() => setIsPromote(!isPromote)}
            width={"tw-w-[191px]"}
            color={"tw-text-[#fff]"}
            bg={"tw-bg-[#2242B4]"}
            text="Promote student(s)"
          />
          {isPromote && (
            <PromoteStudents
              onRoute={() => navigate("/all-grades")}
              onClick={() => setIsPromote(false)}
              text="Are you sure you want to promote 32 students to next class?"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Students;
