import React, { useState } from "react";

const WeeklyTable = () => {
  const students = [
    "John Smith",
    "John Smith",
    "John Smith",
    "John Smith",
    "John Smith",
    "John Smith",
    "John Smith",
    "John Smith",
    "John Smith",
    "John Smith",
  ];

  const [attendance, setAttendance] = useState(
    students.map(() => ({
      Monday: false,
      Tuesday: false,
      Wednesday: false,
      Thursday: false,
      Friday: false,
    }))
  );

  const handleCheckboxChange = (studentIndex, day) => {
    const updatedAttendance = attendance.map((record, index) => {
      if (index === studentIndex) {
        return { ...record, [day]: !record[day] };
      }
      return record;
    });
    setAttendance(updatedAttendance);
  };
  return (
    <div>
      <div className=" tw-flex tw-items-center tw-justify-between tw-py-5">
        <small className=" tw-font-Kum tw-text-base tw-font-semibold tw-text-[#000]">
          WEEK 1
        </small>
        <small className=" tw-text-[#2242B4] tw-font-Kum tw-text-sm tw-underline">
          Go to next week
        </small>
      </div>

      <div className="tw-w-full">
        <table className="tw-table tw-w-full">
          <thead
            style={{
              paddingLeft: "1.25rem !important",
            }}
            className="tw-h-14 tw-bg-[#F0F6FF] tw-w-full tw-mb-4 tw-text-md tw-capitalize tw-font-bold tw-font-kum tw-text-plain"
          >
            <tr>
              <th className="tw-px-4 tw-py-2 tw-border-b ">Students</th>
              <th className="tw-px-4 tw-py-2 tw-border-b">Monday</th>
              <th className="tw-px-4 tw-py-2 tw-border-b">Tuesday</th>
              <th className="tw-px-4 tw-py-2 tw-border-b">Wednesday</th>
              <th className="tw-px-4 tw-py-2 tw-border-b">Thursday</th>
              <th className="tw-px-4 tw-py-2 tw-border-b">Friday</th>
            </tr>
          </thead>
          <tbody className="tw-w-full">
            {students.map((student, studentIndex) => (
              <tr key={studentIndex} className="tw-border-b">
                <td className="tw-px-4 tw-py-2 tw-border-b ">{student}</td>
                {["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"].map(
                  (day) => (
                    <td key={day} className=" tw-pl-4 tw-border-b">
                      <input
                        type="radio"
                        checked={attendance[studentIndex][day]}
                        onChange={() => handleCheckboxChange(studentIndex, day)}
                      />
                    </td>
                  )
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WeeklyTable;
