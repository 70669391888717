import React from "react";
import Header from "../components/header";
import StudentNavbar from "../components/StudentNavbar";
import PageText from "../components/texts/Pagetexts";
import { SearchInput } from "../components/inputs";
import StudentPaymentHistoryTable from "../components/tables/StudentPaymentHistoryTable";
import payimg from "../assets/payimg.png";
import { useNavigate } from "react-router-dom";

const Payment = () => {
  const navigate = useNavigate();
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="PAYMENTS" />
      <StudentNavbar />
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div className=" tw-flex tw-items-center tw-justify-between tw-gap-5 tw-pb-2">
          <div className=" tw-flex tw-items-center tw-gap-5">
            <PageText text="Payment History" />
            <SearchInput
              placeholder="Search by name..."
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
          <div
            onClick={() => navigate("/make-payment")}
            className=" tw-w-40 tw-h-12 tw-rounded-lg tw-cursor-pointer tw-gap-1 tw-bg-primary tw-flex tw-items-center tw-justify-center"
          >
            <img className=" tw-w-5 tw-h-5" src={payimg} alt="" />
            <small className=" Manrope tw-font-semibold tw-text-sm tw-text-[#fff]">
              Make payment
            </small>
          </div>
        </div>
        <StudentPaymentHistoryTable action="View details" />
      </div>
    </div>
  );
};

const PaymentHistorys = () => <Payment />;

export default Payment;
