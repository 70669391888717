import React from "react";
import dashLogo from "../assets/dashLogo.png";
import useAuthStore from "../data/Stores/AuthStore";

const Header = ({ text }) => {
	let { logout } = useAuthStore();
	return (
		<div className=" tw-bg-[#2242B4] tw-space-x-5 lg:tw-pl-12 lg:tw-pr-4 md:tw-px-8 tw-px-5  tw-flex tw-items-center tw-justify-between">
			<div>
				<img
					className=" lg:tw-w-20 md:tw-w-20 tw-w-28 tw-h-20"
					src={dashLogo}
					alt=""
				/>
			</div>
			<div>
				<p className=" tw-font-Kumbh lg:tw-text-2xl md:tw-text-lg tw-text-sm tw-pt-3 tw-text-white tw-font-bold  ">
					{text}
				</p>
			</div>
			<div
				onClick={() => logout()}
				className=" lg:tw-block md:tw-block tw-hidden">
				<img
					className=" tw-w-20 tw-h-20 tw-cursor-pointer"
					src={require("../assets/User.png")}
					alt=""
				/>
			</div>
		</div>
	);
};

export default Header;
