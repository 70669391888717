// import React from "react";

// const EditDetails = () => {
//   return (
//     <div>
//       <p></p>
//     </div>
//   );
// };

// export default EditDetails;
import React, { useState } from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import PageText from "../components/texts/Pagetexts";
import { RiArrowDropDownLine } from "react-icons/ri";
import Buttons from "../components/buttons/buttons";
import profileimg from "../assets/profileimg.png";
import { useLocation, useNavigate } from "react-router-dom";
import ActionDone from "../components/cards/ActionDone";
import useAuthStore from "../data/Stores/AuthStore";
import ParentNavbar from "../components/ParentNavbar";

const EditDetails = () => {
  const navigate = useNavigate(),
    { state } = useLocation(),
    { userPage } = useAuthStore();
  const [isUpdate, setIsUpdate] = useState();
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="MANAGE WARD(S)" />
      <ParentNavbar />
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <PageText text="New Ward" />

          <div>
            <div className=" tw-grid tw-grid-cols-5 tw-gap-y-8 tw-mt-5">
              {/* Name */}
              <div className=" tw-w-52 tw-space-y-2">
                <label
                  className=" Manrope tw-text-sm tw-font-normal tw-text-[#000]"
                  htmlFor=""
                >
                  Name *
                </label>
                <input
                  className=" tw-w-full tw-h-12 tw-border tw-rounded-lg tw-outline-none tw-px-2 placeholder:tw-text-xs placeholder:tw-text-[#757576] tw-text-[#757576] "
                  type="text"
                />
              </div>
              {/* Gender * */}
              <div className=" tw-w-52 tw-space-y-2">
                <label
                  className=" Manrope tw-text-sm tw-font-normal tw-text-[#000]"
                  htmlFor=""
                >
                  Gender *
                </label>
                <div className=" tw-flex tw-items-center tw-relative">
                  <input
                    placeholder="Please Select Gender"
                    className=" tw-h-12 tw-border tw-rounded-lg tw-outline-none tw-px-2 tw-text-xs tw-w-full placeholder:tw-text-xs placeholder:tw-text-[#757576] tw-text-[#757576] "
                    type="text"
                  />
                  <RiArrowDropDownLine
                    size={24}
                    className=" tw-absolute tw-right-4"
                  />
                </div>
              </div>
              {/* Class * */}
              <div className=" tw-w-52 tw-space-y-2">
                <label
                  className=" Manrope tw-text-sm tw-font-normal tw-text-[#000]"
                  htmlFor=""
                >
                  Assign Class *
                </label>
                <div className=" tw-flex tw-items-center tw-relative">
                  <input
                    placeholder="Please Select Class"
                    className=" tw-h-12 tw-border tw-rounded-lg tw-outline-none tw-px-2 tw-text-xs tw-w-full placeholder:tw-text-xs placeholder:tw-text-[#757576] tw-text-[#757576] "
                    type="text"
                  />
                  <RiArrowDropDownLine
                    size={24}
                    className=" tw-absolute tw-right-4"
                  />
                </div>
              </div>
              {/* Date of birth */}
              <div className=" tw-w-52 tw-space-y-2">
                <label
                  className=" Manrope tw-text-sm tw-font-normal tw-text-[#000]"
                  htmlFor=""
                >
                  Date of birth *
                </label>
                <input
                  className=" tw-w-full tw-h-12 tw-border tw-rounded-lg tw-outline-none tw-px-2 placeholder:tw-text-xs placeholder:tw-text-[#757576] tw-text-[#757576] "
                  type="date"
                />
              </div>
              {/*Branch * */}
              <div className=" tw-w-52 tw-space-y-2">
                <label
                  className=" Manrope tw-text-sm tw-font-normal tw-text-[#000]"
                  htmlFor=""
                >
                  Branch *
                </label>
                <div className=" tw-flex tw-items-center tw-relative">
                  <input
                    className=" tw-h-12 tw-border tw-rounded-lg tw-outline-none tw-px-2 tw-text-xs tw-w-full placeholder:tw-text-xs placeholder:tw-text-[#757576] tw-text-[#757576] "
                    type="text"
                  />
                  <RiArrowDropDownLine
                    size={24}
                    className=" tw-absolute tw-right-4"
                  />
                </div>
              </div>

              {/* Email address * */}
              <div className=" tw-w-52 tw-space-y-2">
                <label
                  className=" Manrope tw-text-sm tw-font-normal tw-text-[#000]"
                  htmlFor=""
                >
                  Email address *
                </label>
                <input
                  className=" tw-w-full tw-h-12 tw-border tw-rounded-lg tw-outline-none tw-px-2 placeholder:tw-text-xs placeholder:tw-text-[#757576] tw-text-[#757576] "
                  type="text"
                />
              </div>

              {/* Religion * */}
              <div className=" tw-w-52 tw-space-y-2">
                <label
                  className=" Manrope tw-text-sm tw-font-normal tw-text-[#000]"
                  htmlFor=""
                >
                  Religion *
                </label>
                <div className=" tw-flex tw-items-center tw-relative">
                  <input
                    placeholder="Please Select Religion"
                    className=" tw-h-12 tw-border tw-rounded-lg tw-outline-none tw-px-2 tw-text-xs tw-w-full placeholder:tw-text-xs placeholder:tw-text-[#757576] tw-text-[#757576] "
                    type="text"
                  />
                  <RiArrowDropDownLine
                    size={24}
                    className=" tw-absolute tw-right-4"
                  />
                </div>
              </div>

              {/*Admission Date * */}
              <div className=" tw-w-52 tw-space-y-2">
                <label
                  className=" Manrope tw-text-sm tw-font-normal tw-text-[#000]"
                  htmlFor=""
                >
                  Admission Date *
                </label>
                <input
                  className=" tw-w-full tw-h-12 tw-border tw-rounded-lg tw-outline-none tw-px-2 placeholder:tw-text-xs placeholder:tw-text-[#757576] tw-text-[#757576] "
                  type="date"
                />
              </div>
            </div>
          </div>
        </div>

        <div className=" tw-mt-10">
          <div className=" tw-flex tw-items-center tw-gap-4">
            <img className=" tw-w-48  tw-h-48" src={profileimg} alt="" />
            <div className="">
              <p className="Manrope tw-text-sm tw-font-normal tw-text-[#000]">
                Change Photo (150px X 150px)
              </p>
              <div className=" tw-flex tw-items-center tw-gap-4">
                <Buttons css={"tw-border"} text="Choose File" />
                <small className=" tw-text-[11px] tw-text-[#757576]">
                  No File Chosen
                </small>
              </div>
            </div>
          </div>
        </div>

        <div className=" tw-flex tw-justify-center tw-items-center tw-gap-5 tw-mt-20">
          <Buttons
            onClick={() => navigate("/manage-ward(s)")}
            css={"tw-border tw-border-[#2242B4]"}
            color={"tw-text-[#2242B4]"}
            text="Cancel"
          />
          <Buttons
            onClick={() => setIsUpdate(!isUpdate)}
            bg={" tw-bg-[#2242B4]"}
            color={"tw-text-[#fff]"}
            text="Update"
          />
          {isUpdate && (
            <ActionDone text="Action Done" onClick={() => setIsUpdate(false)} />
          )}
        </div>
      </div>
    </div>
  );
};

export default EditDetails;
