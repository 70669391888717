import React, { useEffect, useState } from "react";
import Header from "../components/header";
import StudentNavbar from "../components/StudentNavbar";
import PageText from "../components/texts/Pagetexts";
import { useNavigate } from "react-router-dom";
import AssessmentsCard from "../components/all-assessments/AssessmentsCard";
import QuizCard from "../components/all-assessments/QuizCard";

const Assessments = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const [selectedTab, setSelectedTab] = useState("Assignment");
  const navigate = useNavigate();

  const tabs = ["Assignment", "Exams", "Quiz"];

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
    if (tab === "Exams") {
      navigate("/your-results");
    }
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "Assignment":
        return (
          <div>
            <AssessmentsCard />
          </div>
        );
      case "Quiz":
        return (
          <div>
            <QuizCard />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="ASSIGNMENTS" />
      <StudentNavbar />
      <div className=" tw-bg-white tw-mt-5  lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <PageText text="All Assessments" />
        </div>

        <div className="">
          <ul className="tw-flex tw-items-center tw-gap-10">
            {tabs.map((tab) => (
              <li
                key={tab}
                className={`tw-cursor-pointer tw-text-sm ${
                  selectedTab === tab
                    ? "tw-underline tw-text-[#3D70F5]"
                    : "tw-text-[#707070]"
                }`}
                onClick={() => handleTabClick(tab)}
              >
                {tab}
              </li>
            ))}
          </ul>
          <div className="tw-mt-5">
            {selectedTab !== "Exams" && renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Assessments;
