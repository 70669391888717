import React from "react";
import Header from "../components/header";
import ParentNavbar from "../components/ParentNavbar";
import PageText from "../components/texts/Pagetexts";
import PaymentTable from "../components/tables/PaymentTable";
import AttendanceChart from "../components/cards/AttendanceChart";
import ParentBarChart from "../components/chart/StudentBarChart";

const Dashboard = () => {
  const card = [
    {
      children: "Children",
      num: "02",
      kids: "Kids",
    },
    {
      children: "Children",
      num: "02",
      kids: "Kids",
    },
    {
      children: "Children",
      num: "02",
      kids: "Kids",
    },
    {
      children: "Children",
      num: "02",
      kids: "Kids",
    },
    {
      children: "Children",
      num: "02",
      kids: "Kids",
    },
  ];
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="WELCOME TO YOUR DASHBOARD (2ND TERM 2023/2024)" />
      <ParentNavbar />
      <div className=" tw-mt-5  lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <PageText text="Welcome Bob!" />
          <small className=" tw-font-light tw-font-Kum tw-text-sm tw-text-[#2C323F]">
            You can oversee and assess your child(ren)
          </small>

          <div className=" tw-grid tw-justify-center tw-items-center tw-mt-5">
            <div className=" tw-grid lg: tw-grid-cols-5 tw-gap-5">
              {card.map((i) => (
                <div className=" tw-bg-white tw-rounded-lg tw-w-56 tw-p-5 tw-h-32">
                  <div className=" tw-flex tw-items-center tw-justify-between">
                    <div className=" tw-grid tw-gap-1">
                      <small className=" tw-text-[#343434] tw-font-Kum tw-text-sm">
                        {i.children}
                      </small>
                      <small className=" Satoshi tw-text-2xl tw-font-light tw-text-blue">
                        {i.num}
                      </small>
                    </div>
                    <div>
                      <div className=" tw-border tw-border-[#CCCCCC] tw-w-12 tw-h-12 tw-rounded-full"></div>
                    </div>
                  </div>
                  <div className=" tw-mt-4">
                    <p className=" tw-font-light tw-text-sm tw-text-[#949494]">
                      {i.kids}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className=" tw-bg-white tw-mt-5 tw-p-5 ">
            <div className=" tw-flex tw-justify-center tw-items-center tw-gap-10">
              <div className=" tw-w-1/2">
                <PaymentTable />
              </div>
              <div className=" tw-w-1/2  tw-max-w-lg tw-h-72 tw-bg-white tw-rounded-lg tw-shadow-md tw-p-6">
                <AttendanceChart text="Attendance Overview for John" />
              </div>
            </div>
          </div>

          <div className=" tw-bg-white">
            <ParentBarChart />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
