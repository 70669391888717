// import React from "react";

// const EditGrade = () => {
//   return (
//     <div>
//       <p></p>
//     </div>
//   );
// };

// export default EditGrade;

import React from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import PageText from "../components/texts/Pagetexts";
import Buttons from "../components/buttons/buttons";
import { useNavigate } from "react-router-dom";

const EditGrade = () => {
  const navigate = useNavigate();
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="GRADE CALCULATIONS" />
      <Navbar />
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <PageText text={"Edit Grade"} />

          <div className=" tw-flex tw-items-center tw-gap-10 tw-mt-8">
            <div className=" ">
              <p className=" Manrope tw-text-sm tw-text-[#000]">Enter Class*</p>
              <input
                className=" tw-border tw-border-[#DDDEEE] tw-w-52 tw-h-12 tw-rounded-lg tw-outline-none tw-px-2 tw-text-xs tw-text-[#757576] placeholder:tw-text-[#757576] placeholder-[#757576]"
                type="text"
              />
            </div>
            <div className=" ">
              <p className=" Manrope tw-text-sm tw-text-[#000]">
                Scores/Marks*
              </p>
              <input
                className=" tw-border tw-border-[#DDDEEE] tw-w-52 tw-h-12 tw-rounded-lg tw-outline-none tw-px-2 tw-text-xs tw-text-[#757576] placeholder:tw-text-[#757576] placeholder-[#757576]"
                type="text"
              />
            </div>
            <div className=" ">
              <p className=" Manrope tw-text-sm tw-text-[#000]">Grade*</p>
              <input
                className=" tw-border tw-border-[#DDDEEE] tw-w-52 tw-h-12 tw-rounded-lg tw-outline-none tw-px-2 tw-text-xs tw-text-[#757576] placeholder:tw-text-[#757576] placeholder-[#757576]"
                type="text"
              />
            </div>
            <div className=" ">
              <p className=" Manrope tw-text-sm tw-text-[#000]">
                Status/Remarks
              </p>
              <input
                className=" tw-border tw-border-[#DDDEEE] tw-w-52 tw-h-12 tw-rounded-lg tw-outline-none tw-px-2 tw-text-xs tw-text-[#757576] placeholder:tw-text-[#757576] placeholder-[#757576]"
                type="text"
              />
            </div>
          </div>
        </div>
        <div className=" tw-flex tw-justify-center tw-items-center tw-mt-32 tw-gap-5">
          <Buttons
            onClick={() => navigate("/all-grades")}
            css={"tw-border tw-border-[#2242B4]"}
            color={"tw-text-[#2242B4]"}
            text="Cancel"
          />
          <Buttons
            bg={"tw-bg-[#2242B4]"}
            color={"tw-text-[#fff]"}
            text="Update"
          />
        </div>
      </div>
    </div>
  );
};

export default EditGrade;
