import React, { useEffect } from "react";
import Header from "../components/header";

import { BranchSelectInput } from "../components/inputs";
import { useLocation, useNavigate } from "react-router-dom";
import PageText from "../components/texts/Pagetexts";
import Navbar from "../components/Navbar";
import Attendance from "../students/attendance";
import useAuthStore from "../data/Stores/AuthStore";
import StudentNavbar from "../components/StudentNavbar";
import ParentNavbar from "../components/ParentNavbar";
import AttendanceChart from "../components/cards/AttendanceChart";

const ViewDetails = () => {
  const navigate = useNavigate(),
    { state } = useLocation(),
    { userPage } = useAuthStore();
  const { logout } = useAuthStore();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const charts = [
    {
      bg: "#DA47FF",
      num: "60",
      text: "Days School Opened",
    },
    {
      bg: "#47FF4E",
      num: "50",
      text: "Days Present",
    },
    {
      bg: "#FF4773",
      num: "10",
      text: "Days Absent",
    },
    {
      bg: "#FF4773",
      num: "2",
      text: "Days Absent",
    },
  ];
  const filteredCharts = charts.filter((i) => i.num !== "2");
  const firstChart = charts[0];
  const secondChart = charts[1];
  const thirdChart = charts[2];
  const forthChart = charts[3];
  return (
    <div className="tw-bg-[#F9F9F9]">
      {userPage === "teacher" ? (
        <Header text="MY STUDENTS" />
      ) : userPage === "student" ? (
        <Header text="MY STUDENTS" />
      ) : userPage === "parent" ? (
        <Header text="ATTENDANCE" />
      ) : null}

      {userPage === "teacher" ? (
        <Navbar />
      ) : userPage === "student" ? (
        <StudentNavbar />
      ) : userPage === "parent" ? (
        <ParentNavbar />
      ) : null}
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        {userPage === "teacher" ? (
          <div className=" tw-flex tw-items-center tw-gap-5 tw-pb-2">
            <BranchSelectInput
              placeholder="Search for session"
              onChange={(e) => console.log(e.target.value)}
            />
            <BranchSelectInput
              placeholder="Search for term"
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
        ) : userPage === "student" ? (
          <div className=" tw-flex tw-items-center tw-gap-5 tw-pb-2">
            <BranchSelectInput
              placeholder="Search for session"
              onChange={(e) => console.log(e.target.value)}
            />
            <BranchSelectInput
              placeholder="Search for term"
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
        ) : userPage === "parent" ? (
          <div className=" tw-grid tw-pb-5">
            <strong className=" tw-font-Kum tw-font-medium tw-text-xl tw-text-[#2C323F]">
              John’s Attendance Overview
            </strong>
            <small className=" tw-font-Kum tw-text-base tw-font-light tw-text-[#2C323F]">
              (Current Term)
            </small>
          </div>
        ) : null}

        <div>
          {/* <p className=" tw-text-xl tw-font-semibold Kum tw-text-[#000] ">
            
          </p> */}
          <PageText text="CURRENT TERM" />
          <div className=" tw-flex tw-items-center tw-gap-32">
            <div className=" tw-flex tw-items-center tw-gap-5 ">
              <AttendanceChart />
            </div>
            <div className=" ">
              <div className=" tw-space-y-5">
                <div className=" tw-flex tw-items-center tw-justify-between tw-w-[419px] tw-border tw-border-[#EDEDED] tw-p-3">
                  <div className=" tw-grid tw-w-48 tw-justify-start">
                    <small className=" tw-font-semibold tw-text-[#404040] tw-text-2xl Satoshi">
                      {firstChart.num}
                    </small>
                    <small className=" tw-text-xs tw-text-[#9E9E9E] Satoshi">
                      {firstChart.text}
                    </small>
                  </div>
                  <img
                    className=" tw-h-10 tw-pl-10 tw-pr-4"
                    src={require("../assets/Divider.png")}
                    alt=""
                  />
                  <div className=" tw-grid tw-w-48 tw-justify-start">
                    <small className=" tw-font-semibold tw-text-[#404040] tw-text-2xl Satoshi">
                      {secondChart.num}
                    </small>
                    <small className="tw-text-xs tw-text-[#9E9E9E] Satoshi">
                      {secondChart.text}
                    </small>
                  </div>
                </div>
                {/*  */}
                <div className=" tw-flex tw-items-center tw-justify-between tw-w-[419px] tw-border tw-border-[#EDEDED] tw-p-3">
                  <div className=" tw-grid tw-w-48 tw-justify-start">
                    <small className=" tw-font-semibold tw-text-[#404040] tw-text-2xl Satoshi">
                      {thirdChart.num}
                    </small>
                    <small className=" tw-text-xs tw-text-[#9E9E9E] Satoshi">
                      {thirdChart.text}
                    </small>
                  </div>
                  <img
                    className=" tw-h-10 tw-pl-10 tw-pr-4"
                    src={require("../assets/Divider.png")}
                    alt=""
                  />
                  <div className=" tw-grid tw-w-48 tw-justify-start">
                    <small className=" tw-font-semibold tw-text-[#404040] tw-text-2xl Satoshi">
                      {forthChart.num}
                    </small>
                    <small className="tw-text-xs tw-text-[#9E9E9E] Satoshi">
                      {forthChart.text}
                    </small>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/*  */}
          {/*  */}
          <div className=" tw-mt-16">
            <PageText text="PREVIOUS TERM" />
            <div className=" tw-space-y-5 ">
              <div className=" tw-flex tw-items-center tw-justify-between tw-w-[419px] tw-border tw-border-[#EDEDED] tw-p-3">
                <div className=" tw-grid tw-w-48 tw-justify-start">
                  <small className=" tw-font-semibold tw-text-[#404040] tw-text-2xl Satoshi">
                    {firstChart.num}
                  </small>
                  <small className=" tw-text-xs tw-text-[#9E9E9E] Satoshi">
                    {firstChart.text}
                  </small>
                </div>
                <img
                  className=" tw-h-10 tw-pl-10 tw-pr-4"
                  src={require("../assets/Divider.png")}
                  alt=""
                />
                <div className=" tw-grid tw-w-48 tw-justify-start">
                  <small className=" tw-font-semibold tw-text-[#404040] tw-text-2xl Satoshi">
                    {secondChart.num}
                  </small>
                  <small className="tw-text-xs tw-text-[#9E9E9E] Satoshi">
                    {secondChart.text}
                  </small>
                </div>
              </div>
              {/*  */}
              <div className=" tw-flex tw-items-center tw-justify-between tw-w-[419px] tw-border tw-border-[#EDEDED] tw-p-3">
                <div className=" tw-grid tw-w-48 tw-justify-start">
                  <small className=" tw-font-semibold tw-text-[#404040] tw-text-2xl Satoshi">
                    {thirdChart.num}
                  </small>
                  <small className=" tw-text-xs tw-text-[#9E9E9E] Satoshi">
                    {thirdChart.text}
                  </small>
                </div>
                <img
                  className=" tw-h-10 tw-pl-10 tw-pr-4"
                  src={require("../assets/Divider.png")}
                  alt=""
                />
                <div className=" tw-grid tw-w-48 tw-justify-start">
                  <small className=" tw-font-semibold tw-text-[#404040] tw-text-2xl Satoshi">
                    {forthChart.num}
                  </small>
                  <small className="tw-text-xs tw-text-[#9E9E9E] Satoshi">
                    {forthChart.text}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const ViewDetail = () => <ViewDetails />;

export default ViewDetails;
