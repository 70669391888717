import React from "react";
import PageText from "../components/texts/Pagetexts";
import { FaArrowLeft } from "react-icons/fa";

const ExamResultDetails = () => {
  return (
    <div>
      <div className=" tw-flex tw-items-center tw-gap-2">
        <FaArrowLeft size={24} />
        <div className=" tw-mt-1">
          <PageText text="Exam Result Details" />
        </div>
      </div>
    </div>
  );
};

export default ExamResultDetails;
