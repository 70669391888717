import React from "react";
import Header from "../components/header";
import StudentNavbar from "../components/StudentNavbar";
import PageText from "../components/texts/Pagetexts";
import Grouptw from "../assets/Grouptw.png";
import { Input } from "../components/inputs";
import Buttons from "../components/buttons/buttons";
import { useNavigate } from "react-router-dom";

const VirtualClassroom = () => {
  const navigate = useNavigate();
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="VIRTUAL CLASSROOM" />
      <StudentNavbar />
      <div className=" tw-bg-white tw-mt-5  lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <PageText text="Ongoing Live Class " />

          <div className=" tw-mt-5">
            <div className=" tw-w-60 tw-bg-[#F5FFF0] tw-p-5 tw-grid tw-gap-3 tw-shadow-lg">
              <div className=" tw-grid">
                <strong className=" Manrope tw-text-base tw-text-primary tw-font-medium">
                  MATHEMATICS
                </strong>
                <small className=" tw-text-sm Manrope tw-text-[#000] tw-font-normal">
                  GRADE 5 CLASS
                </small>
              </div>
              <div className=" tw-grid">
                <strong className=" Manrope tw-text-sm tw-text-[#000] tw-font-medium">
                  Teacher/Facilitator:
                </strong>
                <small className=" tw-text-xs Manrope tw-text-[#000] tw-font-normal">
                  GRADE 5 CLASS
                </small>
              </div>
              <div className=" tw-grid">
                <strong className=" Manrope tw-text-xs tw-text-[#000] tw-font-medium">
                  No of Students in Class:13
                </strong>
                <div className=" tw-flex tw-items-center tw-gap-3 tw-mt-3">
                  <img className=" tw-w-20 tw-h-6" src={Grouptw} alt="" />
                  <small className=" tw-text-xs Manrope tw-text-[#000]">
                    +8 more
                  </small>
                </div>
              </div>
            </div>

            <div className=" tw-mt-5">
              <div>
                <p className=" tw-text-[#000] tw-font-Kum tw-text-lg tw-font-semibold">
                  Enter Class Code to join
                </p>
                <Input width={"tw-w-[348px]"} placeholder="Class code" />
              </div>
            </div>

            <div className=" tw-mt-32">
              <Buttons
                onClick={() => navigate("/live-session")}
                bg={"tw-bg-[#2242B4]"}
                width={"tw-w-[200px]"}
                text="Go to class"
                color={"tw-text-[#fff]"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VirtualClassroom;
