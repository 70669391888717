import React from "react";
import Header from "../components/header";
import StudentNavbar from "../components/StudentNavbar";
import PageText from "../components/texts/Pagetexts";
import { BranchSelectInput } from "../components/inputs";
import { RiArrowDropDownLine } from "react-icons/ri";
import Buttons from "../components/buttons/buttons";
import Navbar from "../components/Navbar";
import ParentNavbar from "../components/ParentNavbar";
import { useLocation, useNavigate } from "react-router-dom";
import useAuthStore from "../data/Stores/AuthStore";

const MakePayment = () => {
  const navigate = useNavigate(),
    { state } = useLocation(),
    { userPage } = useAuthStore();

  console.log(state);
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="PAYMENTS" />
      {userPage === "teacher" ? (
        <Navbar />
      ) : userPage === "student" ? (
        <StudentNavbar />
      ) : userPage === "parent" ? (
        <ParentNavbar />
      ) : null}
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <PageText text="Make Payment" />

          <div className=" tw-mt-5">
            <div className=" tw-flex tw-items-center tw-relative ">
              <BranchSelectInput
                placeholder="Choose payment category"
                rounded={"tw-rounded-xl"}
              />
              <RiArrowDropDownLine className=" tw-absolute tw-right-2" />
            </div>
          </div>

          <div className=" tw-mt-5 tw-space-y-5">
            <div className="tw-flex tw-items-center tw-w-[700px]">
              <div className=" tw-w-3/12">
                <strong className=" Manrope tw-text-sm tw-text-[#000]">
                  Category*
                </strong>
              </div>
              <div className=" tw-w-3/4">
                <input
                  placeholder="School Fess"
                  type="text"
                  className=" tw-bg-[#DDDEEE] tw-outline-none tw-h-12 tw-rounded-lg tw-px-2 tw-w-full placeholder:tw-text-sm placeholder: tw-text-[#757576]"
                />
              </div>
            </div>
            {/*  */}
            <div className="tw-flex tw-items-center tw-w-[700px]">
              <div className=" tw-w-3/12">
                <strong className=" Manrope tw-text-sm tw-text-[#000]">
                  Date*
                </strong>
              </div>
              <div className=" tw-w-3/4">
                <input
                  type="date"
                  className="tw-border tw-border-[#DDDEEE] tw-outline-none tw-h-12 tw-rounded-lg tw-px-2 tw-w-full placeholder:tw-text-sm placeholder: tw-text-[#757576]"
                />
              </div>
            </div>
            {/*  */}
            <div className="tw-flex tw-items-center tw-w-[700px]">
              <div className=" tw-w-3/12">
                <strong className=" Manrope tw-text-sm tw-text-[#000]">
                  Card Number*
                </strong>
              </div>
              <div className=" tw-w-3/4">
                <input
                  placeholder="0000 0000 0000 0000"
                  type="text"
                  className="tw-border tw-border-[#DDDEEE] tw-outline-none tw-h-12 tw-rounded-lg tw-px-2 tw-w-full placeholder:tw-text-sm placeholder: tw-text-[#757576]"
                />
              </div>
            </div>
            {/*  */}
            <div className="tw-flex tw-items-center tw-w-[700px]">
              <div className=" tw-w-3/12">
                <strong className=" Manrope tw-text-sm tw-text-[#000]">
                  Expiry Date*
                </strong>
              </div>
              <div className=" tw-w-3/4 tw-flex tw-items-center tw-gap-10 tw-justify-between">
                <div>
                  <input
                    placeholder="0000 0000 0000 0000"
                    type="month"
                    className="tw-border tw-border-[#DDDEEE] tw-outline-none tw-h-12 tw-rounded-lg tw-px-2 tw-w-full placeholder:tw-text-sm placeholder: tw-text-[#757576]"
                  />
                </div>
                <div className=" tw-flex tw-items-center tw-gap-4 ">
                  <strong className=" Manrope tw-text-sm tw-text-[#000]">
                    CVV*
                  </strong>
                  <input
                    placeholder="000"
                    className="tw-border tw-border-[#DDDEEE] tw-outline-none tw-h-12 tw-rounded-lg tw-px-2"
                    type="text"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className=" tw-justify-center tw-flex tw-items-center tw-mt-20">
          <Buttons
            text="Pay now"
            bg={"tw-bg-[#2242B4]"}
            color={"tw-text-[#fff]"}
          />
        </div>
      </div>
    </div>
  );
};

const MakePaments = () => <MakePayment />;

export default MakePayment;
