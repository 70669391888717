import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "tailwindcss/tailwind.css";

// Register the components
ChartJS.register(ArcElement, Tooltip, Legend);

const AttendanceChart = ({ text }) => {
  const data = {
    labels: ["Days School Opened", "Days Present", "Days Absent"],
    datasets: [
      {
        label: "Attendance Overview",
        data: [60, 50, 10],
        backgroundColor: ["#c084fc", "#4ade80", "#fb7185"],
        hoverBackgroundColor: ["#d8b4fe", "#86efac", "#fca5a5"],
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: "70%",

    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="tw-flex">
      <div className="">
        <h2 className=" tw-text-base tw-font-medium tw-font-Kum">{text}</h2>
        <div className="tw-flex tw-justify-between tw-items-center tw-gap-10 tw-mt-5">
          <div style={{ width: 180, height: 180 }} className="tw-w-1/2 ">
            <Doughnut data={data} options={options} width={180} height={180} />
          </div>
          <div className="tw-w-1/2">
            <ul className="tw-space-y-4">
              <li className="tw-flex tw-items-center">
                <div className="tw-w-4 tw-h-4 tw-bg-[#c084fc] tw-rounded-full tw-mr-2"></div>
                <div className=" tw-grid">
                  <small className=" tw-font-bold Satoshi tw-text-xs tw-text-[#1B1B1B]">
                    {" "}
                    60{" "}
                  </small>
                  <small className=" tw-font-medium Satoshi tw-text-xs tw-text-[#9E9E9E]">
                    {" "}
                    Days School Opened{" "}
                  </small>
                </div>
              </li>
              <li className="tw-flex tw-items-center">
                <div className="tw-w-4 tw-h-4 tw-bg-[#4ade80] tw-rounded-full tw-mr-2"></div>
                <div className=" tw-grid">
                  <small className=" tw-font-bold Satoshi tw-text-xs tw-text-[#1B1B1B]">
                    {" "}
                    50{" "}
                  </small>
                  <small className=" tw-font-medium Satoshi tw-text-xs tw-text-[#9E9E9E]">
                    {" "}
                    Days Present{" "}
                  </small>
                </div>
              </li>
              <li className="tw-flex tw-items-center">
                <div className="tw-w-4 tw-h-4 tw-bg-[#fb7185] tw-rounded-full tw-mr-2"></div>
                <div className=" tw-grid">
                  <small className=" tw-font-bold Satoshi tw-text-xs tw-text-[#1B1B1B]">
                    {" "}
                    10{" "}
                  </small>
                  <small className=" tw-font-medium Satoshi tw-text-xs tw-text-[#9E9E9E]">
                    {" "}
                    Days Absent{" "}
                  </small>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceChart;
