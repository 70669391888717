import React from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";

import { RiArrowDropDownLine } from "react-icons/ri";
import AllStudentsTable from "../components/tables/AllStudentsTable";
import { BranchSelectInput, SearchInput } from "../components/inputs";

const Students = () => {
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="MY STUDENTS" />
      <Navbar />
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div className=" tw-flex tw-items-center tw-gap-5 tw-pb-2">
          <p className=" tw-mt-3">All Students Data</p>
          <SearchInput
            placeholder="Search by name..."
            onChange={(e) => console.log(e.target.value)}
          />
          <BranchSelectInput
            placeholder="Select by branch"
            onChange={(e) => console.log(e.target.value)}
          />
        </div>
        <AllStudentsTable action="View student" />
      </div>
    </div>
  );
};

export default Students;
