// import React from "react";

// const grading = () => {
//   return (
//     <div>
//       <p>grading</p>
//     </div>
//   );
// };

// export default ;
import React from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";

import { RiArrowDropDownLine } from "react-icons/ri";
import AllStudentsTable from "../components/tables/AllStudentsTable";
import { BranchSelectInput, SearchInput } from "../components/inputs";
import PageText from "../components/texts/Pagetexts";
import Buttons from "../components/buttons/buttons";
import AllGradesTable from "../components/tables/AllGradesTable";
import { useNavigate } from "react-router-dom";

const Grading = () => {
  const navigate = useNavigate();
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="GRADE CALCULATIONS" />
      <Navbar />
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div className=" tw-flex tw-items-center tw-justify-between">
          <div className=" tw-flex tw-items-center tw-gap-5 tw-pb-2">
            <PageText className=" tw-mt-3" text="All Grades" />

            <SearchInput
              placeholder="Search by name..."
              onChange={(e) => console.log(e.target.value)}
            />
            <BranchSelectInput
              placeholder="Select by branch"
              onChange={(e) => console.log(e.target.value)}
            />
          </div>
          <div>
            <Buttons
              onClick={() => navigate("/add-grade")}
              bg={"tw-bg-[#2242B4]"}
              text="Add Grade"
              color={"tw-text-[#fff]"}
            />
          </div>
        </div>
        <AllGradesTable action="Delete" Edit="Edit" />
      </div>
    </div>
  );
};

export default Grading;
