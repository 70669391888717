import React, { useState } from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import PageText from "../components/texts/Pagetexts";
import Removed from "../components/cards/students-card/Removed";
import Buttons from "../components/buttons/buttons";
import { useNavigate } from "react-router-dom";
import Recipients from "../assets/Recipients.png";

const AnnouncementDetails = () => {
  const navigate = useNavigate();
  const [isDelete, setIsDelete] = useState(false);
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="COMMUNICATIONS" />
      <Navbar />
      <div className="tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div>
          <PageText text="Announcement Details" />
        </div>
        <div className=" tw-flex tw-mt-5 ">
          <div className=" tw-w-3/5">
            <div className=" tw-flex tw-items-center tw-justify-between">
              <div className=" tw-flex tw-items-center tw-gap-5">
                <small>Posted By: Admin</small>
                <small>General</small>
              </div>
              <small>2/7/2023</small>
            </div>
            <div className=" tw-w-[690px] tw-h-[407px] tw-rounded-md tw-bg-[#9bdede]"></div>

            <div className=" tw-mt-5">
              <p className=" Manrope tw-text-base tw-font-semibold tw-text-[#2242B4]">
                TITLE
              </p>
              <p className=" Manrope tw-text-sm ">
                Porro fugit consequatur ipsa voluptas neque omnis. Modi ex
                inventore corrupti. Placeat dolorem enim impedit non ut totam.
                Perspiciatis fugit ratione ut. Omnis voluptatem repellat porro
                modi. Saepe id sunt maiores odio quam quaerat illum sunt aut.
              </p>
            </div>
            <div className=" tw-mt-10">
              <Buttons
                width={"tw-w-[210px]"}
                onClick={() => setIsDelete(!isDelete)}
                css={"tw-border-[#FF5757] tw-border"}
                color={"tw-text-[#FF5757]"}
                text="Delete announcements"
              />
              {isDelete && (
                <Removed
                  onCancel={() => navigate("/communication")}
                  text="‘Full Name’ "
                />
              )}
            </div>
          </div>
          <div className=" tw-w-2/5 tw-justify-end tw-grid">
            <div className="">
              <p>Recipients</p>
              <div className=" tw-flex tw-items-center tw-gap-2">
                <img className=" tw-w-44 tw-h-12" src={Recipients} alt="" />
                <small>+5</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementDetails;
