import React from "react";

const Today = () => {
  return (
    <div>
      <p></p>
    </div>
  );
};

export default Today;
