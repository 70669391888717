import React, { useState } from "react";
import black from "../../assets/black.png";
import { RiArrowDropDownLine } from "react-icons/ri";
import gallery from "../../assets/gallery.png";
import video from "../../assets/video.png";
import link from "../../assets/link.png";
import Buttons from "../buttons/buttons";
import AddImage from "./AddImage";

const CreatePost = ({ onClick }) => {
  const [image, setImage] = useState(false);
  const social = [
    {
      img: gallery,
      text: "Image",
    },
    {
      img: video,
      text: "Video",
    },
    {
      img: link,
      text: "Link",
    },
  ];
  return (
    <div className="tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-black tw-bg-opacity-45 tw-grid tw-justify-center tw-items-center">
      <div className=" tw-bg-white tw-w-[500px] tw-rounded-lg tw-p-5 tw-h-auto tw-py-5">
        <div className=" tw-flex tw-items-center tw-justify-between tw-border-b tw-border-b-[#BDBDBD] ">
          <small className=" tw-font-Kum tw-text-lg tw-font-semibold">
            Create Post
          </small>
          <small
            onClick={onClick}
            className=" tw-text-[24px] tw-font-semibold tw-cursor-pointer"
          >
            &times;
          </small>
        </div>

        <div className=" tw-flex tw-gap-3 tw-items-center">
          <img className=" tw-w-12 tw-h-12" src={black} alt="" />
          <div className=" tw-space-y-2 tw-mt-5">
            <small className=" Manrope tw-text-sm tw-font-semibold tw-text-[#2242B4]">
              Debs George
            </small>
            <div className=" tw-flex tw-items-center tw-justify-between tw-w-48 tw-rounded-full tw-px-2 tw-bg-[#F1F1F1] tw-h-8">
              <div className=" tw-flex tw-items-center tw-gap-1">
                <small className=" Manrope tw-text-xs tw-text-[#191D23] tw-font-light">
                  All Teachers <sup className=" tw-text-[#FD8282]">x</sup>
                </small>
                <small className=" Manrope tw-text-xs tw-text-[#191D23] tw-font-light">
                  All Students <sup className=" tw-text-[#FD8282]">x</sup>
                </small>
              </div>
              <RiArrowDropDownLine size={24} />
            </div>
          </div>
        </div>
        <div className="">
          <div className=" tw-space-y-1 tw-cursor-pointer">
            <label className=" tw-text-sm tw-text-[#344054] Manrope" htmlFor="">
              Title
            </label>
            <input
              placeholder="Subject Matter"
              className=" tw-h-12 tw-w-full tw-border tw-rounded-lg placeholder:tw-text-[#BEBEBE] placeholder:text-xs tw-text-[13px] tw-px-2"
              type="text"
            />
          </div>

          <div className=" tw-space-y-1">
            <label className=" tw-text-sm tw-text-[#344054] Manrope" htmlFor="">
              Body
            </label>
            <textarea
              name=""
              rows={5}
              className=" tw-w-full tw-border tw-rounded-lg tw-p-2"
              id=""
            ></textarea>
          </div>
        </div>
        <div className=" tw-justify-center tw-flex tw-items-center tw-gap-4 tw-mt-4">
          <div
            onClick={() => setImage(!image)}
            className=" tw-flex tw-items-center tw-gap-1 tw-cursor-pointer"
          >
            <img className=" tw-w-6 tw-h-6" src={gallery} alt="" />
            <small className=" Manrope tw-text-sm tw-text-[#000] tw-font-light">
              Image
            </small>
          </div>
          <div className=" tw-flex tw-items-center tw-gap-1 tw-cursor-pointer">
            <img className=" tw-w-6 tw-h-6" src={video} alt="" />
            <small className=" Manrope tw-text-sm tw-text-[#000] tw-font-light">
              Video
            </small>
            {image && <AddImage onClick={() => setImage(false)} />}
          </div>
          <div className=" tw-flex tw-items-center tw-gap-1 tw-cursor-pointer">
            <img className=" tw-w-6 tw-h-6" src={link} alt="" />
            <small className=" Manrope tw-text-sm tw-text-[#000] tw-font-light">
              Link
            </small>
          </div>
          {/* {social.map((i) => (
            <div className=" tw-flex tw-items-center tw-gap-1">
              <img className=" tw-w-6 tw-h-6" src={i.img} alt="" />
              <small className=" Manrope tw-text-sm tw-text-[#000] tw-font-light">
                {i.text}
              </small>
            </div>
          ))} */}
        </div>
        <div className=" tw-flex tw-items-center tw-justify-center tw-mt-8">
          <Buttons
            bg={"tw-bg-[#2242B4]"}
            color={"tw-text-[#fff]"}
            text="Post"
          />
        </div>
      </div>
    </div>
  );
};

export default CreatePost;
