import React, { useState } from "react";
import Buttons from "../../buttons/buttons";
import RemoveSuccessfull from "./RemoveSuccessfull";
import { useNavigate } from "react-router-dom";

const Removed = ({ text, onCancel }) => {
  const navigate = useNavigate();
  const [isRemoveSuccessfull, setIsRemoveSuccessfull] = useState(false);
  return (
    <div className="tw-fixed tw-left-0 tw-top-0 tw-w-full tw-h-full tw-bg-black tw-bg-opacity-45 tw-grid tw-justify-center tw-items-center">
      <div className=" tw-bg-white tw-w-[460px] tw-h-auto tw-py-5">
        <div>
          <div className=" tw-text-center tw-text-2xl tw-font-Kum tw-text-[#474747] tw-font-semibold">
            {text} Will Be Removed
          </div>
          <p className=" Manrope tw-text-center tw-text-xl tw-text-[#474747]">
            This action can not be reversed
          </p>
        </div>
        <div className=" tw-grid tw-justify-center tw-items-center">
          <div className=" tw-w-72 tw-h-72"></div>
        </div>

        <div className=" tw-flex tw-items-center tw-justify-center tw-gap-5">
          <Buttons
            text={"Cancel"}
            bg={"tw-bg-[#fff]"}
            css={"tw-border tw-border-[#2242B4] "}
            color={"tw-text-[#2242B4]"}
            onClick={onCancel}
          />
          <Buttons
            onClick={() => setIsRemoveSuccessfull(!isRemoveSuccessfull)}
            text="Continue"
            bg={"tw-bg-[#2242B4]"}
            color={"tw-text-[#fff]"}
          />
          {isRemoveSuccessfull && (
            <RemoveSuccessfull text="‘Item Type’" onClick={onCancel} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Removed;
