import React from "react";
import Header from "../components/header";
import Navbar from "../components/Navbar";
import PageText from "../components/texts/Pagetexts";

const PaymentDetails = () => {
  return (
    <div className="tw-bg-[#F9F9F9]">
      <Header text="PAYMENT HISTORY" />
      <Navbar />
      <div className=" tw-mt-5 tw-bg-white lg:tw-mx-8 md:tw-mx-8 tw-mx-5 tw-p-5">
        <div className=" tw-flex tw-items-center tw-gap-5 tw-pb-2">
          <PageText text="Payment History" />
        </div>
      </div>
    </div>
  );
};

export default PaymentDetails;
